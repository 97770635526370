/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Fade from 'react-reveal/Fade';

import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import i18n from '../../languages';
import { getDegree } from '../../redux/actions/degrees';

import Header from '../../components/Common/Header';
import Footer from '../../components/Common/Footer';
import Loader from '../../components/Common/Loader';
import Jumbotron from '../../components/EducationalResource/Jumbotron';
import ActionBar from '../../components/EducationalResource/ActionBar';
import CourseAbout from '../../components/EducationalResource/CourseAbout';
import DegreeProgram from '../../components/EducationalResource/DegreeProgram';
import CourseMentor from '../../components/EducationalResource/CourseMentor';
import MoreCourses from '../../components/EducationalResource/MoreCourses';
import VerifySiteWide from '../../components/Common/VerifySiteWide';

import { courseType } from '../../types/coursesType';
import { degreeType } from '../../types/degreeType';
import { educationalResourcesType } from '../../types/educationalResourceType';
import menuType from '../../types/menuType';
import { userType } from '../../types/userType';

import './index.scss';

// const mockRelatedCourses = require('../../mocks/related_courses.json');

const Degree = (props) => {
  const { degree: id } = props.match.params;
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    props.actions.getDegree(id);
  }, [props.actions, id]);

  const { token } = props.match.params;
  if (token) {
    window.localStorage.setItem('mail_validated', 'true');
  }

  const { degree, user } = props;

  const rightHeight =
    window.innerHeight - 180 > 600 ? window.innerHeight - 180 : 600;
  const heightBackground = { height: rightHeight };

  return (
    <>
      <VerifySiteWide user={user} idContent={degree.id} />
      <div className="page-wrap">
        <Header page="degree" educationalResource={degree} />

        {degree.loading ? (
          <Loader className="no-header" />
        ) : (
          <>
            {degree.other_settings && degree.other_settings.promo_url ? (
              <div className="home-background-back" style={heightBackground}>
                <Fade duration={1000} delay={500}>
                  <div className="home-background" style={heightBackground}>
                    <div className="_background-dark" />
                    <div className="_pattern-overlay" />

                    <Box
                      display={{ xs: 'none', md: 'block' }}
                      className="video-container"
                    >
                      <video
                        className="video-web"
                        autoPlay
                        muted
                        loop
                        playsInline
                      >
                        <source
                          src={degree.other_settings.promo_url}
                          type="video/mp4"
                        />
                      </video>
                    </Box>
                    <Box
                      display={{ xs: 'block', md: 'none' }}
                      className="video-container"
                    >
                      <video
                        className="video-mobile"
                        autoPlay
                        loop
                        muted
                        playsInline
                      >
                        <source
                          src={degree.other_settings.promo_url}
                          type="video/mp4"
                        />
                      </video>
                    </Box>
                  </div>
                </Fade>
              </div>
            ) : (
              <div>
                <div className="background-top" />
                <Fade duration={1000}>
                  <div
                    className="home-background degree"
                    style={
                      degree.media && {
                        backgroundImage: `url(${degree.media.wide})`,
                        ...heightBackground,
                      }
                    }
                  />
                </Fade>
              </div>
            )}

            <div className="page degree">
              <Container maxWidth="lg">
                <Jumbotron
                  type={i18n.t('CAREER')}
                  name={degree.name}
                  partners={degree.partners}
                  short_description={degree.short_description}
                  id={degree.id}
                  user={user}
                />
              </Container>

              <Container maxWidth="lg">
                <ActionBar pricing={degree.pricing} type="degree" />
              </Container>

              <a className="anchor" name="about" id="about" href="#about">
                About
              </a>
              <CourseAbout {...degree} />

              <a className="anchor" name="program" id="program" href="#program">
                Program
              </a>
              <DegreeProgram
                periods={degree.periods}
                user={user}
                id={degree.id}
              />

              <a className="anchor" name="mentor" id="mentor" href="#mentor">
                Mentor
              </a>
              <CourseMentor partners={degree.partners} />

              <MoreCourses courses={degree.related_contents} limit={2} />
            </div>
          </>
        )}
      </div>

      <Footer className="home" user={user} />
    </>
  );
};

Degree.propTypes = {
  actions: PropTypes.shape({
    getDegree: PropTypes.func,
  }),
  course: courseType,
  degree: degreeType,
  educationalResources: educationalResourcesType,
  enrollments: educationalResourcesType,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  menu: menuType,
  search: educationalResourcesType,
  staticContext: PropTypes.object,
  user: userType,
};

Degree.defaultProps = {
  actions: {
    getCourses: () => {},
  },
  course: {},
  degree: {},
  educationalResources: [],
  enrollments: [],
  menu: [],
  search: [],
  staticContext: {},
  user: {},
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getDegree: bindActionCreators(getDegree, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Degree);
