import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/lazy';
import { isMobile } from 'react-device-detect';
import Loader from '../Loader';

import './index.scss';

const VideoPlayer = React.forwardRef((props, ref) => {
  const [isLoading, setIsLoading] = useState(true);
  const [muteVideo, setMuteVideo] = useState(props.muted);
  const videoClassName = isMobile ? 'video-mobile' : 'video-web';

  useEffect(() => {
    setMuteVideo(props.muted);
  }, [props.muted]);

  return (
    <div className={'video-player'}>
      {isLoading && <Loader />}

      <ReactPlayer
        ref={ref}
        className={classnames(videoClassName, props.className)}
        playsinline
        config={
          isMobile
            ? {}
            : {
                file: {
                  forceHLS: true,
                },
              }
        }
        onEnded={() => props.onEnded(true)}
        onPlay={() => props.onPlay(false)}
        onStart={() => setIsLoading(false)}
        width="100%"
        height="92%"
        {...props}
        muted={muteVideo}
      />
    </div>
  );
});

VideoPlayer.displayName = 'VideoPlayer';

VideoPlayer.propTypes = {
  muted: PropTypes.bool,
  url: PropTypes.string,
  playing: PropTypes.bool,
  loop: PropTypes.bool,
  onEnded: PropTypes.func,
  onPlay: PropTypes.func,
  className: PropTypes.string,
};

VideoPlayer.defaultProps = {
  muted: false,
  className: '',
  url: '',
  playing: false,
  loop: false,
  onEnded: () => {},
  onPlay: () => {},
};

export default VideoPlayer;
