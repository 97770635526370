/* eslint-disable global-require */
/* eslint-disable no-undef */

import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { NavHashLink } from 'react-router-hash-link';

import i18n from '../../../languages';
import api from '../../../api';
import addLanguageToStaticUrl from '../../../utils/addLanguageToStaticUrl';

import { userType } from '../../../types/userType';

import logo from '../../../assets/images/logo-footer.svg';
import swissFlagIcon from '../../../assets/images/swiss-flag.svg';

import './index.scss';

const SOCIAL_LINKS = [
  {
    name: 'facebook-icon',
    link: 'https://www.facebook.com/oxygen.educations/',
    title: 'Facebook',
  },
  {
    name: 'instagram-icon',
    link: 'https://www.instagram.com/oxygen.education/',
    title: 'Instagram',
  },
  {
    name: 'linkedin-icon',
    link: 'https://www.linkedin.com/company/64273164/admin/',
    title: 'Linkedin',
  },
  {
    name: 'twitter-icon',
    link: 'https://twitter.com/EducationOxygen',
    title: 'Twitter',
  },
  {
    name: 'youtube-icon',
    link: 'https://www.youtube.com/channel/UCtH0hiDvJa0sgpBrHndFjiQ',
    title: 'Youtube',
  },
];

const Footer = ({ className, user }) => {
  const [error, setError] = useState({});
  const [success, setSuccess] = useState(false);
  const inputMarketingEmail = useRef(null);
  const date = new Date();

  const echoImage = (image) => {
    try {
      return require(`../../../assets/images/icons/social-icons/${image}.svg`)
        .default;
    } catch (e) {
      return false;
    }
  };

  const sendMarketingEmailFormSent = () => {
    const form = inputMarketingEmail.current;
    api.marketingEmail
      .sendMarketingEmail({
        email: form.marketingEmail.value,
        newsletter_subscription: true,
        id_content: 'newsletter',
        tag: 'newsletter',
      })
      .then(() => {
        setSuccess(true);
        window.localStorage.setItem('mail', form.marketingEmail.value);
        return false;
      })
      .catch((responseError) => {
        setError(responseError.response.data);
        return false;
      });
  };

  return (
    <footer className={classnames('footer', className)}>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="content"
        >
          <Grid item sm={4} className="logo">
            <a href="/">
              <img src={logo} className="footer-logo" alt="Oxygen" />
            </a>
            <div className="copyright-container">
              <img src={swissFlagIcon} className="flag" alt="Swiss" />
              <strong>
                &copy; {date.getFullYear()} {i18n.t('BRAND_NAME')}
              </strong>
              . {i18n.t('COPYRIGHT')}
            </div>
          </Grid>

          <Grid item xs={12} sm={8} className="footer-links">
            <Grid container>
              <Grid item xs={6} sm={3}>
                <h3>{i18n.t('BRAND_NAME')}</h3>
                <ul>
                  <li>
                    <a href={addLanguageToStaticUrl('/about')}>
                      {i18n.t('US')}
                    </a>
                  </li>
                  <li>
                    <NavHashLink
                      to="/#methodology"
                      activeClassName="selected"
                      smooth
                    >
                      {i18n.t('METHODOLOGY')}
                    </NavHashLink>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={6} sm={3}>
                <h3>{i18n.t('COMMUNITY')}</h3>
                <ul>
                  <li>
                    <NavHashLink
                      to="/#community"
                      activeClassName="selected"
                      smooth
                    >
                      {i18n.t('COMMUNITY_HUB')}
                    </NavHashLink>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={6} sm={3}>
                <h3>{i18n.t('EXPLORE')}</h3>
                <ul>
                  <li>
                    <a
                      href={addLanguageToStaticUrl(
                        `/list#${i18n.t('CAREERS').toLowerCase()}`,
                      )}
                    >
                      {i18n.t('CAREERS')}
                    </a>
                  </li>
                  <li>
                    <a
                      href={addLanguageToStaticUrl(
                        `/list#${i18n.t('COURSES').toLowerCase()}`,
                      )}
                    >
                      {i18n.t('PROGRAMS')}
                    </a>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={6} sm={3}>
                <h3>{i18n.t('MORE')}</h3>
                <ul>
                  <li>
                    <a
                      href={
                        i18n._culture === 'en'
                          ? 'https://help.oxygen.education/'
                          : 'https://help.oxygen.education/hc/es'
                      }
                    >
                      {i18n.t('CONTACT')}
                    </a>
                  </li>
                  <li>
                    <a href={addLanguageToStaticUrl('/info/terms')}>
                      {i18n.t('TERMS_AND_CONDITIONS')}
                    </a>
                  </li>
                  <li>
                    <a href={addLanguageToStaticUrl('/info/privacy')}>
                      {i18n.t('PRIVACY_POLICY')}
                    </a>
                  </li>

                  <li>
                    <a href={addLanguageToStaticUrl('/info/cookies')}>
                      {i18n.t('COOKIES_POLICY')}
                    </a>
                  </li>
                  <li>
                    <a href={addLanguageToStaticUrl('/info/legal')}>
                      {i18n.t('LEGAL_NOTICE')}
                    </a>
                  </li>
                </ul>
              </Grid>
            </Grid>

            <Grid container className="footer-data">
              <Grid item xs={12} sm={6}>
                {!user.logged && <h3>{i18n.t('SUBSCRIPTION')}</h3>}
              </Grid>
              <Grid item xs={12} sm={6} />

              <Grid item xs={12} sm={7}>
                {!user.logged && !success && (
                  <form ref={inputMarketingEmail}>
                    <TextField
                      name="marketingEmail"
                      label={i18n.t('ENTER_YOUR_EMAIL')}
                      variant="outlined"
                      className="enter-email"
                      error={!lodash.isEmpty(error)}
                      helperText={error && error.msg}
                    />
                    <Button
                      className="btn-white arrow-right md"
                      onClick={() => sendMarketingEmailFormSent()}
                    >
                      &nbsp;
                    </Button>
                  </form>
                )}

                {!user.logged && success && <div>Registration Successful</div>}
              </Grid>
              <Grid item xs={12} sm={5} className="footer-social-icons">
                {SOCIAL_LINKS.map(
                  (social) =>
                    social.name && (
                      <a
                        href={social.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={lodash.uniqueId('social_')}
                      >
                        <img
                          src={echoImage(social.name)}
                          className="icon"
                          alt=""
                        />
                        {social.title}
                      </a>
                    ),
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  user: userType,
};

Footer.defaultProps = {
  className: '',
  user: {},
};

export default Footer;
