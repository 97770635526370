/* eslint-disable no-undef */
import axios from 'axios';
import { config } from '../../config/config';

export const getCourse = (id) =>
  axios.get(`${config.API_DOMAIN}/oxygen/educationalresource/course/${id}`, {
    withCredentials: true,
  });

export default { getCourse };
