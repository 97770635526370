import {
  SEND_MARKETING_EMAIL_REQUEST,
  SEND_MARKETING_EMAIL_SUCCESS,
  SEND_MARKETING_EMAIL_ERROR,
} from '../constants';

const initialState = {
  error: null,
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEND_MARKETING_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SEND_MARKETING_EMAIL_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        loading: false,
        open: true,
      };
    case SEND_MARKETING_EMAIL_ERROR:
      return {
        ...state,
        loading: false,
        error: JSON.parse(action.payload.request.response),
        open: true,
      };

    default:
      return state;
  }
};
