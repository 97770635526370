/* eslint-disable no-undef */
import React from 'react';
import classnames from 'classnames';

import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from 'material-ui-popup-state/HoverMenu';

import {
  usePopupState,
  bindHover,
  bindMenu,
} from 'material-ui-popup-state/hooks';
import i18n from '../../../../languages';
import { userType } from '../../../../types/userType';

import './index.scss';

const UserMenu = ({ user }) => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'userMenu' });

  const { header_urls: headerUrls } = user;

  if (!headerUrls) {
    return null;
  }

  return (
    <div className="menu-list-component">
      <Button
        variant="contained"
        className={classnames(
          'btn-secondary sm dropdown login logged',
          { 'arrow-down': !popupState.isOpen },
          { 'arrow-up': popupState.isOpen },
          { black: popupState.isOpen },
        )}
        {...bindHover(popupState)}
        title={user.fullname || user.username}
      >
        {user.fullname || user.username}
      </Button>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        {...bindMenu(popupState)}
        className="user-dropdown"
      >
        {headerUrls.resume_block && (
          <MenuItem onClick={popupState.close} className="user-dropdown">
            <a href={headerUrls.resume_block}>
              {i18n.t('CONTINUE_LAST_COURSE')}
            </a>
          </MenuItem>
        )}

        {headerUrls.learner_profile && (
          <MenuItem onClick={popupState.close} className="user-dropdown">
            <a href={headerUrls.learner_profile}>{i18n.t('MY_PROFILE')}</a>
          </MenuItem>
        )}

        {headerUrls.account_settings && (
          <MenuItem onClick={popupState.close} className="user-dropdown">
            <a href={headerUrls.account_settings}>{i18n.t('ACCOUNT_SETUP')}</a>
          </MenuItem>
        )}

        {headerUrls.logout && (
          <MenuItem onClick={popupState.close} className="user-dropdown">
            <a href={headerUrls.logout}>{i18n.t('LOGOUT')}</a>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

UserMenu.propTypes = {
  user: userType,
};

UserMenu.defaultProps = {
  user: {
    fullname: '',
    username: '',
  },
};

export default UserMenu;
