export const DATA = {
  sponsors: [
    {
      image: 'fifa.svg',
      name: 'FIFA',
      link: '#',
    },
    {
      image: 'olympics.svg',
      name: 'Olympic Games',
      link: '#',
    },
    {
      image: 'fiba.svg',
      name: 'FIBA',
      link: '#',
    },
    {
      image: 'fjh.svg',
      name: 'FJH',
      link: '#',
    },
    {
      image: 'rugby.svg',
      name: 'Rugby',
      link: '#',
    },
    {
      image: 'plus.svg',
      name: 'Plus',
      link: '#',
    },
  ],
  slider: [
    {
      fullname: 'April Heinrichs',
      title: 'Nuestros Mentores',
      description:
        'Es una de las mujeres más influyentes en la historia del fútbol femenino.<br />Campeona del mundo como jugadora, Coach y Dirigente.<br />En 1998 se convirtió en la primera mujer en entrar al Hall of Fame del fútbol nacional americano.',
      linkCta: 'Leer más',
      image: 'BG-HEINRICH_Desktop.jpg',
    },
    {
      fullname: 'Ettore Messina',
      title: 'Nuestros Mentores',
      description:
        'Es considerado uno de los mejores entrenadores de basketball del mundo.<br />Ganó más de 30 títulos y ha entrenado en Italia, España, Rusia y los Estados Unidos (NBA : Los Angeles Lakers y San Antonio Spurs).',
      image: 'BG-MESSINA_Desktop.jpg',
      link: '/es/course/direccion-tecnica-profesional-basketball',
      linkCta: 'Leer más',
    },
    {
      fullname: 'Julio Velasco',
      title: 'Nuestros Mentores',
      description:
        'Es el entrenador de Voleibol más grande de todos los tiempos. Fue el único en ganar títulos con Selecciones Nacionales de tres continentes diferentes.<br />En 2003 Julio Velasco pasó a formar parte del Voleibol Hall of Fame.',
      image: 'BG-VELASCO_Desktop.jpg',
      linkCta: 'Leer más',
    },
    {
      fullname: 'Estanislao Bachrach',
      title: 'Nuestros Mentores',
      description:
        'Es una de las personas más influyentes de la neurociencia y la biología del cerebro en el mundo. Es autor de varios bestsellers, speaker mundial en universidades y empresas; y coach mental de celebridades deportivas.',
      image: 'BG-BACHRACH_Desktop.jpg',
      linkCta: 'Leer más',
    },
    {
      fullname: 'Carlos Martinez',
      title: 'Nuestros Mentores',
      description:
        'Es uno de los periodistas y narradores más reconocidos de Iberoamérica.<br /> La iconicidad de sus relatos trascienden el mundo offline, ya que es el narrador de uno de los videojuegos futbolísticos más importantes del mundo: PES',
      image: 'BG-MARTINEZ_Desktop.jpg',
      link: '/es/course/relato-deportivo',
      linkCta: 'Leer más',
    },
    {
      fullname: 'Jorge Valdano',
      title: 'Nuestros Mentores',
      description:
        'Ha sido un jugador de Elite y Campeón del Mundo en México 86.<br />DT y Director Deportivo del Real Madrid, Director General de la Escuela de Estudios Universitarios Real Madrid.<br />En 2017 la FIFA lo ha incluido en el Salón de la Fama.',
      image: 'BG-VALDANO_Desktop.jpg',
      link: '/es/course/fin-de-la-carrera-del-deportista-profesional',
      linkCta: 'Leer más',
    },
    {
      fullname: 'Juan Pablo Varsky',
      title: 'Nuestros Mentores',
      description:
        'Es uno de los periodistas con mayor credibilidad y transparencia de Latinoamérica.<br /> Con una trayectoria de más de 30 años, ha trabajado en los medios gráficos y audiovisuales más importantes de la región.',
      linkCta: 'Leer más',
      image: 'BG-VARSKY_Desktop.jpg',
    },
  ],
  about: {
    image: 'valdano.jpg',
    content:
      'La pasión por el fútbol sigue despertando mi curiosidad por el progreso imparable del deporte. Llevo una vida aprendiendo y ahora, lo tengo claro, es la hora de Oxygen.',
    fullname: 'Jorge Valdano',
    description: 'Cofundador y embajador de marca',
    link: '/es/la-historia-de-jorge-valdano',
    linkCta: 'Ver Mas',
  },
  about_detail: {
    achievements: [
      '<b>1986:</b> Campeón del Mundo en México con Argentina',
      '<b>1985-1986:</b> Campeón de la UEFA con el Real Madrid',
      '<b>1994-1996:</b> Entrenador del Real Madrid',
      '<b>1999-2003:</b> Director Deportivo del Real Madrid',
      '<b>2009-2011:</b> Director General del Real Madrid',
      '<b>Currently:</b> Analista Deportivo',
    ],
    content_history:
      '<p>No hay mejor inicio laboral que subirse arriba de una pasión, y la mía se llamó fútbol. Primero fue un gran placer recreativo, pero el tiempo fue autorizándome a soñar con el profesionalismo. Me represento el balón como un maravilloso vehículo que me sacó de mi pueblo con 16 años y de mi país con 19, siempre detrás del sueño.</p><p>El fútbol ocupó mi vida, conocí la otra cara del placer: la disciplina, las exigencias, la presión… Cuando el fútbol se convirtió en una obligación, busqué un placer sustitutivo en la lectura, aprovechando el tiempo libre que me dejaba el profesionalismo.</p>',
    quote1_title:
      'No hay mejor inicio laboral que subirse arriba de una pasion, y la mia se llamo futbol',
    quote1_description:
      '<p>Mientras terminaba los cursos como entrenador, me invitaron a escribir en el diario “El País”; me ofrecieron un programa de radio en la “Cadena Ser”, con un nombre un poco pretencioso: “La cátedra de Valdano”, y fui el primer comentarista en el entonces recién nacido “Canal Plus”.</p><p>El ofrecimiento de Florentino Pérez para que me hiciera cargo de la Dirección Deportiva del Real Madrid y, ya en el 2008, la Dirección General del club, me fueron alejando del entrenador, al tiempo que fue completando mis conocimientos sobre la ya pujante industria del fútbol. Florentino Pérez fue quién mejor entendió el futuro del fútbol como “negocio de héroes” y que los “Galácticos” marcaron un antes y un después en la asociación de fútbol y management.</p><p>Con el correr del tiempo, publiqué diez libros que dan la vuelta entera alrededor del fútbol como juego, como espectáculo y como cultura popular. Muchos de ellos son libros que se valen del deporte para hablar de liderazgo, trabajo en equipo, o gestión del talento. Como se puede apreciar, convertí el fútbol en mensaje.</p><p>El tiempo fue sofisticando al deporte y la tecnología terminó por ponerlo en otra dimensión. La industria, multiplica los ingresos año tras año y exige, en todos los ámbitos deportivos, gente con formación.</p>',
    quote2_title:
      'La pasion por el futbol sigue despertando mi curiosidad por el progreso imparable del deporte. Llevo una vida aprendiendo y ahora, lo tengo claro, es la hora de Oxygen',
    quote2_description:
      '<p>La pasión por el fútbol sigue despertando mi curiosidad por el progreso imparable del deporte. Llevo una vida aprendiendo y ahora, lo tengo claro, es la hora de “Oxygen”. Se trata de una plataforma académica que utiliza la red como canal de distribucion, apoyándonos en el conocimiento de grandes especialistas y en personajes de éxito que llevaron ese conocimiento a la práctica. Todos aquellos que aman el deporte y quieren convertirlo en un medio de trabajo, necesitan formarse. Quién quiera trabajar en deporte, debe estudiar deporte. Todos los deportes y en todos los ámbitos: técnico, salud, gestión, periodismo…</p><p>“Oxygen”, junto a Universidades con la que estableceremos alianzas, asegurará una formación de calidad impartida por la gente más relevante del mundo del deporte, para llegar a todos aquellos que sientan pasión por este maravilloso mundo. Daremos cursos, impartiremos títulos, crearemos comunidad, activaremos una Fundación y estaremos siempre a la vanguardia del conocimiento.</p><p>Si amas el deporte, búscanos y te encontrarás a ti mismo.</p>',
  },
  content_slider: [
    {
      image: 'bg-methodology.jpg',
      content:
        'Colaboramos con nuestros mentores e instituciones de prestigio, para crear experiencias de e-learning de alto nivel en sus áreas de especialización.',
    },
    {
      image: 'bg-methodology.jpg',
      content:
        'Usamos lo más actualizado en tecnología educativa, multimedia y herramientas interactivas para diseñar nuestras actividades de e-learning.',
    },
    {
      image: 'bg-methodology.jpg',
      content:
        'Producimos cortos documentales con atletas y mentores, que cuentan sus historias y proporcionan experiencias personales.',
    },
    {
      image: 'bg-methodology.jpg',
      content:
        'Proporcionamos nuestro contenido en un entorno de aprendizaje virtual que ofrece una rica experiencia de usuario que invita a la interactividad, la creatividad y la creación de comunidades.',
    },
  ],
  content_info: [
    {
      image: 'bg-community.jpg',
      title: 'Community<br />Hub',
      description:
        'La interacción es clave en el proceso de aprendizaje. Por eso Oxygen va a tener un espacio físico en el mundo virtual.<br />Dentro de él podrás caminar, explorar, interactuar con la comunidad, acudir a eventos sociales, asistir a charlas, y estar al tanto de las últimas innovaciones deportivas, mientras formas parte de una experiencia virtual única que conecta a todo el mundo del deporte.<br />Regístrate ahora. Las puertas abrirán pronto.',
      link: 'MODAL',
      link_description: 'Notificarme',
    },
    {
      image: 'bg-foundation.jpg',
      title: 'Funda-cion',
      description:
        'La fundación Oxygen tiene una misión clara: brindar contenido académico a jóvenes deportistas, personas de escasos recursos económicos o madres que tuvieron que abandonar sus carreras por motivos de maternidad.<br /><br /> A través de convenios con fundaciones e instituciones, Oxygen implementará planes globales con el objetivo de que todos, sin importar el país, puedan acceder a conocimientos que les permitan ser competitivos en el mundo del deporte.',
      link: '',
      link_description: '',
    },
  ],
};

export default DATA;
