/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import lodash from 'lodash';

import i18n from '../../languages';

import Header from '../../components/Common/Header';
import Footer from '../../components/Common/Footer';
import Loader from '../../components/Common/Loader';
import CategoryBox from '../../components/Common/CategoryBox';
import MetaTags from '../../components/Common/MetaTags';

import { getMenu } from '../../redux/actions/menu';
import selectLanguage from '../../utils/selectLanguage';

import './index.scss';

const List = ({ match, menu }) => {
  const { pathname } = useLocation();
  const { lang } = match.params;

  useEffect(() => {
    selectLanguage(lang);
    window.scrollTo(0, 0);
  }, [pathname, lang]);

  return (
    <>
      <MetaTags description={i18n.t('PAGE_LIST_DESCRIPTION')} />
      <div className="page-wrap">
        <Header theme="black" />
        {menu.loading ? (
          <Loader className="no-header" />
        ) : (
          <div className="page list">
            {menu.map((category) => (
              <CategoryBox
                category={category}
                key={lodash.uniqueId('category_box_')}
              />
            ))}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

List.propTypes = {
  menu: PropTypes.arrayOf({}),
  actions: PropTypes.objectOf({
    getMenu: PropTypes.func,
  }),
};

List.defaultProps = {
  menu: [],
  actions: {},
};

const mapStateToProps = (state) => ({
  menu: state.menu,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getMenu: bindActionCreators(getMenu, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
