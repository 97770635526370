/* eslint-disable no-nested-ternary */
import React from 'react';
import lodash from 'lodash';
import Grid from '@material-ui/core/Grid';

import trackAction from '../../../utils/tracking';

import { educationalResourcesType } from '../../../types/educationalResourceType';

import './index.scss';
import ThumbnailCourse from '../../Common/ThumbnailCourse';

const CoursesGallery = ({ educationalResources }) => {
  const itemLimit = 8;

  // Tracking
  educationalResources.length > 0 &&
    trackAction('view_item_list', educationalResources);

  return (
    <div className="courses-gallery">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        className="grid-container courses-gallery-container"
        spacing={2}
      >
        {educationalResources &&
          educationalResources.length > 0 &&
          educationalResources.slice(0, itemLimit).map((item, index) => {
            const isTheLastUnpair =
              (itemLimit === 8 || itemLimit === 5) && index === itemLimit - 1;
            const gridLayout = isTheLastUnpair
              ? 12
              : (index / 3) % 1 === 0
              ? 12
              : 6;

            return (
              <Grid
                item
                xs={12}
                sm={12}
                md={gridLayout}
                key={lodash.uniqueId('gallery_')}
              >
                <ThumbnailCourse
                  course={item}
                  index={index}
                  key={lodash.uniqueId('gallery_')}
                  forceBig={isTheLastUnpair}
                />
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};

CoursesGallery.propTypes = {
  educationalResources: educationalResourcesType,
};

CoursesGallery.defaultProps = {
  educationalResources: [],
};

export default CoursesGallery;
