/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import { getUser } from '../../../redux/actions/user';
import { getMenu } from '../../../redux/actions/menu';

import StaticHeader from './StaticHeader';
import StickyHeader from './StickyHeader';
import MainMenu from '../MainMenu';
import MainMenuMobile from '../MainMenu/Mobile';

import { userType } from '../../../types/userType';
import { educationalResourceType } from '../../../types/educationalResourceType';

import menuType from '../../../types/menuType';

import './index.scss';

const Header = ({
  actions,
  educationalResource,
  user,
  selectedTab,
  setSelectedTab,
  page,
  theme,
  menu,
  scrollCallback,
  menuOpenCallback,
  hasEnrollment,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    actions.getUser();
    actions.getMenu();
  }, [actions]);

  useEffect(() => {
    menuOpenCallback(menuOpen);
  }, [menuOpen]);

  const toggleDrawer = () => (event) => {
    if (
      event.currentTarget.className === 'main-menu' ||
      (event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift'))
    ) {
      return;
    }
    setMenuOpen(menuOpen === false);
  };

  const fullList = () => (
    <div
      role="presentation"
      className="main-menu"
      onClick={toggleDrawer(menuOpen)}
      onKeyDown={toggleDrawer(menuOpen)}
    >
      <Container maxWidth="lg">
        <Box display={{ xs: 'none', md: 'block' }}>
          <MainMenu user={user} menu={menu} />
        </Box>
        <Box display={{ xs: 'flex', md: 'none' }}>
          <MainMenuMobile user={user} menu={menu} />
        </Box>
      </Container>
    </div>
  );

  return (
    <>
      <header className="header">
        <StaticHeader
          user={user}
          theme={menuOpen ? 'black' : theme}
          clickMenu={toggleDrawer(menuOpen)}
          menuOpen={menuOpen}
          page={page}
          educationalResource={educationalResource}
          scrollCallback={scrollCallback}
          hasEnrollment={hasEnrollment}
        />

        {(page === 'course' || page === 'degree') && (
          <Hidden only={['xs', 'sm']}>
            <StickyHeader
              educationalResource={educationalResource}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              user={user}
            />
          </Hidden>
        )}

        <Drawer
          anchor="top"
          open={menuOpen}
          onClose={toggleDrawer(menuOpen)}
          transitionDuration={600}
          className="menu-container"
        >
          {fullList()}
        </Drawer>
      </header>
    </>
  );
};

const mapStateToProps = (state) => ({
  menu: state.menu,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getMenu: bindActionCreators(getMenu, dispatch),
    getUser: bindActionCreators(getUser, dispatch),
  },
});

Header.propTypes = {
  educationalResource: educationalResourceType,
  user: userType,
  menu: menuType,
  page: PropTypes.string,
  theme: PropTypes.string,
  actions: PropTypes.shape({
    getUser: PropTypes.func,
    getMenu: PropTypes.func,
  }),
  selectedTab: PropTypes.number,
  setSelectedTab: PropTypes.func,
  scrollCallback: PropTypes.func,
  menuOpenCallback: PropTypes.func,
  hasEnrollment: PropTypes.bool,
};

Header.defaultProps = {
  educationalResource: {},
  user: {},
  menu: [],
  page: '',
  theme: '',
  actions: {
    getUser: () => {},
    getMenu: () => {},
  },
  selectedTab: 0,
  setSelectedTab: () => {},
  scrollCallback: () => {},
  menuOpenCallback: () => {},
  hasEnrollment: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
