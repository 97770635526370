/* eslint-disable no-undef */
import axios from 'axios';
import { config } from '../../config/config';

export const getEnrollments = () =>
  axios.get(`${config.API_DOMAIN}/oxygen/educationalresource/enrollment`, {
    withCredentials: true,
  });

export default { getEnrollments };
