import { arrayOf, shape, string, number } from 'prop-types';

import { partnersType } from './partnersType';
import mediaType from './mediaType';
import { pricingType } from './pricingType';
import languageType from './languageType';

export const degreeType = shape({
  available_language: languageType,
  id: number,
  name: string,
  effort: string,
  end: string,
  end_display: string,
  media: mediaType,
  short_description: string,
  start: string,
  start_display: string,
  language: languageType,
  overview: string,
  other_langs: arrayOf(string),
  oxygen_type: string,
  oxygen_subtype: string,
  other_settings: {
    partner: {
      logo_url: string,
      description: string,
    },
    layout: string,
    order: number,
  },
  subjects: arrayOf({
    id: number,
    short_description: string,
    ovrrview: string,
    year: string,
    name: string,
    subject_type: string,
    subjects_required: arrayOf(string),
  }),
  pricing: arrayOf(pricingType),
  partners: arrayOf(partnersType),
});

export const degreeTypes = arrayOf(degreeType);
