/* eslint-disable no-undef */
import axios from 'axios';
import { config } from '../../config/config';

export const getDegree = (id) =>
  axios.get(`${config.API_DOMAIN}/oxygen_degree/degrees/${id}`, {
    withCredentials: true,
  });

export default { getDegree };
