/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Grid from '@material-ui/core/Grid';
import { Hidden } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';

import i18n from '../../../languages';
import { config } from '../../../config/config';

import { getSearch } from '../../../redux/actions/search';
import addLanguageToStaticUrl from '../../../utils/addLanguageToStaticUrl';
import { getVariablesToSend } from '../../../utils/getUtmParams';

import DropdownMenu from './DropdownMenu';

import SearchResults from './SearchResults';

import { coursesType } from '../../../types/coursesType';
import { userType } from '../../../types/userType';
import menuType from '../../../types/menuType';

import SearchIcon from '../../../assets/images/search-light.svg';
import SearchCloseIcon from '../../../assets/images/search-close-light.svg';
import './index.scss';
import ThumbnailCourse from '../ThumbnailCourse';

const MainMenu = (props) => {
  const [menuItemShow, setMenuItemShow] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const { menu, search, user } = props;
  const { header_urls: headerUrls } = user;

  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  };

  const onChange = async (text) =>
    (await text.length) > 2 && debounce(props.actions.getSearch(text), 800);

  const searchButton = () => {
    setSearchValue('');
    setIsSearching(false);
  };

  const params = getVariablesToSend() && `&${getVariablesToSend()}`;

  return (
    menu &&
    menu.length > 0 && (
      <div className="menu">
        <Hidden>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={12} sm={6} className="main-menu-container">
              <Grid item sm={10} className="search-container">
                <form onSubmit={(e) => e.preventDefault()}>
                  <Paper component="form">
                    <TextField
                      label={i18n.t('WHAT_YOU_WANT_LEARN')}
                      variant="outlined"
                      className="main-search"
                      onFocus={() => setIsSearching(true)}
                      onChange={(e) => {
                        if (e.target.value.length === 0) {
                          searchButton();
                        } else {
                          setIsSearching(true);
                          setSearchValue(e.target.value);
                          onChange(e.target.value);
                        }
                      }}
                      value={searchValue}
                    />
                    <IconButton
                      aria-label="search"
                      onClick={() => isSearching && searchButton()}
                    >
                      {!isSearching ? (
                        <img
                          src={SearchIcon}
                          className="icon-search"
                          alt="Search"
                        />
                      ) : (
                        <img
                          src={SearchCloseIcon}
                          className="icon-search"
                          alt="Close"
                        />
                      )}
                    </IconButton>
                    <Link
                      to={addLanguageToStaticUrl('/list')}
                      className="btn-secondary arrow-right white md explore-courses"
                    >
                      {i18n.t('EXPLORE_ALL')}
                    </Link>
                  </Paper>
                </form>
              </Grid>
              <Grid item xs={12} sm={12} className="menu-content">
                {!isSearching ? (
                  <>
                    <Box
                      display={{ xs: 'block', md: 'none' }}
                      className="menu-mobile"
                    >
                      <DropdownMenu
                        menu={menu}
                        overMenu={(menuItem) => setMenuItemShow(menuItem)}
                      />
                    </Box>
                    <Box
                      display={{ xs: 'none', md: 'block' }}
                      className="menu-desktop"
                    >
                      <DropdownMenu
                        menu={menu}
                        overMenu={(menuItem) => setMenuItemShow(menuItem)}
                      />
                    </Box>
                  </>
                ) : search && search && search.length > 0 ? (
                  <SearchResults results={search} />
                ) : (
                  <SearchResults results={[]} />
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Hidden only={['xs', 'sm']}>
                {menuItemShow && !isSearching ? (
                  <ThumbnailCourse course={menuItemShow} forceOver />
                ) : (
                  ''
                )}
              </Hidden>

              <Box
                display={{ xs: 'block', md: 'none' }}
                className="menu-mobile"
              >
                <hr />

                <h2>
                  {props.user.logged ? (
                    user.fullname || user.username
                  ) : (
                    <>
                      <a
                        href={`https:${config.URL_EDX_DOMAIN}/register?next=https%3A%2F%2F${config.URL_SITE_DOMAIN}%2F${params}`}
                      >
                        {i18n.t('REGISTER')}
                      </a>
                      <a
                        href={`https:${config.URL_EDX_DOMAIN}/login?next=https%3A%2F%2F${config.URL_SITE_DOMAIN}%2F`}
                      >
                        {i18n.t('LOGIN')}
                      </a>
                    </>
                  )}
                </h2>
                <h3>
                  <a href={process.env.REACT_APP_COMMUNITY_URL}>
                    {i18n.t('COMMUNITY')}
                  </a>
                </h3>

                <hr />

                {props.user.logged && (
                  <ul>
                    {headerUrls.resume_block && (
                      <li>
                        <a href={headerUrls.resume_block}>
                          {i18n.t('CONTINUE_LAST_COURSE')}
                        </a>
                      </li>
                    )}
                    {headerUrls.learner_profile && (
                      <li>
                        <a href={headerUrls.learner_profile}>
                          {i18n.t('MY_PROFILE')}
                        </a>
                      </li>
                    )}
                    {headerUrls.account_settings && (
                      <li>
                        <a href={headerUrls.account_settings}>
                          {i18n.t('ACCOUNT_SETUP')}
                        </a>
                      </li>
                    )}
                    {headerUrls.logout && (
                      <li>
                        <a href={headerUrls.logout}>{i18n.t('LOGOUT')}</a>
                      </li>
                    )}
                  </ul>
                )}
              </Box>
            </Grid>
          </Grid>
        </Hidden>
      </div>
    )
  );
};

MainMenu.propTypes = {
  actions: PropTypes.shape({
    getSearch: PropTypes.func,
  }),
  menu: menuType,
  search: {
    error: PropTypes.string,
    loading: PropTypes.bool,
    pagination: PropTypes.shape({
      count: PropTypes.integer,
      next: PropTypes.string,
      num_pages: PropTypes.integer,
      previous: PropTypes.string,
    }),
    results: coursesType,
  },
  user: userType,
};

MainMenu.defaultProps = {
  actions: {
    getSearch: () => {},
  },
  menu: [],
  search: {
    error: null,
    loading: false,
    pagination: {},
    results: [],
  },
  user: {
    fullname: '',
    username: '',
  },
};

const mapStateToProps = (state) => ({
  search: state.search,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getSearch: bindActionCreators(getSearch, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
