const spanish = {
  BRAND_NAME: 'Oxygen',
  BRAND_TITLE: 'Advanced Sports Education',
  BRAND_DESCRIPTION:
    'La referencia mundial en educación online deportiva, impulsada por la sabiduría de nuestros mentores, la tecnología de vanguardia y nuestro diseño único de e-learning.',

  CREATE_ACCOUNT: 'Empieza ahora',
  CONTINUE_COURSES: 'Continúa tus programas',
  CONTINUE: 'Continuar',
  CLOSE: 'Cerrar',
  LEARN_MORE: 'Aprende mas',
  STARTING_DATES: 'FECHAS DISPONIBLES',
  STARTING_DATES_DISCLAIMER:
    'Los webinars y otros contenidos pueden estar sujetos  a modificaciones. Para más detalles, comuníquese con nuestros canal de soporte.',

  EXPLORE: 'Explorar',
  COMMUNITY: 'Community Hub',
  LOGIN: 'Iniciar Sesion',
  REGISTER: 'Registrate',
  MENU: 'Menu',

  CONTINUE_LAST_COURSE: 'Reanudar ultimo Programa',
  MY_PROFILE: 'Mi Perfil',
  ACCOUNT_SETUP: 'Configuracion',
  LOGOUT: 'Cerrar Sesion',

  US: 'Nosotros',
  METHODOLOGY: 'Metodología',
  METHODOLOGY_CROP: 'Meto-dologia',
  COMMUNITY_HUB: 'Community Hub',
  HELP_CENTER: 'Centro de ayuda',

  FUNDATION: 'Fundación',
  PARTNERS: 'Partners',
  MENTORS: 'Mentores',
  BILLBOARD: 'Cartelera',
  BACKYARD: 'El Patio',
  CLASS: 'Clase',
  HELP: 'Centro de Ayuda',
  CAREER: 'Carrera',
  CAREERS: 'Carreras',
  COURSE: 'Programa',
  COURSES: 'Programas',
  PROGRAMS: 'Programas',
  TIPS: 'Tips',
  CONTACT: 'Centro de Ayuda',
  TERMS_AND_CONDITIONS: 'Términos y Condiciones',
  PRIVACY_POLICY: ' Política de Privacidad',
  COOKIES_POLICY: 'Políticas de Cookies',
  LEGAL_NOTICE: 'Aviso Legal',

  COPYRIGHT: 'Todos los derechos reservados',
  FAQS: 'FAQS',
  SUBSCRIPTION: 'Suscripcion',
  ENTER_YOUR_EMAIL: 'Ingresa tu Email',
  MORE: 'Mas',
  FEATURED_CONTENT: 'Contenidos Destacados',

  EXPLORE_ALL: 'Explorar todos',
  ENTER: 'Ingresar',
  WHAT_YOU_WANT_LEARN: '¿Que quieres aprender?',

  REMIND_ME: 'Notificarme',
  REMIND_ME_WHEN: 'Cuando se abran las inscripciones',

  VIEW_PROGRAM: 'Ver Programa',
  GO_BACK: 'Volver',

  VIEW_ALL_COURSES_AND_CAREERS: 'Ver todos los Programas y Carreras',
  ABOUT: 'Acerca de',
  ABOUT_US: 'Acerca de nosotros',
  HEADQUARTERS: 'Oficina Central',
  SCHEDULE: 'Programa',
  MENTOR: 'Mentor',
  SUBSCRIBE_COURSE: 'Suscribirse al Programa',
  SUBSCRIBE_CAREER: 'Suyscribirse a la Carrera',

  ACHIEVEMENTS: 'Principales logros',
  HISTORY: 'Mi Historia',

  TAXES_NOT_INCLUDED: 'Impuestos no incluídos',
  AVAILABLE_CURRENCIES: 'Disponible en las siguientes monedas: ',

  ONE_TIME_PAYMENT: 'Un solo pago (Valor Total)',
  TIME_100_ONLINE: '100% Online',
  START_NOW_AND_LEARN: 'Versión desktop.',
  START: 'Inicio',
  TO_COMPLETE: 'para completar',
  MONTHS: 'Meses',
  YEARS: 'Años',
  SUGGESTED_TIME: 'Sugerido 8 horas / semana.',
  TEACHING_TEAM: 'Equipo Docente',
  LANGUAGE: 'Idioma',
  REQUIREMENTS: 'Requerimientos',
  REQUIREMENTS_DESCRIPTION: 'Conexión a Internet, Auriculares.',
  INCLUDES: 'Incluye',
  STUDY_PROGRAM: 'Programa<br />de Estudio',
  MORE_COURSES: 'Más programas interesantes',
  MENTOR_OF_THIS_COURSE: 'Mentor de este programa',
  SEND_AGAIN: 'Enviar nuevamente',
  SUBMIT: 'Enviar',
  COMING_SOON: 'Proximamente',
  COMING_SOON_LOWER_CASE: 'Próximamente',
  SHARE_COURSE_PROGRAM: 'Compartir Programa',
  SHARE_COURSE_PROGRAM_TITLE: 'Cuentale a tus amigos acerca de este programa',

  DOWNLOAD_SCHEDULE: 'Obtener programa de estudio',
  OPEN_INSCRIPTION: 'INSCRIPCION ABIERTA',
  BUY_INSCRIPTION: '¡Comienza Ahora!',

  SPANISH: 'Español',
  ENGLISH: 'Inglés',
  PORTUGUESE: 'Portugués',

  FROM: 'Del',
  TO: 'al',
  THANKS_SIGNING_UP: '¡Gracias por suscribirse!',
  COURSE_STARTS: 'Inicio del Programa',
  DAYS: 'Días',
  HOURS: 'Horas',
  MINUTES: 'Minutos',
  YES: 'Si',
  No: 'No',

  MESSAGE_FOR_VALDANO: 'Mensaje para Jorge Valdano',

  CREATE_ACCOUNT_BANNER:
    '¡CREA TU CUENTA PARA FORMAR PARTE DE NUESTRA COMUNIDAD!',
  VERIFY_EMAIL_BANNER:
    'POR FAVOR VERIFICA TU EMAIL PARA COMPLETAR TU REGISTRACIÓN',

  MODAL_WELCOME_USER_TITLE: 'Ya eres parte de la Comunidad Oxygen.',
  MODAL_WELCOME_USER_DESCRIPTION:
    '¡Bienvenido! ¡Mantente conectado para recibir nuestras ultimas noticias y novedades!',
  MODAL_WELCOME_USER_BUTTON: 'Empieza a explorar',

  MODAL_EARLY_BID_TITLE: '¡Obten tu 10% de Descuento AHORA!',
  MODAL_EARLY_BID_DESCRIPTION:
    'Crea tu cuenta y recibe tu cupón de descuento “EARLY BIRD“ ¡INSCRIPCIONES ABIERTAS!',
  MODAL_EARLY_BID_DISCLAIMER:
    'Cupón de Descuento EARLY BIRD válido hasta el 1 de Abril de 2022.',
  MODAL_EARLY_BID_OK: 'Obten Descuento',

  MODAL_GET_PROGRAM_TITLE: 'Obtén el programa completo.',
  MODAL_GET_PROGRAM_DESCRIPTION:
    'Ingresa tu nombre completo y correo electrónico abajo y obtén acceso a toda nuestra oferta académica.',
  MODAL_GET_PROGRAM_DISCLAIMER:
    'Al enviar este formulario, acepto que mi información será utilizada para contactarme en el contexto de mi solicitud y la relación comercial que pueda resultar. Confirmo que también he leído la ',
  MANDATORY_FIELD: 'Campo Obligatorio',

  MODAL_ENTER_EMAIL_TITLE: '¡Ya estás casi listo para recibir recordatorios!',
  MODAL_ENTER_EMAIL_DESCRIPTION:
    'Ingresa tu nombre completo y correo electrónico abajo para ser informado',

  MODAL_ENTER_EMAIL_REMINDME_TITLE: 'Revisa tu correo',
  MODAL_ENTER_EMAIL_REMINDME_THANKS:
    'Te enviamos un email así podremos verificar que eres tú',

  MODAL_ENTER_EMAIL_SEND: 'Enviar',
  MODAL_ENTER_EMAIL_THANKS: '¡Gracias! Por favor revisa tu casilla de email',

  MODAL_ENTER_EMAIL_AGAIN_NOT_VERIFIED_REMINDME_TITLE:
    '¡Salta al campo de juego!',
  MODAL_ENTER_EMAIL_AGAIN_NOT_VERIFIED_REMINDME_DESCRIPTION:
    'Verifica tu email para recibir notificaciones de nuestras actualizaciones',

  MODAL_ENTER_EMAIL_AND_VERIFIED_REMINDME_TITLE: '¡Te mantendremos informado!',
  MODAL_ENTER_EMAIL_AND_VERIFIED_REMINDME_DESCRIPTION:
    'Te avisaremos cuando este programa esté disponible',

  EMAIL_VERIFICATION_TITLE: 'Verificación de Email',
  EMAIL_VERIFICATION_DESCRIPTION:
    '¡Por favor ingresa tu casilla de email así podremos verificar que eres tú!',
  EMAIL_VERIFICATION_THANKS_DESCRIPTION:
    '¡Muchas gracias! Por favor chequea tu casilla para verificar tu email.',

  MODAL_COMPLETE_REGISTRATION_TITLE: '¡Revisa tu email!',
  MODAL_COMPLETE_REGISTRATION_DESCRIPTION:
    'Confirma el link que te enviamos a {email} para completar tu cuenta',

  MODAL_COMPLETE_DIDNT_RECEIVE: 'No recibí el Email',

  MODAL_GET_PROGRAM_VERIFY_TITLE: '¡Ya casi estás listo!',
  MODAL_GET_PROGRAM_VERIFY_THANKS:
    '¡Muchas gracias! Por favor verifica tu email para continuar.',
  BUTTON_OK: 'Continuar',
  MODAL_ENTER_EMAIL_SEND_OK: 'Enviar',

  MODAL_LANGUAGE_AVAILABLE_TITLE:
    'Este programa esta disponible en otro idioma.',
  MODAL_LANGUAGE_AVAILABLE_DESCRIPTION: '¿Quieres continuar con la compra?',
  MODAL_LANGUAGE_AVAILABLE_THANKS_TITLE: 'Gracias.',
  MODAL_LANGUAGE_AVAILABLE_THANKS:
    'Te gustaría que Oxygen ofrezca una version en Español de este programa?',

  EMAIL_INVALID: 'El formato del email es inválido',
  FIELDS_INCOMPLETED: 'Todos los campos con * son requeridos',

  COFOUNDERS: 'Co-Fundadores',
  ABOUT_US_CONTENT:
    'Somos personas que venimos de diferentes industrias, pero todos tenemos algo en común: Somos amantes del deporte y creemos que su democratización puede hacer un mundo mejor.',
  LETS_TALK: 'Hablemos',
  PARTNER_INTERESTED:
    '¿Estás interesado en asociarte con Oxygen? ¡Te esperamos!',

  PARTNERSHIP_WITH_OXYGEN: 'Partnership with Oxygen',
  MEDIA: 'Media',
  UNIVERSITY: 'University',
  TECH: 'Tech',
  SPORT: 'Sport',

  IN_PARTNERSHIP: 'EN PARTNERSHIP CON',

  PAGE_DEFAULT_TITLE: 'Oxygen - Advanced Sports Education',
  PAGE_DEFAULT_DESCRIPTION:
    'La referencia mundial en educación online deportiva, impulsada por la sabiduría de nuestros mentores, la tecnología de vanguardia y nuestro diseño único de e-learning.',

  PAGE_LIST_TITLE: '',
  PAGE_LIST_DESCRIPTION: 'Todos los programas y carreras de Oxygen',

  PAGE_ABOUT_TITLE: 'Sobre Nosotros. Contactanos.',
  PAGE_ABOUT_DESCRIPTION: 'Sobre Nosotros. Contactanos.',

  PAGE_ABOUT_DETAIL_TITLE: 'La historia de Jorge Valdano, Cofundador de Oxygen',
  PAGE_ABOUT_DETAIL_DESCRIPTION:
    'Nuestro Embajador de Marca expresa sus sentimientos acerca de la Educacion en el Deporte, Deportistas Profesionales, Industria del Deporte, e-Learning',

  PAGE_TERMS_TITLE: 'Terminos y Condiciones Generales',
  PAGE_TERMS_DESCRIPTION: 'Terminos y Condiciones Generales',

  PAGE_PRIVA_TITLE: 'Politica de Privacidad y Proteccion de Datos',
  PAGE_PRIVA_DESCRIPTION: 'Politica de Privacidad y Proteccion de Datos',

  NAME: 'Nombre',
  LAST_NAME: 'Apellido',

  AVAILABLE_DAYS_RULES:
    'Las fechas disponibles se calculan de acuerdo a la zona horaria UTC',

  BUYABLE_COURSE: 'PREINSCRIPCION ABIERTA / ',
};

export default spanish;
