import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';

import FacebookShareIcon from '../../../../assets/images/share/facebook.svg';
import LinkedinShareIcon from '../../../../assets/images/share/in.svg';
import TwitterShareIcon from '../../../../assets/images/share/twitter.svg';
import MailShareIcon from '../../../../assets/images/share/mail.svg';
// import LinkShareIcon from '../../../../assets/images/share/link.svg';

import i18n from '../../../../languages';

import './index.scss';

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 600,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
}));

const makeHashtag = (str) => {
  const wordArray = str.split(' ').filter((char) => char !== '');
  let result = '#';

  if (wordArray.length === 0) {
    return false;
  }

  result += wordArray
    .map((word) => {
      const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
      return capitalizedWord;
    })
    .join('');

  if (result.length > 140) {
    return false;
  }
  return result.replace(/[^a-zA-Z ]/g, '');
};

const ShareModal = ({ open, handleClose, name, description }) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2>
        {i18n.t('SHARE_COURSE_PROGRAM_TITLE')}
        <IconButton onClick={handleClose} className="close">
          <CloseIcon />
        </IconButton>
      </h2>

      <>
        <Grid container className="form-content">
          <Grid item xs={12} sm={12}>
            <p>
              <FacebookShareButton
                className="share facebook"
                url={window.location.href}
                quote={name && name}
                hashtag={name && makeHashtag(name)}
              >
                <img src={FacebookShareIcon} alt="Facebook" />
              </FacebookShareButton>
              <LinkedinShareButton
                className="share linkedin"
                url={window.location.href}
                title={name && name}
                summary={description && description}
              >
                <img src={LinkedinShareIcon} alt="Linkedin" />
              </LinkedinShareButton>
              <TwitterShareButton
                className="share twitter"
                url={window.location.href}
                title={name && name}
                hashtags={
                  name && [
                    makeHashtag(name),
                    makeHashtag(i18n.t('BRAND_NAME')),
                    makeHashtag(i18n.t('BRAND_TITLE')),
                  ]
                }
              >
                <img src={TwitterShareIcon} alt="Twitter" />
              </TwitterShareButton>
              <EmailShareButton
                className="share email"
                url={window.location.href}
              >
                <img src={MailShareIcon} alt="Mail" />
              </EmailShareButton>
            </p>
          </Grid>
        </Grid>
      </>
    </div>
  );

  /*
    <button className="share link" onClick={copyCodeToClipboard}>
      <img src={LinkShareIcon} alt="Link" />
    </button>
  */

  return (
    <Modal className="share-email-modal" open={open} onClose={handleClose}>
      {body}
    </Modal>
  );
};

ShareModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  idContent: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
};

ShareModal.defaultProps = {
  open: false,
  handleClose: () => {},
  idContent: '',
  name: '',
  description: '',
};

export default ShareModal;
