import classnames from 'classnames';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import BuyButton from '../../Common/BuyButton';
import EnterEmailModal from '../../Common/Modals/EnterEmail';

import { userType } from '../../../types/userType';

import i18n from '../../../languages';

import VideoMuteIcon from '../../../assets/images/icon-video-muted.svg';
import VideoUnmuteIcon from '../../../assets/images/icon-video-unmuted.svg';
import VideoReplayIcon from '../../../assets/images/icon-video-replay.svg';
import BackIcon from '../../../assets/images/arrow-left-back.svg';

import { pricingType } from '../../../types/pricingType';
import { educationalResourceType } from '../../../types/educationalResourceType';

import './index.scss';

const Jumbotron = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [mute, setMute] = useState(true);
  let title = i18n.t('BRAND_TITLE');
  const titleWordsQty =
    title && title.trim().replace(/\s+/gi, ' ').split(' ').length;
  if (titleWordsQty <= 3) {
    title = title.replace(/\s/g, '<br />');
  }

  const setMuteClick = (muteClick) => {
    setMute(muteClick);
    props.setMuteCallback(muteClick);
  };

  const setReplayClick = () => {
    props.setReplayCallback(true);
  };

  return (
    <div className={classnames('jumbotron', { stop: !props.stop })}>
      <Grid container>
        <Grid item xs={12} sm={8} md={7} className="content">
          <Link to="/" className="back-button">
            <img src={BackIcon} alt="Back" />{' '}
            {i18n.t('VIEW_ALL_COURSES_AND_CAREERS')}
          </Link>
          <h3>{i18n.t(props.type)}</h3>
          <h1>{props.name}</h1>
          <h2>
            {props.partners && props.partners[0] && props.partners[0].fullname}
          </h2>
          <p>{props.short_description}</p>
        </Grid>

        <Grid item xs={12} sm={4} md={5} className="content-right">
          {!props.educationalResource.bought ? (
            <div className="buy-course-container">
              <BuyButton
                available_language={
                  props.educationalResource.available_language
                }
                educationalResource={props.educationalResource}
                language={props.educationalResource.language}
                pricing={props.educationalResource.pricing}
                openDescription
                status={props.educationalResource.status}
                id={props.educationalResource.id}
              />
            </div>
          ) : (
            (!window.localStorage.getItem('mail_validated') ||
              window.localStorage.getItem('mail_validated') === 'false') &&
            !props.user.logged && (
              <>
                <button
                  type="button"
                  onClick={setOpenModal}
                  className="btn-primary md arrow-right get-course-program"
                >
                  {i18n.t('DOWNLOAD_SCHEDULE')}
                </button>
              </>
            )
          )}

          {props.hasVideo && (
            <div className="control-buttons">
              <img
                src={mute ? VideoMuteIcon : VideoUnmuteIcon}
                className="video-mute-icon"
                alt="Mute"
                onClick={() => setMuteClick(!mute)}
              />
              <img
                src={VideoReplayIcon}
                className="video-replay-icon"
                alt="Replay"
                onClick={setReplayClick}
              />
            </div>
          )}
        </Grid>
      </Grid>

      <EnterEmailModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        idContent={props.educationalResource.id}
        contentType={props.educationalResource.type}
      />
    </div>
  );
};

Jumbotron.propTypes = {
  educationalResource: educationalResourceType,
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      fullname: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.string,
    }),
  ),
  pricing: PropTypes.arrayOf(pricingType),
  user: userType,
  hasVideo: PropTypes.bool,
};

Jumbotron.defaultProps = {
  id: '',
  type: '',
  name: '',
  pricing: [],
  partners: [],
  user: {},
  hasVideo: false,
};

export default Jumbotron;
