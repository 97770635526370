import i18n from '../languages';

const addLanguageToStaticUrl = (url = '/', selLang = undefined) => {
  try {
    const lang = selLang || i18n.culture || 'en';

    if (url === '/about') {
      return lang === 'es' ? '/es/acerca-de-nosotros' : '/about';
    }

    return `/${lang}${url}`
      .toString()
      .replaceAll('/en/en/', '/')
      .replaceAll('/es/es/', '/es/')
      .replaceAll('/en/es/', `/${lang}/`)
      .replaceAll('/es/en/', `/${lang}/`)
      .replaceAll('/en/', '/');
  } catch (e) {
    console.log('error on url', e);
    return '/';
  }
};

export default addLanguageToStaticUrl;
