/* eslint-disable no-undef */
import axios from 'axios';
import { config } from '../../config/config';

export const getSearch = async (value) =>
  axios.get(
    `${config.API_DOMAIN}/oxygen/educationalresource?search_term=${value}`,
    {
      withCredentials: true,
    },
  );

export default getSearch;
