import React from 'react';
import { Helmet } from 'react-helmet';

import i18n from '../../../languages';

import { courseType } from '../../../types/coursesType';

const defaultLangs = [{ en: '/' }, { es: '/es' }];

const MetaTags = ({
  title,
  description,
  media,
  name,
  other_langs,
  short_description,
  type,
  seo_description,
  seo_title,
}) => {
  const urlBase = `https://${process.env.REACT_APP_URL_SITE_DOMAIN}`;
  const defaultBrandName = i18n.t('BRAND_NAME');
  const defaultBrandTitle = i18n.t('PAGE_DEFAULT_TITLE');

  let url = '';

  let renderName = name || defaultBrandTitle;
  let renderDescription = short_description
    ? `${short_description}`
    : i18n.t('PAGE_DEFAULT_DESCRIPTION');

  if (seo_title || title) {
    renderName = seo_title || `${defaultBrandName} - ${title}`;
  }

  if (seo_description || description) {
    renderDescription = seo_description || description;
  }

  const renderOtherLangs = other_langs || defaultLangs;
  //const areDefaults = other_langs && other_langs.length > 0 ? false : true;

  const buildHreflangs = () => {
    let links = [];
    let url = '';
    type = type !== undefined ? type : 'other';

    if (type === 'course' || type === 'degree') {
      let defaultHref = '';
      if (renderOtherLangs && renderOtherLangs.length > 0) {
        renderOtherLangs.map((langObj, key) => {
          let l = Object.keys(renderOtherLangs[key])[0];
          let slug = Object.values(renderOtherLangs[key])[0];

          if (l === 'es') {
            url = urlBase + `/${l}/${type}/${slug}`;
            defaultHref = window.location.href;
          } else {
            url = urlBase + `/${type}/${slug}`;
            defaultHref = url;
          }

          links.push(<link rel="alternate" hrefLang={l} href={url} key={l} />);
        });
      }
      links.push(
        <link
          rel="alternate"
          hrefLang={i18n.culture}
          href={window.location.href}
          key={i18n.culture}
        />,
      );
      links.push(
        <link rel="alternate" hrefLang="x-default" href={defaultHref} />,
      );
    } else if (window.location.href.split(window.location.host)[1] === '/') {
      ['es', 'en'].map((lang) => {
        if (lang !== 'en') {
          url = `${urlBase}/es`;
        } else {
          url = urlBase;
        }
        links.push(
          <link rel="alternate" hrefLang={lang} href={url} key={lang} />,
        );
      });
      links.push(<link rel="alternate" hrefLang="x-default" href={urlBase} />);
    } else {
      renderOtherLangs.map((langObj, key) => {
        let l = Object.keys(renderOtherLangs[key])[0];
        let slug = Object.values(renderOtherLangs[key])[0];
        url = urlBase + slug;
        links.push(<link rel="alternate" hrefLang={l} href={url} key={l} />);
        if (l === 'en') {
          links.push(<link rel="alternate" hrefLang="x-default" href={url} />);
        }
      });
    }

    return links.map((link) => {
      return link;
    });
  };

  return (
    <Helmet>
      <html lang={i18n.culture} />
      <title>{renderName}</title>
      <meta name="description" content={renderDescription} />

      {buildHreflangs()}

      <meta property="og:title" content={renderName} />
      <meta property="og:description" content={renderDescription} />

      {media && <meta property="og:image" content={media.square} />}
      {media && <meta property="og:image:secure_url" content={media.square} />}
      {media && <meta property="og:image:type" content="image/jpeg" />}
      {media && <meta property="og:image:width" content="710" />}
      {media && <meta property="og:image:height" content="536" />}
      {media && <meta name="twitter:image" content={media.square} />}
    </Helmet>
  );
};

export default MetaTags;
