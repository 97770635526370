import I18n from '@s-ui/i18n';
import Polyglot from '@s-ui/i18n/lib/adapters/polyglot';

import english from './english';
import spanish from './spanish';

const i18n = new I18n({ adapter: new Polyglot() });

i18n.languages = {
  es: {
    ...spanish,
  },
  en: {
    ...english,
  },
};

export default i18n;
