import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import i18n from '../../../../languages';

import Header from '../../../../components/Common/Header';
import Footer from '../../../../components/Common/Footer';
import MetaTags from '../../../../components/Common/MetaTags';

import '../../index.scss';

const CookiesPolicyES = () => {
  const { pathname } = useLocation();

  const langs = [{ en: '/info/cookies' }, { es: '/es/info/cookies' }];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <MetaTags
        title={i18n.t('PAGE_PRIVA_TITLE')}
        description={i18n.t('PAGE_PRIVA_DESCRIPTION')}
        other_langs={langs}
      />
      <div className="page-wrap">
        <Header theme="black" />
        <div className="page privacy">
          <Container maxWidth="lg" className="container">
            <Grid item xs={12}>
              <h2>Politica de Cookies</h2>
              <h3>
                <strong> INTRODUCCION</strong>
              </h3>
              <p>
                La p&aacute;gina web podr&aacute; utilizar cookies propias y/o
                terceros para mejorar la experiencia del usuario y ofrecer
                contenidos orientadores a sus intereses. La utilizaci&oacute;n
                del procedimiento de registro en la p&aacute;gina web y/o
                navegaci&oacute;n, el usuario acepta la instalaci&oacute;n de
                cookies, salvo que se oponga, seg&uacute;n se detalla en esta
                Pol&iacute;tica de Cookies.
              </p>
              <p>
                La Pol&iacute;tica de Cookies est&aacute; sujeta a
                actualizaciones peri&oacute;dicas. El objeto, es ayudarle a
                comprender el uso que se realiza de las cookies, la finalidad,
                as&iacute; como las diferentes posibilidades que tiene el
                usuario para su gesti&oacute;n.
              </p>
              <p>
                Los usuarios pueden acceder a esta informaci&oacute;n en
                cualquier momento a trav&eacute;s del <em>link </em>habilitado
                en la p&aacute;gina web. De la misma manera, podr&aacute;n
                modificar sus preferencias sobre la aceptaci&oacute;n de cookies
                a trav&eacute;s de las opciones que tiene establecidas su
                navegador.
              </p>
              <p>
                La Pol&iacute;tica de Cookies de la p&aacute;gina web comprende
                el dominio de{' '}
                <a href="https://oxygen.education/">oxygen.education</a>.com
              </p>
              <h3>
                <strong> DEFINICI&Oacute;N DE UNA COOKIE</strong>
              </h3>
              <p>
                Las <em>cookies </em>y otras tecnolog&iacute;as similares tales
                como local shared objects, flash <em>cookies </em>o{' '}
                <em>p&iacute;xeles</em>, son herramientas empleadas por los
                servidores web para almacenar y recuperar informaci&oacute;n
                acerca de sus visitantes, as&iacute; como para ofrecer un
                correcto funcionamiento del sitio.
              </p>
              <p>
                Mediante el uso de estos dispositivos se permite al servidor web
                recordar algunos datos concernientes al usuario, como sus
                preferencias para la visualizaci&oacute;n de las p&aacute;ginas
                de ese servidor, nombre y contrase&ntilde;a, productos que
                m&aacute;s le interesan, etc.
              </p>
              <h3>
                <strong>
                  {' '}
                  CLASIFICACION DE LAS COOKIES QUE PUEDAN DARSE EN LA PAGINA WEB
                </strong>
              </h3>
              <ul>
                <li>
                  <strong>
                    <em>Cookies Anal&iacute;ticas: </em>
                  </strong>
                  recogen informaci&oacute;n del uso que se realiza de la
                  p&aacute;gina web.
                </li>
                <li>
                  <strong>
                    <em>Cookies sociales: </em>
                  </strong>
                  son aquellas necesarias para redes sociales externas.
                </li>
                <li>
                  <strong>
                    <em>Cookies de afiliados: </em>
                  </strong>
                  permiten hacer un seguimiento de las visitas procedentes de
                  otras webs, con las que la p&aacute;gina web establece un
                  contrato de afiliaci&oacute;n.
                </li>
                <li>
                  <strong>
                    <em>Cookies de publicidad y comportamentales: </em>
                  </strong>
                  recogen informaci&oacute;n sobre las preferencias y elecciones
                  personales del usuario.
                </li>
                <li>
                  <strong>
                    <em>Cookies t&eacute;cnicas y funcionales: </em>
                  </strong>
                  son las estrictamente necesarias para el uso de la
                  p&aacute;gina web y para la prestaci&oacute;n del servicio
                  contratado.
                </li>
              </ul>
              <p>
                A continuaci&oacute;n, detallamos las cookies utilizadas en la
                presente p&aacute;gina web y la finalidad de estas.
                Relaci&oacute;n de cookies utilizadas en el dominio de
                https://oxygen.education
              </p>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>
                        <strong>
                          <em>Cookie</em>
                        </strong>
                      </p>
                    </td>
                    <td>
                      <p>
                        <strong>
                          <em>Descripci&oacute;n</em>
                        </strong>
                      </p>
                    </td>
                    <td>
                      <p>
                        <strong>
                          <em>Duraci&oacute;n</em>
                        </strong>
                      </p>
                    </td>
                    <td>
                      <p>
                        <strong>
                          <em>Categor&iacute;a</em>
                        </strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>XDOMAIN-LOGGED-IN</strong>
                      </p>
                    </td>
                    <td>
                      <p>Esta cookie se</p>
                      <p>configura para el inicio de sesi&oacute;n</p>
                      <p>entre dominios.</p>
                    </td>
                    <td>
                      <p>Indefinida</p>
                    </td>
                    <td rowSpan="3">
                      <p>T&eacute;cnicas</p>
                      <p>y funcionales</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>sessionid</strong>
                      </p>
                    </td>
                    <td>
                      <p>Esta cookie se</p>
                      <p>configura para</p>
                      <p>guardar los datos de la sesi&oacute;n actual.</p>
                    </td>
                    <td>
                      <p>14 d&iacute;as</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>CloudFront-Signature</strong>
                      </p>
                      <p>
                        <strong>CloudFront-Policy</strong>
                      </p>
                      <p>
                        <strong>CloudFront-Key-Pair-Id</strong>
                      </p>
                    </td>
                    <td>
                      <p>Esta cookie esta configurada para agilizar la</p>
                      <p>distribuci&oacute;n de</p>
                      <p>contenido web</p>
                      <p>est&aacute;tico y din&aacute;mico como archivos</p>
                      <p>.html, .css, .js y</p>
                      <p>archivos de</p>
                      <p>im&aacute;genes a los</p>
                      <p>usuarios.</p>
                    </td>
                    <td>
                      <p>Durante</p>
                      <p>la sesi&oacute;n</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_ga_*</strong>
                      </p>
                    </td>
                    <td rowSpan="3">
                      <p>Estas cookies</p>
                      <p>
                        habilitan la funci&oacute;n de control de visitas y
                        sesiones de los usuarios en el Sitio Web.
                      </p>
                    </td>
                    <td>
                      <p>365 d&iacute;as</p>
                    </td>
                    <td rowSpan="3">
                      <p>Anal&iacute;ticas</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>__utmzzses</strong>
                      </p>
                    </td>
                    <td>
                      <p>Durante</p>
                      <p>la sesi&oacute;n</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                <br />
                <br />
              </p>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>
                        <strong>InitialTrafficSource</strong>
                      </p>
                    </td>
                    <td>
                      <p>2 a&ntilde;os</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_hjSessionUser_*</strong>
                      </p>
                    </td>
                    <td rowSpan="14">
                      <p>Estas cookies se configuran para</p>
                      <p>registrar las</p>
                      <p>actividades en el Sitio Web para</p>
                      <p>
                        comprender el uso de este y mejorar la experiencia del
                      </p>
                      <p>usuario.</p>
                    </td>
                    <td>
                      <p>1 a&ntilde;o</p>
                    </td>
                    <td rowSpan="14">
                      <p>Anal&iacute;ticas</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_hjSession_*</strong>
                      </p>
                    </td>
                    <td>
                      <p>30 min</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_hjAbsoluteSessionInProgress</strong>
                      </p>
                    </td>
                    <td>
                      <p>30 min</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_hjIncludedInPageviewSample</strong>
                      </p>
                    </td>
                    <td>
                      <p>30 min</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_hjIncludedInSessionSample</strong>
                      </p>
                    </td>
                    <td>
                      <p>30 min</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_hjid</strong>
                      </p>
                    </td>
                    <td>
                      <p>1 a&ntilde;o</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_hjptid</strong>
                      </p>
                    </td>
                    <td>
                      <p>1 a&ntilde;o</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_BEAMER_DATE_PRODUCT</strong>
                      </p>
                    </td>
                    <td>
                      <p>300 d&iacute;as</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_BEAMER_FILTER_BY_URL_PRODUCT</strong>
                      </p>
                    </td>
                    <td>
                      <p>20 min</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_BEAMER_LAST_UPDATE_PRODUCT</strong>
                      </p>
                    </td>
                    <td>
                      <p>300 d&iacute;as</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>ajs_user_id</strong>
                      </p>
                    </td>
                    <td>
                      <p>1 a&ntilde;o</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>ajs_anonymous_id</strong>
                      </p>
                    </td>
                    <td>
                      <p>1 a&ntilde;o</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_gcl_au</strong>
                      </p>
                    </td>
                    <td>
                      <p>3 meses</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_gid</strong>
                      </p>
                    </td>
                    <td>
                      <p>1 d&iacute;a</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_fbp</strong>
                      </p>
                    </td>
                    <td>
                      <p>Estas cookies y</p>
                      <p>pixeles de</p>
                      <p>seguimiento se</p>
                      <p>configuran para</p>
                      <p>medir y analizar el rendimiento de</p>
                      <p>nuestras campa&ntilde;as en Facebook</p>
                    </td>
                    <td>
                      <p>3 meses</p>
                    </td>
                    <td>
                      <p>Publicidad y</p>
                      <p>comportamentales</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                <br />
                <br />
              </p>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>
                        <strong>AnalyticsSyncHistory</strong>
                      </p>
                    </td>
                    <td rowSpan="9">
                      <p>Estas cookies se configuran para</p>
                      <p>analizar y medir el rendimiento de</p>
                      <p>nuestras</p>
                      <p>publicaciones en LinkedIn</p>
                    </td>
                    <td>
                      <p>30 horas</p>
                    </td>
                    <td rowSpan="9">
                      <p>Publicidad y</p>
                      <p>comportamentales</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>li_gc</strong>
                      </p>
                    </td>
                    <td>
                      <p>2 a&ntilde;os</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>Li_mc</strong>
                      </p>
                    </td>
                    <td>
                      <p>2 a&ntilde;os</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>lidc</strong>
                      </p>
                    </td>
                    <td>
                      <p>24 horas</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>UserMatchHistory</strong>
                      </p>
                    </td>
                    <td>
                      <p>30 d&iacute;as</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>lang</strong>
                      </p>
                    </td>
                    <td>
                      <p>Durante</p>
                      <p>la sesi&oacute;n</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>Lms_analytics</strong>
                      </p>
                    </td>
                    <td>
                      <p>30 d&iacute;as</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>bcookie</strong>
                      </p>
                    </td>
                    <td>
                      <p>2 d&iacute;as</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>Lms_ads</strong>
                      </p>
                    </td>
                    <td>
                      <p>30 d&iacute;as</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_uetsid</strong>
                      </p>
                    </td>
                    <td rowSpan="2">
                      <p>Estas cookies se configuran para</p>
                      <p>medir y analizar el rendimiento de</p>
                      <p>nuestras campa&ntilde;as en Bing Ads</p>
                    </td>
                    <td>
                      <p>1 d&iacute;a</p>
                    </td>
                    <td rowSpan="2">
                      <p>Publicidad y</p>
                      <p>comportamentales</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_uetvid</strong>
                      </p>
                    </td>
                    <td>
                      <p>16 d&iacute;as</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                <br />
                <br />
              </p>
              <ol start="4">
                <li>
                  <strong> ACEPTACION DE LAS COOKIES</strong>
                </li>
              </ol>
              <p>
                Al acceder a esta p&aacute;gina web, y de acuerdo con la
                normativa vigente en materia de protecci&oacute;n de datos, le
                informamos del uso de cookies, d&aacute;ndole la opci&oacute;n
                de aceptarlas expresamente y de acceder a m&aacute;s
                informaci&oacute;n a trav&eacute;s de esta Pol&iacute;tica de
                Cookies.
              </p>
              <p>
                Debe saber que, en el caso de continuar navegando, estar&aacute;
                prestando su consentimiento para el empleo de estas cookies. No
                obstante, en cualquier momento, podr&aacute; cambiar de
                opini&oacute;n y bloquear su utilizaci&oacute;n a trav&eacute;s
                de su navegador.
              </p>
              <p>
                Para su total tranquilidad, esta p&aacute;gina web cumple con lo
                estipulada en la normativa vigente en relaci&oacute;n con el uso
                de las cookies y sus datos personales.
              </p>
              <ol start="5">
                <li>
                  <strong>
                    {' '}
                    PROCEDIMIENTOS PARA DESHABILITAR, BLOQUEAR Y/O ELIMINAR
                    COOKIES
                  </strong>
                </li>
              </ol>
              <p>
                En cualquier momento, puede adaptar la configuraci&oacute;n del
                navegador para desestimar el uso de cookies.
              </p>
              <p>
                Tambi&eacute;n puede adaptar la configuraci&oacute;n de forma
                que el navegador rechace todas las cookies, o &uacute;nicamente
                las cookies de terceros. Y tambi&eacute;n puede eliminar
                cualquiera de las cookies que ya se encuentren en tu equipo.
              </p>
              <p>
                Para ello, debe tener en cuenta que tendr&aacute; que adaptar
                por separado la configuraci&oacute;n de cada navegador y equipo
                que utilice ya que, como le hemos comentario anteriormente las
                cookies se asocian al navegador, no a la persona.
              </p>
              <p>
                <strong>
                  GOOGLE CHROME{' '}
                  <a href="https://support.google.com/chrome/answer/95647?hl=es-419 ">
                    https://support.google.com/chrome/answer/95647?hl=es-419
                  </a>
                </strong>
              </p>
              <p>
                <strong>
                  INTERNET EXPLORER{' '}
                  <a href="https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-10">
                    https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-10
                  </a>
                </strong>
              </p>
              <p>
                <strong>
                  MOZILLA FIREFOX{' '}
                  <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&amp;redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we">
                    https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&amp;redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we
                  </a>
                </strong>
              </p>
              <p>
                <strong>
                  APPLE SAFARI{' '}
                  <a href="https://support.apple.com/es-es/HT201265 ">
                    https://support.apple.com/es-es/HT201265
                  </a>
                </strong>
              </p>
              <p>
                <strong>
                  EDGE{' '}
                  <a href="https://support.microsoft.com/es-es/windows/microsoft-edge-datos-de-exploraci%C3%B3n-y-privacidad-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd">
                    https://support.microsoft.com/es-es/windows/microsoft-edge-datos-de-exploraci%C3%B3n-y-privacidad-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd
                  </a>
                </strong>
              </p>
              <p>
                <strong>
                  OPERA{' '}
                  <a href="https://help.opera.com/en/latest/web-preferences/ ">
                    https://help.opera.com/en/latest/web-preferences/
                  </a>
                </strong>
              </p>
              <p>
                Si tiene cualquier duda sobre esta Pol&iacute;tica de Cookies,
                puede contactar con nosotros envi&aacute;ndonos un correo
                electr&oacute;nico a{' '}
                <a href="mailto:info@oxygen.education">info@oxygen.education</a>
              </p>
            </Grid>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CookiesPolicyES;
