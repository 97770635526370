import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import lodash from 'lodash';

import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';

import i18n from '../../../../languages';
import api from '../../../../api';

import './index.scss';

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '80%',
    maxWidth: 380,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
}));

const EnterEmailModal = ({ open, handleClose, idContent, contentType }) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const inputMarketingEmail = useRef(null);
  const [error, setError] = useState({});
  const [success, setSuccess] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [checkbox, setCheckbox] = useState(false);

  const [email, setEmail] = useState(
    window.localStorage.getItem('mail')
      ? window.localStorage.getItem('mail')
      : '',
  );
  const isEmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [loading, setLoading] = useState(false);

  const sendMarketingEmailFormSent = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = inputMarketingEmail.current;

    if (isEmailRegex.test(String(email).toLowerCase())) {
      setError({});
    } else {
      setError({
        msg: i18n.t('EMAIL_INVALID'),
      });
      return false;
    }

    const tag = contentType
      ? `reminder-${contentType}`
      : idContent === 'community'
      ? 'reminder-community-hub'
      : 'reminder-course';

    setLoading(true);
    return api.marketingEmail
      .sendMarketingEmail({
        email: form.marketingEmail.value,
        newsletter_subscription: false,
        id_content: idContent,
        tag,
      })
      .then(() => {
        window.localStorage.setItem('mail', form.marketingEmail.value);
        window.localStorage.setItem('mail_validated', 'false');
        setSuccess(true);
        setLoading(false);
        return false;
      })
      .catch((responseError) => {
        if (responseError.response) {
          setError(responseError.response.data);
        }
        setLoading(false);
        return false;
      });
  };

  const reSendMarketingEmailFormSent = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setLoading(true);
    return api.marketingEmail
      .reSendMarketingEmail({
        email:
          window.localStorage.getItem('mail') &&
          window.localStorage.getItem('mail').replace(/['"]+/g, ''),
        id_content: idContent,
        tag:
          idContent === 'community'
            ? 'reminder-community-hub'
            : 'reminder-course',
      })
      .then(() => {
        setSuccess(true);
        setLoading(false);
        handleClose(event);
        return false;
      })
      .catch((responseError) => {
        if (responseError.response) {
          setError(responseError.response.data);
        }
        setLoading(false);
        return false;
      });
  };

  const changeFormEmail = (event) => {
    event.preventDefault();
    setEmail(event.target.value);
    setError({});
  };

  const changeFormFirstName = (event) => {
    event.preventDefault();
    setFirstName(event.target.value);
    setError({});
  };

  const changeFormLastName = (event) => {
    event.preventDefault();
    setLastName(event.target.value);
    setError({});
  };

  const handleChangeCheckBox = (event) => {
    setCheckbox(event.target.checked);
  };

  const bodyFirstTime = (
    <div style={modalStyle} className={classes.paper}>
      {success ? (
        <>
          <h2>
            {i18n.t('MODAL_ENTER_EMAIL_REMINDME_TITLE')}
            <IconButton onClick={handleClose} className="close">
              <CloseIcon />
            </IconButton>
          </h2>
          <Grid container className="form-content">
            <Grid item xs={12}>
              <p>{i18n.t('MODAL_ENTER_EMAIL_REMINDME_THANKS')}</p>
            </Grid>
            <Grid item xs={12} align="right">
              <Button
                className="btn-primary arrow-right md"
                onClick={() => window.location.reload(false)}
              >
                {i18n.t('BUTTON_OK')}
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <h2>
            {i18n.t('MODAL_ENTER_EMAIL_TITLE')}
            <IconButton onClick={handleClose} className="close">
              <CloseIcon />
            </IconButton>
          </h2>
          <p>{i18n.t('MODAL_ENTER_EMAIL_DESCRIPTION')}</p>
          <form ref={inputMarketingEmail}>
            <Grid
              container
              justifyContent="center"
              className="form-content get-program-modal"
              spacing="1"
            >
              <Grid item xs={12} sm={6}>
                <TextField
                  error={!lodash.isEmpty(error)}
                  helperText={error && error.msg}
                  name="firstName"
                  label={`${i18n.t('NAME')} *`}
                  color="secondary"
                  type="text"
                  className="form-input"
                  onChange={changeFormFirstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={!lodash.isEmpty(error)}
                  helperText={error && error.msg}
                  name="lastName"
                  label={`${i18n.t('LAST_NAME')} *`}
                  color="secondary"
                  type="text"
                  className="form-input"
                  onChange={changeFormLastName}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  error={!lodash.isEmpty(error)}
                  helperText={error && error.msg}
                  name="marketingEmail"
                  label="Email *"
                  color="secondary"
                  type="email"
                  className="form-input"
                  defaultValue={
                    window.localStorage.getItem('mail') &&
                    window.localStorage.getItem('mail').replace(/['"]+/g, '')
                  }
                  onChange={changeFormEmail}
                />
                <small>* {i18n.t('MANDATORY_FIELD')}</small>
              </Grid>
              <Grid item xs={1} sm={1}>
                <Checkbox
                  color="primary"
                  onChange={handleChangeCheckBox}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </Grid>
              <Grid item xs={11} sm={11}>
                <small>
                  {i18n.t('MODAL_GET_PROGRAM_DISCLAIMER')}
                  <a href="/es/info/privacy">{i18n.t('PRIVACY_POLICY')}.</a>
                </small>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  id="get-course-program-send"
                  className="btn-primary arrow-right md"
                  onClick={sendMarketingEmailFormSent}
                  disabled={
                    loading ||
                    !checkbox ||
                    !firstName ||
                    !lastName ||
                    !isEmailRegex.test(String(email).toLowerCase())
                  }
                >
                  {i18n.t('MODAL_ENTER_EMAIL_SEND_OK')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </div>
  );

  // Already sent his email. Not Validated.
  const alreadySendNotConfirmedBody = (
    <div style={modalStyle} className={classes.paper}>
      {success ? (
        <>
          <h2>
            {i18n.t('MODAL_ENTER_EMAIL_REMINDME_TITLE')}
            <IconButton onClick={handleClose} className="close">
              <CloseIcon />
            </IconButton>
          </h2>
          <Grid container className="form-content">
            <Grid item xs={12}>
              <p>{i18n.t('MODAL_ENTER_EMAIL_REMINDME_THANKS')}</p>
            </Grid>
            <Grid container className="form-content send-again-box">
              <Grid item sm={8} xs={12}>
                <p className="bottom">
                  {i18n.t('MODAL_COMPLETE_DIDNT_RECEIVE')}{' '}
                  <a
                    href="/"
                    disabled={loading}
                    className="send-again"
                    onClick={reSendMarketingEmailFormSent}
                  >
                    {i18n.t('SEND_AGAIN')}
                  </a>
                </p>
              </Grid>
              <Grid item sm={4} xs={12} align="right">
                <Button
                  className="btn-primary arrow-right md"
                  onClick={handleClose}
                >
                  {i18n.t('BUTTON_OK')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <h2>
            {i18n.t('MODAL_ENTER_EMAIL_AGAIN_NOT_VERIFIED_REMINDME_TITLE')}
            <IconButton onClick={handleClose} className="close">
              <CloseIcon />
            </IconButton>
          </h2>
          <Grid container className="form-content">
            <Grid item xs={12}>
              <p>
                {i18n
                  .t(
                    'MODAL_ENTER_EMAIL_AGAIN_NOT_VERIFIED_REMINDME_DESCRIPTION',
                  )
                  .replace('{email}', window.localStorage.getItem('mail'))}
              </p>
            </Grid>
            <Grid container className="form-content send-again-box">
              <Grid item sm={8} xs={12}>
                <p className="bottom">
                  {i18n.t('MODAL_COMPLETE_DIDNT_RECEIVE')}{' '}
                  <a
                    href="/"
                    disabled={loading}
                    className="send-again"
                    onClick={reSendMarketingEmailFormSent}
                  >
                    {i18n.t('SEND_AGAIN')}
                  </a>
                </p>
              </Grid>
              <Grid item sm={4} xs={12} align="right">
                <Button
                  className="btn-primary arrow-right md"
                  disabled={loading}
                  onClick={handleClose}
                >
                  {i18n.t('BUTTON_OK')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );

  // Already sent his email and Validated it.
  const alreadySendAndConfirmedBody = (
    <div style={modalStyle} className={classes.paper}>
      <>
        <h2>
          {i18n.t('MODAL_ENTER_EMAIL_AND_VERIFIED_REMINDME_TITLE')}
          <IconButton onClick={handleClose} className="close">
            <CloseIcon />
          </IconButton>
        </h2>
        <Grid container className="form-content">
          <Grid item xs={12}>
            <p>
              {i18n.t('MODAL_ENTER_EMAIL_AND_VERIFIED_REMINDME_DESCRIPTION')}
            </p>
          </Grid>
          <Grid item xs={12} align="right">
            <Button
              className="btn-primary arrow-right md"
              onClick={handleClose}
            >
              {i18n.t('CONTINUE')}
            </Button>
          </Grid>
        </Grid>
      </>
    </div>
  );

  // First time. nothing sent
  let body = bodyFirstTime;

  // Already sent his email. Not Validated.
  if (
    window.localStorage.getItem('mail') &&
    window.localStorage.getItem('mail_validated') === 'false'
  ) {
    body = alreadySendNotConfirmedBody;
  }

  // Already sent his email. User validated.
  if (
    window.localStorage.getItem('mail') &&
    window.localStorage.getItem('mail_validated') === 'true'
  ) {
    body = alreadySendAndConfirmedBody;
  }

  // bodyFirstTime
  return (
    <Modal className="enter-email-modal" open={open} onClose={handleClose}>
      {body}
    </Modal>
  );
};

EnterEmailModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  idContent: PropTypes.string,
  contentType: PropTypes.string,
};

EnterEmailModal.defaultProps = {
  open: false,
  handleClose: () => {},
  idContent: '',
  contentType: '',
};

export default EnterEmailModal;
