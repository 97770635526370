/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
import React, { useEffect, useState, useRef } from 'react';
import Cookies from 'js-cookie';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isMobileOnly } from 'react-device-detect';
import queryString from 'query-string';
import lodash from 'lodash';
import { Buffer } from 'buffer';
import { isLocalhost } from '../../serviceWorker';
import psl from 'psl';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { getUser } from '../../redux/actions/user';
import { getEducationalResources } from '../../redux/actions/educationalResources';
import { getEnrollments } from '../../redux/actions/enrollments';
import api from '../../api';

import i18n from '../../languages';
import HomeMockSP from '../../constants/HomeSP';
import HomeMockEN from '../../constants/HomeEN';
import selectLanguage from '../../utils/selectLanguage';
import addLanguageToStaticUrl from '../../utils/addLanguageToStaticUrl';
import { getReferrer, getUtmParams } from '../../utils/getUtmParams';
import { trackEvent } from '../../utils/tracking';

import Header from '../../components/Common/Header';
import Footer from '../../components/Common/Footer';
import Loader from '../../components/Common/Loader';
import AboutBox from '../../components/Home/AboutBox';
import Jumbotron from '../../components/Home/Jumbotron';
import CoursesGallery from '../../components/Home/CoursesGallery';
import ImageSlider from '../../components/Home/ImageSlider';
import ContentSlider from '../../components/Home/ContentSlider';
import SliderCourses from '../../components/Home/SliderCourses';
import ContentInfo from '../../components/Home/ContentInfo';
import VerifySiteWide from '../../components/Common/VerifySiteWide';
import MetaTags from '../../components/Common/MetaTags';
import VideoPlayer from '../../components/Common/VideoPlayer';
import WelcomeModal from '../../components/Common/Modals/Welcome';

import { educationalResourcesType } from '../../types/educationalResourceType';
import { userType } from '../../types/userType';

import './index.scss';
import PartnerBar from '../../components/Home/PartnerBar';

const VIDEO_DESKTOP = {
  en:
    'https://oxygen.education/Video-Landing/Video-Landing-210326/ENG/Landing_16x9-ENG-H264-subt.m3u8',
  es:
    'https://oxygen.education/Video-Landing/Video-Landing-210326/SPA/Landing_16x9-SPA-H264-subt.m3u8',
};

const VIDEO_MOBILE = {
  en:
    'https://oxygen.education/Video-Landing/Video-Landing-Mobile-210326/ENG/Landing_9x14-EN-Subt-ingles-ALTA.m3u8',
  es:
    'https://oxygen.education/Video-Landing/Video-Landing-Mobile-210326/SPA/Landing_9x14-ES-Subt-espaniol-ALTA.m3u8',
};

const Home = (props) => {
  const { pathname } = useLocation();
  const { token, lang } = props.match.params;
  const [loading, setLoading] = useState(true);
  const [muteVideo, setMuteVideo] = useState(true);
  const [replayVideo, setReplayVideo] = useState(false);
  const [stopVideoScroll, setStopVideoScroll] = useState(false);
  const [stopVideoMenuOpen, setStopVideoMenuOpen] = useState(false);
  const [stopVideo, setStopVideo] = useState(false);

  const [openWelcomeUserModal, setOpenWelcomeUserModal] = useState(false);

  const videoRef = useRef(null);

  const domain = isLocalhost
    ? 'localhost'
    : `.${psl.parse(process.env.REACT_APP_URL_SITE_DOMAIN).domain}`;

  const params = queryString.parse(props.location.search);

  const { actions, educationalResources, enrollments, user } = props;

  useEffect(() => {
    const currentUtm =
      Cookies.get('utm_params') && JSON.parse(Cookies.get('utm_params'));
    const currentReferrer =
      Cookies.get('referrer') && JSON.parse(Cookies.get('referrer'));

    const newUtm = getUtmParams();
    const newReferrer = getReferrer() && { referrer: getReferrer() };

    if (!lodash.isEmpty(newUtm) && currentUtm !== newUtm) {
      Cookies.set('utm_params', JSON.stringify(newUtm), {
        domain,
        expires: 7,
      });

      Cookies.set('referrer', JSON.stringify(newReferrer), {
        domain,
        expires: 7,
      });
    }

    if (!lodash.isEmpty(newReferrer)) {
      Cookies.set('referrer', JSON.stringify(newReferrer), {
        domain,
        expires: 7,
      });
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (params.welcome) {
      let email = window.localStorage.getItem('mail');
      if (email) {
        let email_obfuscated = Buffer.from(email).toString('base64');

        trackEvent({
          event: 'account_activation',
          social_auth_provider: params.social_auth_provider || 'oxygen',
          email: email,
          userId: email_obfuscated,
        });
      }

      setOpenWelcomeUserModal(true);
      window.localStorage.setItem('mail_validated', 'true');
    }
  }, []);

  useEffect(() => {
    if (params.login && params.social_auth_provider) {
      let email = window.localStorage.getItem('mail');
      if (email) {
        let email_obfuscated = Buffer.from(email).toString('base64');

        trackEvent({
          event: 'login',
          social_auth_provider: params.social_auth_provider,
          email: email,
          userId: email_obfuscated,
        });
      }
    }
  }, []);

  useEffect(() => {
    (async () => {
      user.logged && (await actions.getEnrollments());
    })();
  }, [user.logged]);

  useEffect(() => {
    selectLanguage(lang);
    (async () => {
      await actions.getEducationalResources();
      await actions.getUser();
    })();

    setLoading(false);
  }, [actions, lang]);

  useEffect(() => {
    setStopVideo(stopVideoScroll || stopVideoMenuOpen);
  }, [stopVideoScroll, stopVideoMenuOpen]);

  if (token) {
    window.localStorage.setItem('mail_validated', 'true');
    api.marketingEmail
      .confirmMarketingEmail({
        email:
          window.localStorage.getItem('mail') &&
          window.localStorage.getItem('mail').replace(/['"]+/g, ''),
        token,
      })
      .catch((responseError) => {
        // eslint-disable-next-line no-console
        console.log(responseError, 'Error');
        return false;
      });
  }

  const HomeMockData = i18n._culture === 'en' ? HomeMockEN : HomeMockSP;
  const { about, content_info, content_slider, slider } = HomeMockData;
  const hasEnrollment = enrollments && enrollments.length > 0;

  const videoOpacity = stopVideo ? '0.7' : '1';
  let videoToPlay = isMobileOnly ? VIDEO_MOBILE.en : VIDEO_DESKTOP.en;
  if (i18n._culture) {
    videoToPlay = isMobileOnly
      ? VIDEO_MOBILE[i18n._culture]
      : VIDEO_DESKTOP[i18n._culture];
  }

  return !loading ? (
    <div style={{ backgroundColor: '#000' }}>
      <MetaTags />
      {openWelcomeUserModal && (
        <WelcomeModal
          open={openWelcomeUserModal}
          handleClose={() => setOpenWelcomeUserModal(false)}
        />
      )}
      {!hasEnrollment && <VerifySiteWide user={user} />}
      <div className="page-wrap">
        <Header
          theme={hasEnrollment ? 'black' : ''}
          page="home"
          scrollCallback={setStopVideoScroll}
          menuOpenCallback={setStopVideoMenuOpen}
          hasEnrollment={hasEnrollment}
        />

        <div
          className="home-background-back"
          style={hasEnrollment ? { top: 300 } : {}}
        >
          <div className="home-background">
            <div className="_background-dark" />
            <div
              className={classnames('_pattern-overlay', {
                mobile: isMobileOnly,
              })}
            />
            <VideoPlayer
              ref={videoRef}
              muted={muteVideo}
              url={videoToPlay}
              playing={!stopVideo}
              onEnded={() => setStopVideo(true)}
              onPlay={() => setReplayVideo(false)}
              loop={replayVideo}
              style={{ opacity: videoOpacity }}
            />
          </div>
        </div>

        <div className="page home">
          {hasEnrollment && (
            <div className="enrollment">
              <Container maxWidth="lg">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} className="title">
                    <h2>{i18n.t('CONTINUE_COURSES')}</h2>
                  </Grid>
                  <Grid item xs={12} sm={12} className="content">
                    <SliderCourses slider={enrollments} />
                  </Grid>
                </Grid>
              </Container>
            </div>
          )}

          <Container
            maxWidth="lg"
            className="jumbotron-container"
            style={{ position: 'relative' }}
          >
            <Jumbotron
              logged={user.logged}
              setMuteCallback={setMuteVideo}
              setReplayCallback={() => {
                videoRef.current.seekTo(0);
                setReplayVideo(true);
                setStopVideo(false);
              }}
              stop={stopVideo}
            />
            <PartnerBar />
          </Container>

          {educationalResources.loading ? (
            <Loader />
          ) : (
            <Container
              maxWidth="xl"
              style={hasEnrollment ? { marginTop: 310 } : { marginTop: 50 }}
            >
              <CoursesGallery
                educationalResources={educationalResources.results}
              />
            </Container>
          )}

          <Container maxWidth="xl">
            <AboutBox
              {...about}
              linkCta={about.linkCta}
              link={addLanguageToStaticUrl('/about')}
            />
          </Container>

          <Container maxWidth="xl">
            <ContentSlider content_slider={content_slider} />
            <Container maxWidth="xl">
              <a id="mentors" style={{ paddingTop: -60, display: 'block' }}></a>
              <ImageSlider slider={slider} />
            </Container>
            <ContentInfo content_info={content_info} />
          </Container>
        </div>
      </div>
      <Footer className="home" user={user} />
    </div>
  ) : (
    <Loader />
  );
};

Home.propTypes = {
  actions: PropTypes.shape({
    getEducationalResources: PropTypes.func,
    getEnrollments: PropTypes.func,
    getUser: PropTypes.func,
  }),
  educationalResources: PropTypes.shape({
    error: PropTypes.bool,
    loading: PropTypes.bool,
    results: educationalResourcesType,
  }),
  enrollments: educationalResourcesType,
  user: userType,
};

Home.defaultProps = {
  actions: {
    getEducationalResources: () => {},
    getEnrollments: () => {},
    getUser: () => {},
  },
  educationalResources: {},
  enrollments: [],
  user: {},
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getEducationalResources: bindActionCreators(
      getEducationalResources,
      dispatch,
    ),
    getEnrollments: bindActionCreators(getEnrollments, dispatch),
    getUser: bindActionCreators(getUser, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
