import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import i18n from '../../../../languages';

import Header from '../../../../components/Common/Header';
import Footer from '../../../../components/Common/Footer';
import MetaTags from '../../../../components/Common/MetaTags';

import '../../index.scss';

const TermsES = () => {
  const { pathname } = useLocation();

  const langs = [{ en: '/info/terms' }, { es: '/es/info/terms' }];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <MetaTags
        title={i18n.t('PAGE_TERMS_TITLE')}
        description={i18n.t('PAGE_TERMS_DESCRIPTION')}
        other_langs={langs}
      />
      <div className="page-wrap">
        <Header theme="black" />
        <div className="page terms">
          <Container maxWidth="lg" className="container">
            <Grid item xs={12}>
              <h2>Terms &amp; Conditions</h2>
              <p>
                <h3>
                  GENERAL TERMS AND CONDITIONS TO BE ACCEPTED BY THE STUDENTS
                </h3>
                <p>
                  <strong>1. SUBJECT MATTER</strong>
                </p>
                <p>
                  The terms and conditions described below (hereinafter the "
                  <em>General</em>
                  <em>Terms</em>
                  <em>and</em>
                  <em>Conditions</em>"), together with the training contract and
                  its annexes (hereinafter the "<em>Training</em>
                  <em>Contract</em>") constitute, collectively, a contract
                  (hereinafter the "<em>Contract</em>") between Platform Media
                  Lab SA, a company incorporated as a Swiss limited liability
                  company (soci&eacute;t&eacute; anonyme), registered in the
                  commercial register of the Canton of Vaud (CHE- 426. 769.305)
                  whose registered office is currently located at Route de
                  Crassier 7, 1262 Eysins, Switzerland (hereinafter referred to
                  as "<em>Platform</em>
                  <em>Media</em>") and the student (hereinafter referred to as
                  the "<em>Student</em>"). The term "Parties" shall refer
                  collectively to Platform Media and the Student and a "Party"
                  shall refer to Platform Media or the Student, as applicable.
                </p>
                <p>
                  In the event of any inconsistency between the Terms and
                  Conditions and the Training Agreement, the terms of the
                  Training Agreement shall prevail.
                </p>
                <p>
                  These Terms and Conditions shall apply to the following
                  services provided by Platform Media (the "<em>Services</em>")
                  on the online training platform (the "<em>Platform</em>"),
                  unless otherwise expressly stated:
                </p>
                <ul>
                  <li>
                    access to the Platform (the "<em>Platform</em>
                    <em>Access</em>"), including (i) participation in forums,
                    discussions or blogs; (ii) access to the Platform's course
                    catalogue; (iii) receipt of communications regarding
                    Platform products and services; and (iv) exchange of
                    material with Platform Media and other Learners, as well as
                  </li>
                  <li>
                    booking and purchase of various programmes through the
                    Platform (hereinafter referred to as the "<em>Training</em>
                    <em>Courses</em>").
                  </li>
                </ul>
                <p>
                  In order to access, book and purchase the Services on the
                  Platform, the Student will have to accept the full text of
                  these Terms and Conditions. The Student confirms to have read,
                  understood and accepted these Terms and Conditions available
                  on Platform Media's website{' '}
                  <a href="https://oxygen.education/">oxygen.education</a>{' '}
                  (hereinafter the "<em>Website</em>") or on Platform Media's
                  mobile application "OXYGEN" (hereinafter the "
                  <em>Application</em>").
                </p>
                <p>
                  In addition, prior to booking any Training Course, the Student
                  must read and agree to the relevant Training Agreement with
                  the relevant teacher. Platform Media shall make the Training
                  Contracts available to the Students in the "<em>Student</em>
                  <em>Area</em>".
                </p>
                <p>
                  <strong>2. AMENDMENT OF GENERAL TERMS AND CONDITIONS</strong>
                </p>
                <p>
                  Platform Media reserves the right at any time to make such
                  amendments, updates or changes to these Terms and Conditions
                  as it may deem appropriate and will notify Learners of such
                  amendments by posting a new version of these Terms and
                  Conditions on the Website.
                </p>
                <p>
                  In the event that Platform Media makes a modification, update
                  or change to these General Terms and Conditions, Platform
                  Media shall send each registered Student the new version of
                  the General Terms and Conditions to his or her email address
                  or Student Area. The Student must expressly accept the new
                  version of the Terms and Conditions in order to continue using
                  the Services provided by the Platform. If the Student does not
                  accept the amended version of the General Terms and
                  Conditions, the Student will no longer have access to the
                  Platform and Platform Media will suspend or cancel the
                  Contract concluded with the Student. The Student shall be
                  responsible for checking the current Terms and Conditions at
                  the time of contracting the relevant Services.
                </p>
                <p>
                  <strong>3. REGISTERED STUDENTS</strong>
                </p>
                <p>
                  The contracting and purchase of the Online Courses may be
                  reserved for registered Students. In order to register as a
                  registered Student, the Student must agree and warrant the
                  following:
                </p>
                <ul>
                  <li>
                    That the Student is eighteen (18) years of age or older, or
                    is over the minimum legal age in his/her country of
                    residence where this is over eighteen (18) years of age.
                  </li>
                  <li>
                    That the information provided by the Student at the time of
                    enrolment is accurate, current and complete, and that the
                    Student will keep such information up to date.
                  </li>
                  <li>
                    That the Student has the right to reserve and purchase the
                    Training courses from his/her country of residence and in
                    accordance with the laws applicable to the Student.
                  </li>
                  <li>
                    That the Student may not transfer his/her account, username
                    or password to any third party.
                  </li>
                </ul>
                <p>
                  <strong>4. PRICE AND PAYMENT CONDITIONS</strong>
                </p>
                <p>
                  The price of the Training Courses booked by the Trainee shall
                  be indicated in the provisions of the fees in force applicable
                  to the Training Course specifically booked (hereinafter
                  referred to as the "<em>Fees in</em>
                  <em>Force</em>"). The Parties may amend the provisions
                  relating to the Ongoing Fees to the extent expressly provided
                  for in the Training Contract.
                </p>
                <p>The mere Access to the Platform shall be free of charge.</p>
                <p>
                  Payment of the price of the contracted Training Course shall
                  be made, at the Student's choice, through the Platform's
                  payment gateway by any means: credit or debit card, bank
                  transfer or PayPal.
                </p>
                <p>
                  The Student will not have access to the Training Courses until
                  the payment has actually been received by Platform Media. The
                  Student must make the payment at least 2 working days prior to
                  the delivery of the Training Course, otherwise the Student
                  will not be able to attend the Training Course.
                </p>
                <p>
                  If the Student chooses to pay by bank transfer, the Student
                  will receive, within 4 hours of booking the Training Course,
                  an email indicating the bank account number to which the
                  transfer should be made. The Student will make the bank
                  transfer and send the corresponding receipt within 4 hours of
                  receipt of the e-mail.
                </p>
                <p>
                  Any charges arising from payments made shall in all cases be
                  paid by the Student.
                </p>
                <p>
                  After the signature of the Contract, an acknowledgement of
                  receipt of the booked Training Course will be sent via e- mail
                  to the Student within 24 hours with the details of the course
                  fee. Once payment has been made by the Student, Platform Media
                  will issue an invoice to the Student.
                </p>
                <p>
                  No discount or compensation is allowed. Platform Media will
                  not issue a refund in the event that the Training Course
                  booked and purchased is not used. In the event of a
                  cancellation, all Fees already paid will remain with Platform
                  Media.
                </p>
                <p>
                  <strong>5. TERM</strong>
                </p>
                <p>
                  The Agreement shall take effect from the date of acceptance by
                  the Student and
                </p>
                <p>
                  shall cease to be in force upon termination of the
                  relationship between the Student and Platform Media in
                  accordance with the terms of the Training Agreement.
                </p>
                <p>
                  <strong>6. TRAINING COURSES</strong>
                </p>
                <p>
                  The terms and conditions applicable to each Training Course
                  shall be those set out in the Training Agreement.
                </p>
                <p>
                  In particular, the Training Agreement shall include the
                  following information: (i) description of the Training Course;
                  (ii) price; (iii) timeframe; as well as (iv) official diplomas
                  and email addresses of the responsible person.
                </p>
                <p>
                  <strong>
                    7. OBLIGATIONS, REPRESENTATIONS AND WARRANTIES OF THE
                    TRAINEE
                  </strong>
                </p>
                <p>The Trainee hereby represents and warrants that:</p>
                <ol>
                  <li>
                    that all information provided to Platform Media is true,
                    accurate and up to date;
                  </li>
                  <li>
                    that he/she will pay the price of the Training Courses to be
                    contracted;
                  </li>
                  <li>
                    that you have elected to purchase the Training Courses at
                    your own risk subject to the Training Agreement to be signed
                    and that you shall have no claim against Platform Media with
                    respect to the adequacy and accuracy of the Training
                    Agreement;
                  </li>
                  <li>
                    the information or content that the Student may enter or
                    share on the Platform and its use will not infringe any
                    copyright, industrial property right or any other third
                    party rights, nor violate any applicable law or regulation,
                    and that the Student has obtained all rights, authorisations
                    and permissions required for such purposes.
                  </li>
                </ol>
                <p>
                  The Student shall not circumvent, disable or tamper in any way
                  with the security features of the Services offered through the
                  Platform or any other features that may alter the conditions
                  of access and use of the Training Courses or the Platform.
                </p>
                <p>
                  The Student shall indemnify and hold Platform Media and its
                  respective employees, sub-licensees and or assigns harmless
                  against all claims, costs, damages and expenses (including
                  legal fees and court costs) arising out of (i) the use of the
                  Services; (ii) failure to comply with or breach of any
                  provision of the Agreement (in particular, breach of any
                  representation or warranty contained herein); (iii)
                  unauthorised use of the Student's account or the Student Area;
                  (iv) any other cause which may be conducive to damage to
                  Platform Media.
                </p>
                <p>
                  In using the Platform, the Student shall be bound by the
                  following restrictions: (i) may not enter, retain or disclose
                  any information that could infringe morality, public order,
                  fundamental rights and, in general, any applicable
                  regulations; and
                  <br />
                  (ii) may not enter, retain or disclose through the Platform
                  any content that could damage the Platform or any Platform
                  equipment, other Learners or any third party, that could
                  impede its normal operation.
                </p>
                <p>
                  The content of the Training Course shall be received and used
                  solely by the Student for the Student's own use and not for
                  the Student's benefit, and the Student may not transmit or
                  transfer such content to any third party.
                </p>
                <p>
                  <strong>8. LIMITATION OF LIABILITY</strong>
                </p>
                <p>
                  Platform Media does not warrant that access to and use of the
                  Platform will be error free. The use of the Platform
                </p>
                <p>
                  includes the use of software, hardware and an Internet
                  connection, for which reason the Student recognises and
                  accepts that the functioning of the Platform could be affected
                  by any defect or anomaly in the use of these elements.
                </p>
                <p>
                  In particular, Platform Media Lab does not guarantee or assume
                  any responsibility for damages of any nature that may derive,
                  by way of example and without limitation, from: (i)
                  inferences, omissions, interruptions, computer viruses, loss
                  of data or own or third party content, caused by any cause,
                  which prevent or delay the provision of the Services or the
                  use of the content; (ii) delays or blockages in Access to the
                  Platform or the Training Course caused by deficiencies in the
                  Internet or electronic systems; (iii) damage that may be
                  caused by third parties through unlawful interference beyond
                  Platform Media's control; (iv) impossibility of providing
                  access caused by the Student, by third parties, or by force
                  majeure.
                </p>
                <p>
                  Platform Media does not guarantee or assume any liability for
                  damages of any kind, in particular (but not limited to)
                  indirect damages and loss of profits, which may be due to: (i)
                  the installation or use of the Platform; (ii) access to or use
                  of the linked sites; (iii) the lack of quality, lawfulness and
                  usefulness of the information and content and services
                  existing on the Platform and/or on the linked sites; and/or
                  (iv) for the total or partial withdrawal of a content or
                  course from the Platform for reasons deemed appropriate by
                  Platform Media.
                </p>
                <p>
                  Platform Media expressly excludes, to the extent permitted by
                  law, any and all liability for damages, in particular (without
                  limitation), consequential damages and lost profits arising
                  from: (i) accidents; (ii) injuries; and/or (iii) illness of
                  any nature related to the use of the Platform and/or linked
                  sites.
                </p>
                <p>
                  <strong>9. ACCOUNT, PASSWORD AND SECURITY</strong>
                </p>
                <p>
                  The Student is responsible for the quality and confidentiality
                  of the password chosen at the time of registration, as well as
                  for all activity generated through his/her password and
                  account. The Student shall maintain the confidentiality of
                  his/her password and shall ensure that he/she does not share
                  it under any circumstances with a third party.
                </p>
                <p>
                  The Student shall immediately inform Platform Media of any
                  fraudulent use of his/her password or account and any other
                  breach of security. The Student agrees to close the account at
                  the end of each session (logout).
                </p>
                <p>
                  The Student shall be solely responsible for maintaining and
                  safeguarding his/her information.
                </p>
                <p>
                  <strong>10. MODIFICATION AND TERMINATION</strong>
                </p>
                <p>
                  Platform Media may, at any time, in its sole discretion and
                  without notice, (i) modify, suspend or terminate the Platform;
                  (ii) exclude the Student, block, cancel or suspend the
                  Student's registration; and (iii) terminate the Training
                  Agreement with the Student. Platform Media shall not incur any
                  liability to the Student in such event, including any damage
                  or loss of information that may result therefrom.
                </p>
                <p>
                  In the event of termination of the Training Agreement,
                  Platform Media reserves the right to delete the Student's
                  account at any time without notice. Platform Media shall not
                  be required to justify such a decision and shall not be
                  entitled to any claim for compensation for such a reason.
                </p>
                <p>
                  In particular, the Training Contract shall be terminated
                  immediately and access to the Platform Services shall be
                  definitively refused to any Student who has violated these
                  Terms and Conditions.
                  <br />
                  The Student may not have several accounts under the same
                  identity. Platform Media reserves the right to immediately
                  terminate the Training Contract and to suspend or delete the
                  account of the Student who violates this rule and to refuse,
                  without delay and for the future, Access to the Platform and
                  related networks.
                </p>
                <p>
                  The Student may cancel his/her account at any time via the
                  Student Area.
                </p>
                <p>
                  <strong>11. INTELLECTUAL PROPERTY RIGHTS</strong>
                </p>
                <p>
                  All Intellectual Property Rights in the Platform, the Website
                  and the App, as well as the content of the Services available
                  on the Platform, are owned by Platform Media or, as the case
                  may be, by third parties who may have entered into relevant
                  usage agreements with Platform Media.
                </p>
                <p>
                  Intellectual Property Rights shall include all intellectual
                  property rights, including know-how, in its various forms such
                  as drawings, designs, documents, inventions, software
                  programs, reports, processes and protocols, which are
                  protected by secrets, patents, copyrights and trademarks
                  (hereinafter the "Intellectual Property Rights").
                </p>
                <p>
                  The Student shall not enjoy in any manner whatsoever under any
                  provision herein any Intellectual Property Rights other than
                  those specifically set out in the Training Contract.
                </p>
                <p>
                  <strong>12. DATA PROTECTION</strong>
                </p>
                <p>
                  The provisions relating to the collection and processing of
                  the Student's personal data are contained in the Data Privacy
                </p>
                <p>
                  Policy available at{' '}
                  <a href="https://oxygen.education/info/privacy">
                    https://oxygen.education/info/privacy
                  </a>
                  , such Data Privacy Policy constituting an annex to the
                  Training Contract.
                </p>
                <p>
                  The Parties acknowledge the confidential nature of the terms
                  of this Training Agreement and of the documents and
                  information relating to or arising from this Training
                  Agreement. Such information, including the terms of the
                  Training Agreement, may not be disclosed by the Parties,
                  including their employees, agents and dependants, without the
                  prior written consent of the other Party, except where such
                  disclosure is required by law or court order.
                </p>
                <p>
                  The obligation of confidentiality contained in the preceding
                  paragraph shall continue for the entire duration of the
                  Training Agreement and also after termination of the Training
                  Agreement for a period of five years.
                </p>
                <p>
                  <strong>13. ASSIGNMENT OF THE CONTRACT</strong>
                </p>
                <p>
                  The Trainee may not assign its rights or obligations under
                  this Agreement without the prior written consent of Platform
                  Media.
                </p>
                <p>
                  Platform Media may assign, in whole or in part, its rights and
                  obligations under this Agreement to any third party without
                  the prior consent of the Student.
                </p>
                <p>
                  <strong>
                    14. INDEPENDENCE AND INTEGRATION OF THE CLAUSES
                  </strong>
                </p>
                <p>
                  If any provision contained herein is declared invalid, void or
                  ineffective for any reason, such declaration shall not affect
                  the remaining provisions of these General Conditions, which
                  shall be considered as valid and effective. Notwithstanding
                  the foregoing, if a provision which is declared invalid, void
                  or ineffective renders other provisions invalid, void or
                  ineffective, such other provisions shall also be considered
                  invalid, void and ineffective.
                </p>
                <p>
                  The Parties shall be released from all rights and obligations
                  arising from the provision declared invalid or ineffective, to
                  the extent that these rights and obligations are directly
                  affected by the invalidity or ineffectiveness. In this case,
                  the Parties shall negotiate in good faith the replacement of
                  the provision declared invalid or ineffective by another
                  provision that preserves the original intention of the Parties
                  and the balance of the Parties' consideration.
                </p>
                <p>
                  <strong>15. APPLICABLE LAW AND JURISDICTION</strong>
                </p>
                <p>
                  The Training Contract and its annexes, including these General
                  Conditions, are governed by the laws of the State of
                  Switzerland.
                </p>
                <p>
                  Subject to statutory rules regarding jurisdiction which cannot
                  be derogated from, all disputes arising out of or in
                  connection with the Training Contract and its annexes,
                  including these General Conditions, shall be settled by the
                  courts having jurisdiction in Eysins, Switzerland.
                </p>
              </p>
            </Grid>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsES;
