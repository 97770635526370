import { arrayOf, shape, string, number } from 'prop-types';

import { subjectsType } from './subjectType';

export const periodType = shape({
  description: string,
  end: string,
  id: number,
  name: string,
  start: string,
  subjects: subjectsType,
});

export const periodsType = arrayOf(periodType);
