/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import lodash from 'lodash';

import CardMedia from '@material-ui/core/CardMedia';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import Header from '../../../components/Common/Header';
import Footer from '../../../components/Common/Footer';
import MetaTags from '../../../components/Common/MetaTags';

import i18n from '../../../languages';
import HomeMockSP from '../../../constants/HomeSP';
import HomeMockEN from '../../../constants/HomeEN';
import selectLanguage from '../../../utils/selectLanguage';
import addLanguageToStaticUrl from '../../../utils/addLanguageToStaticUrl';

import ValdanoBioPhoto1 from '../../../assets/images/about/bg-valdano-bio-01@2x.jpg';
import ValdanoBioPhoto2 from '../../../assets/images/about/bg-valdano-bio-02@2x.jpg';

import BackIcon from '../../../assets/images/arrow.svg';

import './index.scss';

const AboutDetail = ({ match }) => {
  const { pathname } = useLocation();
  const { lang } = match.params;

  const HomeMockData = i18n._culture === 'en' ? HomeMockEN : HomeMockSP;

  const {
    achievements,
    content_history,
    quote1_title,
    quote1_description,
    quote2_title,
    quote2_description,
  } = HomeMockData.about_detail;

  useEffect(() => {
    selectLanguage(lang);
    setTimeout(() => {
      window.scrollTo(0, 1);
    }, 0);
  }, [pathname, lang]);

  const langs = [
    { en: '/the-history-of-jorge-valdano' },
    { es: '/es/la-historia-de-jorge-valdano' },
  ];

  return (
    <>
      <MetaTags
        title={i18n.t('PAGE_ABOUT_DETAIL_TITLE')}
        description={i18n.t('PAGE_ABOUT_DETAIL_DESCRIPTION')}
        other_langs={langs}
      />
      <div className="page-wrap">
        <Header theme="black" page="about-detail" />
        <div className="page about-detail">
          <Container maxWidth="lg">
            <Grid container className="grid-container">
              <Grid item xs={3} md={12} className="category-title">
                <a href={addLanguageToStaticUrl('/about')}>
                  <img src={BackIcon} className="back-button" alt="Back" />
                </a>
              </Grid>
              <Grid xs={9} className="mobile-title-box">
                <h1>Jorge Valdano</h1>
              </Grid>
            </Grid>

            <Grid container className="grid-container top-detail" spacing={1}>
              <Grid item xs={12} md={4} className="achivements">
                <h1>Jorge Valdano</h1>
                <h2>{i18n.t('ACHIEVEMENTS')}</h2>
                <ul>
                  {achievements.map((item) => (
                    <li
                      dangerouslySetInnerHTML={{ __html: item }}
                      key={lodash.uniqueId('achievements_')}
                    />
                  ))}
                </ul>
              </Grid>
              <Grid item xs={0} md={1} />
              <Grid item xs={12} md={7}>
                <h3 className="story-title">{i18n.t('HISTORY')}</h3>
                <div dangerouslySetInnerHTML={{ __html: content_history }} />
              </Grid>
            </Grid>

            <Grid container className="grid-container">
              <Grid item xs={12} md={12}>
                <CardMedia
                  className="bio-photo"
                  image={ValdanoBioPhoto1}
                  title="Jorge Valdano"
                />
              </Grid>
            </Grid>

            <Grid container className="grid-container content">
              <Grid item xs={12} md={4}>
                <h3>
                  &quot;
                  <span dangerouslySetInnerHTML={{ __html: quote1_title }} />
                  &quot;
                </h3>
              </Grid>
              <Grid item xs={0} md={1} />
              <Grid item xs={12} md={7}>
                <div dangerouslySetInnerHTML={{ __html: quote1_description }} />
              </Grid>
            </Grid>

            <Grid container className="grid-container">
              <Grid item xs={12} md={12}>
                <CardMedia
                  className="bio-photo"
                  image={ValdanoBioPhoto2}
                  title="Jorge Valdano"
                />
              </Grid>
            </Grid>

            <Grid container className="grid-container content">
              <Grid item md={4}>
                <h3>
                  &quot;
                  <span dangerouslySetInnerHTML={{ __html: quote2_title }} />
                  &quot;
                </h3>
              </Grid>
              <Grid item md={1} />
              <Grid item md={7}>
                <div dangerouslySetInnerHTML={{ __html: quote2_description }} />
              </Grid>
            </Grid>

            <Grid
              container
              className="grid-container content back-button-footer"
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Grid item xs={12} md={2}>
                <a
                  href={addLanguageToStaticUrl('/about')}
                  className="btn-secondary arrow-left dark sm"
                >
                  {i18n.t('GO_BACK')}
                </a>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutDetail;
