import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import i18n from '../../../../languages';

import Header from '../../../../components/Common/Header';
import Footer from '../../../../components/Common/Footer';
import MetaTags from '../../../../components/Common/MetaTags';

import '../../index.scss';

const TermsEN = () => {
  const { pathname } = useLocation();

  const langs = [{ en: '/info/terms' }, { es: '/es/info/terms' }];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <MetaTags
        title={i18n.t('PAGE_TERMS_TITLE')}
        description={i18n.t('PAGE_TERMS_DESCRIPTION')}
        other_langs={langs}
      />
      <div className="page-wrap">
        <Header theme="black" />
        <div className="page terms">
          <Container maxWidth="lg" className="container">
            <Grid item xs={12}>
              <h2>TERMINOS Y CONDICIONES</h2>
              <p>
                <h3>
                  CONDICIONES GENERALES
                  <br />A SER ACEPTADAS POR EL ESTUDIANTE
                </h3>
                <h4>
                  <strong>1. OBJETO</strong>
                </h4>
                <p>
                  Las cl&aacute;usulas y condiciones descriptas a
                  continuaci&oacute;n en el presente (en adelante, las &ldquo;
                  <em>Condiciones Generales</em>&rdquo;), junto con el contrato
                  de formaci&oacute;n y sus anexos (en adelante, el &ldquo;
                  <em>Contrato de Formaci&oacute;n</em>&rdquo;) constituyen, en
                  forma conjunta, un contrato (en adelante, el &ldquo;
                  <em>Contrato</em>&rdquo;) entre Platform Media Lab SA, una
                  empresa constituida como una sociedad an&oacute;nima de Suiza
                  (soci&eacute;t&eacute; anonyme), inscripta en el registro del
                  comercio del Cant&oacute;n de Vaud (CHE- 426.769.305) cuya
                  sede social se encuentra actualmente en Route de Crassier 7,
                  1262 Eysins, Suiza (en adelante, &ldquo;
                  <em>Platform Media</em>&rdquo;) y el alumno (en adelante, el
                  &ldquo;<em>Alumno</em>&rdquo;). El t&eacute;rmino
                  &ldquo;Partes&rdquo; se referir&aacute;, en forma conjunta, a
                  Platform Media y al Alumno y una &ldquo;Parte&rdquo;
                  har&aacute; referencia a Platform Media o al Alumno,
                  seg&uacute;n corresponda.
                </p>
                <p>
                  En caso de discrepancias entre las Condiciones Generales y el
                  Contrato de Formaci&oacute;n, prevalecer&aacute;n los
                  t&eacute;rminos del Contrato de Formaci&oacute;n.
                </p>
                <p>
                  Las presentes Cl&aacute;usulas y Condiciones ser&aacute;n
                  aplicables a los siguientes servicios provistos por Platform
                  Media (en adelante, los &ldquo;<em>Servicios</em>&rdquo;) en
                  la plataforma de capacitaci&oacute;n online (en adelante, la
                  &ldquo;<em>Plataforma</em>&rdquo;), salvo que se indique
                  expresamente lo contrario:
                </p>
                <ul>
                  <li>
                    acceso a la Plataforma (en adelante, el &ldquo;
                    <em>Acceso a la Plataforma</em>&rdquo;), incluyendo (i) la
                    participaci&oacute;n en foros, debates o blogs; (ii) acceso
                    al cat&aacute;logo de cursos de la Plataforma; (iii)
                    recepci&oacute;n de comunicaciones relacionadas con
                    productos y servicios de la Plataforma; y (iv) intercambio
                    de material con Platform Media y otros Alumnos, como
                    as&iacute; tambi&eacute;n
                  </li>
                  <li>
                    reserva y compra de diversos programas a trav&eacute;s de la
                    Plataforma (en adelante, los &ldquo;
                    <em>Cursos de Formaci&oacute;n</em>&rdquo;).
                  </li>
                </ul>
                <p>
                  A fin de tener acceso, reservar y comprar los Servicios en la
                  Plataforma, el Alumno tendr&aacute; que aceptar el texto
                  completo de estas Condiciones Generales. El Alumno confirma
                  haber le&iacute;do, comprendido y aceptado estas Condiciones
                  Generales disponibles en el sitio web de Platform Media{' '}
                  <a href="https://oxygen.education/">oxygen.education</a> (en
                  adelante, el &ldquo;<em>Sitio Web</em>&rdquo;) o en la
                  aplicaci&oacute;n m&oacute;vil de Platform Media "OXYGEN" (en
                  adelante, la &ldquo;<em>Aplicaci&oacute;n</em>&rdquo;).
                </p>
                <p>
                  Asimismo, previa reserva de cualquier Curso de
                  Formaci&oacute;n, el Alumno deber&aacute; leer y aceptar el
                  Contrato de Formaci&oacute;n relevante con su correspondiente
                  profesor. Platform Media pondr&aacute; a disposici&oacute;n de
                  los Alumnos los Contratos de Formaci&oacute;n en el &ldquo;
                  <em>&Aacute;rea del Alumno</em>&rdquo;.
                </p>
                <h4>
                  <strong>2. REFORMA DE CONDICIONES GENERALES</strong>
                </h4>
                <p>
                  Platform Media se reserva el derecho de efectuar, en cualquier
                  momento, las reformas, actualizaciones o cambios a estas
                  Condiciones Generales que pueda considerar convenientes,
                  notificando tales modificaciones a los Alumnos mediante
                  publicaci&oacute;n de una nueva versi&oacute;n de estas en el
                  Sitio Web.
                </p>
                <p>
                  En caso de que Platform Media realice una modificaci&oacute;n,
                  actualizaci&oacute;n o cambio a estas Condiciones Generales,
                  Platform Media deber&aacute; enviar a cada Alumno registrado
                  la nueva versi&oacute;n de las Condiciones Generales a su
                  direcci&oacute;n de correo electr&oacute;nico o &Aacute;rea
                  del Alumno. El Alumno deber&aacute; realizar una
                  aceptaci&oacute;n expresa de la nueva versi&oacute;n de las
                  Condiciones Generales a fin de continuar con el uso de los
                  Servicios provistos por la Plataforma. Si el Alumno no acepta
                  la versi&oacute;n reformada de las Condiciones Generales, no
                  tendr&aacute; m&aacute;s acceso a la Plataforma y Platform
                  Media suspender&aacute; o cancelar&aacute; el Contrato
                  celebrado con el Alumno. El Alumno ser&aacute; responsable de
                  chequear las Condiciones Generales vigentes al momento de
                  contratar los Servicios relevantes.
                </p>
                <h4>
                  <strong>3. ALUMNOS REGISTRADOS</strong>
                </h4>
                <p>
                  La contrataci&oacute;n y compra de los Cursos Online
                  podr&aacute; estar reservada para los Alumnos registrados. A
                  fin de poder inscribirse como Alumno registrado, el Alumno
                  deber&aacute; aceptar y garantizar lo siguiente:
                </p>
                <ul>
                  <li>
                    Que el Alumno tiene la edad de dieciocho (18) a&ntilde;os o
                    m&aacute;s, o supera la edad legal m&iacute;nima en su
                    pa&iacute;s de residencia donde &eacute;sta sea mayor de
                    dieciocho (18) a&ntilde;os.
                  </li>
                  <li>
                    Que la informaci&oacute;n provista por el Alumno al momento
                    de su inscripci&oacute;n es fiel, est&aacute; vigente y
                    completa, y que el Alumno mantendr&aacute; dicha
                    informaci&oacute;n actualizada.
                  </li>
                  <li>
                    Que el Alumno tiene derecho a reservar y comprar los cursos
                    de Formaci&oacute;n desde su pa&iacute;s de residencia y
                    conforme las leyes aplicables al Alumno.
                  </li>
                  <li>
                    Que el alumno no puede transferir su cuenta, nombre de
                    usuario o contrase&ntilde;a a ning&uacute;n tercero.
                  </li>
                </ul>
                <h4>
                  <strong>4. PRECIO Y CONDICIONES DE PAGO</strong>
                </h4>
                <p>
                  El precio de los Cursos de Formaci&oacute;n contratados por el
                  Alumno estar&aacute; indicado en las disposiciones de los
                  honorarios vigentes aplicables al Curso de Formaci&oacute;n
                  espec&iacute;ficamente reservado (en adelante, los &ldquo;
                  <em>Honorarios Vigentes</em>&rdquo;). Las Partes podr&aacute;n
                  modificar las disposiciones relativas a los Honorarios
                  Vigentes en la medida que el Contrato de Formaci&oacute;n
                  expresamente lo disponga.
                </p>
                <p>
                  El mero Acceso a la Plataforma estar&aacute; libre de cargo.
                </p>
                <p>
                  El pago del precio del Curso de Formaci&oacute;n contratado se
                  efectuar&aacute;, a elecci&oacute;n del Alumno, a
                  trav&eacute;s de la pasarela de pagos de la Plataforma por
                  cualquier medio: tarjeta de cr&eacute;dito o d&eacute;bito,
                  transferencia bancaria o PayPal.
                </p>
                <p>
                  El Alumno no tendr&aacute; acceso a los Cursos de
                  Formaci&oacute;n hasta que el pago haya sido efectivamente
                  recibido por Platform Media. El Alumno deber&aacute; efectuar
                  el pago como m&iacute;nimo 2 d&iacute;as h&aacute;biles
                  previos al dictado del Curso de Formaci&oacute;n, en caso
                  contrario, el Alumno no podr&aacute; asistir a dicho Curso de
                  Formaci&oacute;n.
                </p>
                <p>
                  Si el Alumno opta por realizar el pago mediante una
                  transferencia bancaria, el Alumno recibir&aacute;, dentro de
                  las 4 horas posteriores a la reserva del Curso de
                  Formaci&oacute;n, un correo electr&oacute;nico indicando el
                  n&uacute;mero de cuenta bancaria a la cual deber&aacute;
                  realizarse dicha transferencia. El Alumno realizar&aacute; la
                  transferencia bancaria y enviar&aacute; el correspondiente
                  comprobante dentro de las 4 horas posteriores a la
                  recepci&oacute;n de dicho e-mail.
                </p>
                <p>
                  Todo cargo que surja de los pagos efectuados ser&aacute; en
                  todos los casos pagado por el Alumno.
                </p>
                <p>
                  Luego de la firma del Contrato, se enviar&aacute; v&iacute;a
                  e-mail al Alumno, dentro de las 24 horas, un acuse de recibo
                  del Curso de Formaci&oacute;n reservado con el detalle del
                  precio de este. Una vez efectuado el pago por parte del
                  Alumno, Platform Media emitir&aacute; una factura al Alumno.
                </p>
                <p>
                  No se permite descuento ni compensaci&oacute;n alguna.
                  Platform Media no realizar&aacute; ning&uacute;n reembolso en
                  caso de que el Curso de Formaci&oacute;n contratado y comprado
                  no se haya utilizado. Ante una cancelaci&oacute;n, todos los
                  Honorarios Vigentes ya abonados permanecer&aacute;n en poder
                  de Platform Media.
                </p>
                <h4>
                  <strong>5. PLAZO</strong>
                </h4>
                <p>
                  El Contrato entrar&aacute; en vigor a partir de la fecha de
                  aceptaci&oacute;n por parte del Alumno y dejar&aacute; de
                  estar en vigor ante la terminaci&oacute;n de la
                  relaci&oacute;n entre el Alumno y Platform Media conforme los
                  t&eacute;rminos del Contrato de Formaci&oacute;n.
                </p>
                <h4>
                  <strong>6. CURSOS DE FORMACION</strong>
                </h4>
                <p>
                  Las cl&aacute;usulas y condiciones aplicables a cada Curso de
                  Formaci&oacute;n ser&aacute;n aquellas consignadas en el
                  Contrato de Formaci&oacute;n.
                </p>
                <p>
                  En particular, el Contrato de Formaci&oacute;n incluir&aacute;
                  la siguiente informaci&oacute;n: (i) descripci&oacute;n del
                  Curso de Formaci&oacute;n; (ii) precio; (iii) plazo;
                  as&iacute; como tambi&eacute;n (iv) diplomas oficiales y
                  direcciones de correo electr&oacute;nico de la persona
                  responsable.
                </p>
                <h4>
                  <strong>
                    7. OBLIGACIONES, DECLARACIONES Y GARANT&Iacute;AS DEL ALUMNO
                  </strong>
                </h4>
                <p>El Alumno en este acto declara y garantiza:</p>
                <ol>
                  <li>
                    que toda informaci&oacute;n provista a Platform Media es
                    fiel, cierta y actualizada;
                  </li>
                  <li>
                    que pagar&aacute; el precio de los Cursos de
                    Formaci&oacute;n a ser contratados;
                  </li>
                  <li>
                    que ha optado por contratar los Cursos de Formaci&oacute;n
                    bajo su responsabilidad sujeto al Contrato de
                    Formaci&oacute;n a ser firmado y no tendr&aacute; reclamo
                    alguno contra Platform Media respecto de la
                    adecuaci&oacute;n y precisi&oacute;n del Contrato de
                    Formaci&oacute;n
                  </li>
                  <li>
                    la informaci&oacute;n o contenido que el Alumno pueda
                    introducir o compartir en la Plataforma y su correspondiente
                    uso no infringir&aacute; ning&uacute;n derecho de autor,
                    derecho de propiedad industrial o de cualquier otro tipo de
                    derechos de terceros, ni violar&aacute; ninguna ley o
                    reglamentaci&oacute;n aplicable, y que el Alumno ha obtenido
                    todos los derechos, autorizaciones y permisos exigidos a
                    tales efectos.
                  </li>
                </ol>
                <p>
                  El Alumno no evitar&aacute;, desactivar&aacute; ni
                  manipular&aacute; de manera alguna las funciones de seguridad
                  de los Servicios ofrecidos a trav&eacute;s de la Plataforma ni
                  cualquier otra funci&oacute;n que pudiera alterar las
                  condiciones de acceso y uso de los Cursos de Formaci&oacute;n
                  o de la Plataforma.
                </p>
                <p>
                  El Alumno indemnizar&aacute; y mantendr&aacute; indemne a
                  Platform Media y sus respectivos empleados, sub-licenciatarios
                  y o cesionarios, contra todo reclamo, costo, da&ntilde;o y
                  gasto (incluyendo honorarios legales y costas judiciales)
                  derivados de (i) el uso de los Servicios; (ii) la
                  inobservancia o violaci&oacute;n de alguna de las
                  disposiciones del Contrato (en particular, violaci&oacute;n de
                  alguna de las declaraciones o garant&iacute;as contenidas en
                  el presente); (iii) el uso no autorizado de la cuenta del
                  Alumno o del &Aacute;rea del Alumno; (iv) toda otra causa que
                  pudiera ser conducente a ocasionar da&ntilde;os a Platform
                  Media.
                </p>
                <p>
                  En el uso de la Plataforma, el Alumno estar&aacute; obligado a
                  respetar las siguientes restricciones: (i) no podr&aacute;
                  introducir, conservar o divulgar informaci&oacute;n alguna que
                  pudiera infringir la moral, el orden p&uacute;blico, los
                  derechos fundamentales y, en general, alguna norma aplicable;
                  y (ii) no podr&aacute; introducir, conservar ni divulgar a
                  trav&eacute;s de la Plataforma ning&uacute;n contenido que
                  pudiera da&ntilde;ar a la Plataforma o a alg&uacute;n
                  equipamiento de la Plataforma, de otros Alumnos o de
                  alg&uacute;n tercero, que pudiera impedir su normal
                  funcionamiento.
                </p>
                <p>
                  El contenido del Curso de Formaci&oacute;n ser&aacute;
                  recibido y utilizado &uacute;nicamente por el Alumno para su
                  propio uso y no en su beneficio, y el Alumno no podr&aacute;
                  transmitir ni transferir dicho contenido a terceros.
                </p>
                <h4>
                  <strong>8. LIMITACION DE RESPONSABILIDAD</strong>
                </h4>
                <p>
                  Platform Media no garantiza que el acceso y uso de la
                  Plataforma no contenga errores. La utilizaci&oacute;n de la
                  Plataforma comprende el uso de software, hardware y una
                  conexi&oacute;n a Internet, por lo que el Alumno reconoce y
                  acepta que el funcionamiento de la Plataforma podr&iacute;a
                  verse afectado por cualquier defecto y anomal&iacute;a en el
                  uso de dichos elementos.
                </p>
                <p>
                  En particular, Platform Media Lab no garantiza ni asume
                  responsabilidad alguna respecto de da&ntilde;os y perjuicios
                  de cualquier naturaleza que pudieran derivar, con
                  car&aacute;cter meramente enunciativo y no taxativo, de: (i)
                  inferencias, omisiones, interrupciones, virus
                  inform&aacute;ticos, p&eacute;rdidas de datos o contenidos
                  propios o de terceros, motivadas por cualquier causa, que
                  impidan o retrasen la prestaci&oacute;n de los Servicios o el
                  uso de los contenidos; (ii) demoras o bloqueos en el Acceso a
                  la Plataforma o al Curso de Formaci&oacute;n causados por
                  deficiencias en Internet o en sistemas electr&oacute;nicos;
                  (iii) da&ntilde;os que puedan ser causados por terceras
                  personas mediante intromisiones ileg&iacute;timas fuera del
                  control de Platform Media e; (iv) imposibilidad de dar acceso
                  provocada por el Alumno, por terceros, o por supuestos de
                  fuerza mayor.
                </p>
                <p>
                  Platform Media no garantiza ni asume ning&uacute;n tipo de
                  responsabilidad por los da&ntilde;os y perjuicios de toda
                  clase, en particular (sin car&aacute;cter taxativo)
                  da&ntilde;os indirectos y lucro cesante, que puedan deberse a:
                  (i) la instalaci&oacute;n o uso de la Plataforma; (ii) el
                  acceso o uso de los sitios enlazados; (iii) la falta de
                  calidad, licitud y utilidad de la informaci&oacute;n y
                  contenidos y servicios existentes en la Plataforma y/o en los
                  sitios enlazados; y/o (iv) por la retirada total o parcial de
                  un contenido o curso de la Plataforma por las razones que
                  Platform Media estime convenientes.
                </p>
                <p>
                  Platform Media excluye expresamente, con el alcance permitido
                  por ley, cualquier clase de responsabilidad por da&ntilde;os y
                  perjuicios, en particular (sin limitaci&oacute;n),
                  da&ntilde;os indirectos y lucro cesante derivado de: (i)
                  accidentes; (ii) lesiones; y/o (iii) enfermedad de cualquier
                  naturaleza relacionada con el uso de la Plataforma y/o de los
                  sitios enlazados.
                </p>
                <h4>
                  <strong>9. CUENTA, CONTRASE&Ntilde;A Y SEGURIDAD</strong>
                </h4>
                <p>
                  El Alumno es responsable de la calidad y confidencialidad de
                  la contrase&ntilde;a elegida al momento de su registro,
                  as&iacute; como tambi&eacute;n de toda actividad generada a
                  trav&eacute;s de su contrase&ntilde;a y cuenta. El Alumno
                  mantendr&aacute; la confidencialidad de su contrase&ntilde;a y
                  garantizar&aacute; que no la comparte en ninguna circunstancia
                  con un tercero.
                </p>
                <p>
                  El Alumno informar&aacute; de manera inmediata a Platform
                  Media acerca del uso fraudulento de su contrase&ntilde;a o
                  cuenta y cualquier otra violaci&oacute;n de su seguridad. El
                  Alumno acuerda cerrar la cuenta al finalizar cada
                  sesi&oacute;n (<em>logout</em>).
                </p>
                <p>
                  El Alumno ser&aacute; el &uacute;nico responsable de conservar
                  y cuidar su informaci&oacute;n.
                </p>
                <h4>
                  <strong>10. MODIFICACION Y EXTINCION</strong>
                </h4>
                <p>
                  Platform Media podr&aacute;, en cualquier momento, a su
                  exclusivo criterio y sin mediar notificaci&oacute;n alguna,
                  (i) modificar, suspender o incluso cerrar la Plataforma; (ii)
                  excluir al Alumno, bloquear, cancelar o suspender el registro
                  del Alumno; y (iii) rescindir el Contrato de Formaci&oacute;n
                  con el Alumno. Platform Media no incurrir&aacute; en
                  responsabilidad alguna respecto del Alumno en dicho supuesto,
                  incluyendo perjuicio o p&eacute;rdida de informaci&oacute;n
                  que pudiera resultar de ello.
                </p>
                <p>
                  En caso de rescisi&oacute;n del Contrato de Formaci&oacute;n,
                  Platform Media se reserva el derecho de borrar la cuenta del
                  Alumno en cualquier momento y sin notificaci&oacute;n alguna.
                  Platform Media no tendr&aacute; que justificar tal
                  decisi&oacute;n ni podr&aacute; recibir reclamo alguno de
                  compensaci&oacute;n por tal motivo.
                </p>
                <p>
                  En particular, el Contrato de Formaci&oacute;n se
                  extinguir&aacute; inmediatamente y el acceso a los Servicios
                  de la Plataforma ser&aacute;n definitivamente rechazados a
                  cualquier Alumno que haya violado estas Condiciones Generales.
                </p>
                <p>
                  El Alumno no podr&aacute; tener varias cuentas bajo la misma
                  identidad. Platform Media se reserva el derecho de rescindir
                  inmediatamente el Contrato de Formaci&oacute;n y suspender o
                  borrar la cuenta del Alumno que viole dicha norma y a
                  rechazar, sin dilaci&oacute;n y para el futuro, el Acceso a la
                  Plataforma y las redes conexas.
                </p>
                <p>
                  El Alumno podr&aacute; cancelar su cuenta en cualquier momento
                  a trav&eacute;s del &Aacute;rea del Alumno.
                </p>
                <h4>
                  <strong>11. DERECHOS DE PROPIEDAD INTELECTUAL</strong>
                </h4>
                <p>
                  Todos los Derechos de Propiedad Intelectual sobre la
                  Plataforma, el Sitio Web y la Aplicaci&oacute;n, as&iacute;
                  como tambi&eacute;n el contenido de los Servicios disponibles
                  en la Plataforma, son de propiedad de Platform Media o,
                  seg&uacute;n el caso, de terceros que puedan haber celebrado
                  contratos relevantes de uso con Platform Media.
                </p>
                <p>
                  Los Derechos de Propiedad Intelectual incluir&aacute;n todos
                  los derechos de propiedad intelectual, incluido el know-how,
                  en sus variadas formas tales como dibujos, dise&ntilde;os,
                </p>
                <p>
                  documentos, invenciones, programas de software, informes,
                  procesos y protocolos, que est&eacute;n protegidos mediante
                  secretos, patentes, derechos de autor y marcas (en adelante,
                  los &ldquo;<em>Derechos de Propiedad Intelectual</em>&rdquo;).
                </p>
                <p>
                  El Alumno no gozar&aacute; de manera alguna conforme a ninguna
                  disposici&oacute;n aqu&iacute; contenida de ning&uacute;n
                  Derecho de Propiedad Intelectual, salvo aquellos
                  espec&iacute;ficamente establecidos en el Contrato de
                  Formaci&oacute;n.
                </p>
                <h4>
                  <strong>12. PROTECCION DE DATOS</strong>
                </h4>
                <p>
                  Las disposiciones relativas a la recolecci&oacute;n y
                  procesamiento de datos personales del Alumno est&aacute;n
                  contenidas en la Pol&iacute;tica de Privacidad de Datos
                  disponible en{' '}
                  <a href="https://oxygen.education/es/info/privacy">
                    oxygen.education/es/info/privacy
                  </a>
                  , constituyendo tal Pol&iacute;tica de Protecci&oacute;n de
                  Datos un anexo al Contrato de Formaci&oacute;n.
                </p>
                <p>
                  Las Partes reconocen el car&aacute;cter confidencial de los
                  t&eacute;rminos de este Contrato de Formaci&oacute;n,
                  as&iacute; como de los documentos e informaci&oacute;n
                  relacionada con el mismo o que de all&iacute; surja. Dicha
                  informaci&oacute;n, incluyendo los t&eacute;rminos del
                  Contrato de Formaci&oacute;n, no podr&aacute; ser revelada por
                  las Partes, incluidos sus empleados, agentes y personas a su
                  cargo, sin el previo consentimiento por escrito de la otra
                  Parte, salvo en aquellos casos en que dicha revelaci&oacute;n
                  se imponga por requerimiento legal o por mandato judicial.
                </p>
                <p>
                  La obligaci&oacute;n de confidencialidad contenida en el
                  p&aacute;rrafo anterior continuar&aacute; vigente durante todo
                  el periodo de duraci&oacute;n del Contrato de Formaci&oacute;n
                  trato y tambi&eacute;n con posterioridad a la
                  terminaci&oacute;n de este durante un periodo de cinco
                  a&ntilde;os.
                </p>
                <h4>
                  <strong>13. CESION DEL CONTRATO</strong>
                </h4>
                <p>
                  El Alumno no podr&aacute; ceder sus derechos u obligaciones
                  derivados del presente Contrato sin el previo consentimiento
                  escrito de Platform Media.
                </p>
                <p>
                  Platform Media podr&aacute; ceder, total o parcialmente, sus
                  derechos y obligaciones previstos en este Contrato a cualquier
                  tercero sin el consentimiento previo del Alumno.
                </p>
                <h4>
                  <strong>
                    14. INDEPENDENCIA E INTEGRACION DE LAS CL&Aacute;USULAS
                  </strong>
                </h4>
                <p>
                  Si alguna disposici&oacute;n contenida en el presente fuere
                  declarada inv&aacute;lida, nula o ineficaz por cualquier
                  motivo, dicha declaraci&oacute;n no afectar&aacute; al resto
                  de las disposiciones de estas Condiciones Generales, que
                  deber&aacute;n ser consideradas como v&aacute;lidas y
                  eficaces. Sin perjuicio de lo expuesto, si una
                  disposici&oacute;n que es declarada inv&aacute;lida, nula o
                  ineficaz volviera inv&aacute;lida, nula o ineficaz a otras
                  disposiciones, &eacute;stas tambi&eacute;n se
                  considerar&aacute;n inv&aacute;lidas, nulas e ineficaces.
                </p>
                <p>
                  Las Partes ser&aacute;n liberadas de todos los derechos y
                  obligaciones que se deriven de la disposici&oacute;n declarada
                  inv&aacute;lida o ineficaz, en la medida en que estos derechos
                  y obligaciones resulten directamente afectados por la
                  invalidez o ineficacia. En este caso, las Partes
                  deber&aacute;n negociar de buena fe la sustituci&oacute;n de
                  la disposici&oacute;n declarada inv&aacute;lida o ineficaz por
                  otra disposici&oacute;n que preserve la intenci&oacute;n
                  original de las Partes y el equilibrio de las
                  contraprestaciones de las Partes.
                </p>
                <h4>
                  <strong>15. LEY APLICABLE Y JURISDICCION</strong>
                </h4>
                <p>
                  El Contrato de Formaci&oacute;n y sus anexos, incluyendo estas
                  Condiciones Generales, se rigen de acuerdo con la
                  legislaci&oacute;n del Estado de Suiza.
                </p>
                <p>
                  Sujeto a normas legales respecto de la jurisdicci&oacute;n que
                  no pueden derogarse, todas las disputas que deriven o
                  est&eacute;n relacionadas con el Contrato de Formaci&oacute;n
                  y sus anexos, incluyendo estas Condiciones Generales,
                  ser&aacute;n resueltas por los tribunales con competencia en
                  Eysins, Suiza.
                </p>
              </p>
            </Grid>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsEN;
