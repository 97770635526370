/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import Cookies from 'js-cookie';
import psl from 'psl';
import lodash from 'lodash';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import i18n from '../../languages';
import { getCourse } from '../../redux/actions/courses';
import api from '../../api';
import getOtherSettings from '../../utils/getOtherSettings';
import trackAction from '../../utils/tracking';
import { isLocalhost } from '../../serviceWorker';
import { getReferrer, getUtmParams } from '../../utils/getUtmParams';

import Header from '../../components/Common/Header';
import Footer from '../../components/Common/Footer';
import Loader from '../../components/Common/Loader';
import Jumbotron from '../../components/EducationalResource/Jumbotron';
import ActionBar from '../../components/EducationalResource/ActionBar';
import CourseAbout from '../../components/EducationalResource/CourseAbout';
import CourseProgram from '../../components/EducationalResource/CourseProgram';
import MoreCourses from '../../components/EducationalResource/MoreCourses';
import VerifySiteWide from '../../components/Common/VerifySiteWide';
import ImageSlider from '../../components/Home/ImageSlider';
import MetaTags from '../../components/Common/MetaTags';
import VideoPlayer from '../../components/Common/VideoPlayer';
import BuyButton from '../../components/Common/BuyButton';
import EarlyBidModal from '../../components/Common/Modals/EarlyBid';

import { courseType } from '../../types/coursesType';
import { degreeType } from '../../types/degreeType';
import { educationalResourcesType } from '../../types/educationalResourceType';
import menuType from '../../types/menuType';
import { userType } from '../../types/userType';

import './index.scss';

const Course = (props) => {
  const { course: id, token } = props.match.params;

  const { pathname } = useLocation();
  const [selectedTab, setSelectedTab] = useState(0);
  const [muteVideo, setMuteVideo] = useState(true);
  const [replayVideo, setReplayVideo] = useState(false);
  const [stopVideoScroll, setStopVideoScroll] = useState(false);
  const [stopVideoMenuOpen, setStopVideoMenuOpen] = useState(false);
  const [stopVideo, setStopVideo] = useState(false);
  const [openEarlyBidModal, setOpenEarlyBidModal] = useState(false);

  const videoRef = useRef(null);
  const domain = isLocalhost
    ? 'localhost'
    : `.${psl.parse(process.env.REACT_APP_URL_SITE_DOMAIN).domain}`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const currentUtm =
      Cookies.get('utm_params') && JSON.parse(Cookies.get('utm_params'));
    const currentReferrer =
      Cookies.get('referrer') && JSON.parse(Cookies.get('referrer'));

    const newUtm = getUtmParams();
    const newReferrer = getReferrer() && { referrer: getReferrer() };

    if (!lodash.isEmpty(newUtm) && currentUtm !== newUtm) {
      Cookies.set('utm_params', JSON.stringify(newUtm), {
        domain,
        expires: 7,
      });

      Cookies.set('referrer', JSON.stringify(newReferrer), {
        domain,
        expires: 7,
      });
    }

    if (!lodash.isEmpty(newReferrer)) {
      Cookies.set('referrer', JSON.stringify(newReferrer), {
        domain,
        expires: 7,
      });
    }
  }, []);

  useEffect(() => {
    props.actions.getCourse(id);
  }, [props.actions, id]);

  if (token) {
    window.localStorage.setItem('mail_validated', 'true');

    api.marketingEmail
      .confirmMarketingEmail({
        email:
          window.localStorage.getItem('mail') &&
          window.localStorage.getItem('mail').replace(/['"]+/g, ''),
        token,
      })
      .catch((responseError) => {
        // eslint-disable-next-line no-console
        console.log(responseError, 'Error');
        return false;
      });
  }

  useEffect(() => {
    !isMobile && setStopVideo(stopVideoScroll || stopVideoMenuOpen);
  }, [stopVideoScroll, stopVideoMenuOpen]);

  const { course, user } = props;

  // Tracking
  !course.loading && trackAction('view_item', [course]);

  const otherSettings =
    !course.loading && getOtherSettings(course.other_settings);
  const isVideo =
    otherSettings &&
    (isMobile
      ? otherSettings.media_mobile_url !== undefined
      : otherSettings.promo_url !== undefined);

  const videoOpacity = stopVideo ? '0.7' : '1';

  const renderVideo = (videoToPlay) => (
    <div className={classnames('home-background-back')}>
      <Fade duration={1000} delay={500}>
        <div className="home-background">
          <div className="_background-dark" />
          <div
            className={classnames('_pattern-overlay-detail', {
              mobile: isMobile,
            })}
          />
          <VideoPlayer
            ref={videoRef}
            muted={muteVideo}
            url={videoToPlay}
            playing={!stopVideo}
            onEnded={() => setStopVideo(true)}
            onPlay={() => setReplayVideo(false)}
            loop={replayVideo}
            style={{ opacity: videoOpacity }}
          />
        </div>
      </Fade>
    </div>
  );

  const renderImagen = (media) => (
    <>
      <div className="background-top" />
      <Fade duration={1000}>
        <div
          className="home-background course"
          style={
            media && {
              backgroundImage: `url(${isMobile ? media.square : media.wide})`,
            }
          }
        />
      </Fade>
    </>
  );

  return (
    <>
      <MetaTags {...course} />
      <VerifySiteWide user={user} idContent={course.id} />
      <div className="page-wrap">
        <Header
          page="course"
          educationalResource={course}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          scrollCallback={setStopVideoScroll}
          menuOpenCallback={setStopVideoMenuOpen}
        />

        {course.loading ? (
          <Loader className="no-header" />
        ) : (
          <>
            {otherSettings &&
            (isMobile
              ? otherSettings.media_mobile_url !== undefined
              : otherSettings.promo_url !== undefined)
              ? renderVideo(
                  isMobile
                    ? otherSettings.media_mobile_url
                    : otherSettings.promo_url,
                )
              : renderImagen(course.media)}
            <div className="page course">
              <Container maxWidth="lg" className="jumbo-container">
                <Jumbotron
                  type={i18n.t('COURSE')}
                  name={course.name}
                  author={course.org}
                  partners={course.partners}
                  short_description={course.short_description}
                  id={course.id}
                  user={user}
                  setMuteCallback={setMuteVideo}
                  setReplayCallback={() => {
                    videoRef.current.seekTo(0);
                    setReplayVideo(true);
                    setStopVideo(false);
                  }}
                  stop={!isVideo || stopVideo}
                  hasVideo={
                    isMobile
                      ? !!otherSettings.media_mobile_url
                      : !!otherSettings.promo_url
                  }
                  educationalResource={course}
                />
              </Container>

              <div className="action-bar-container">
                <Container maxWidth="lg">
                  <Grid container direction="row">
                    <Grid item className="title">
                      <h2>{course.name}</h2>
                      <h3>
                        {course.partners &&
                          course.partners[0] &&
                          course.partners[0].fullname}
                      </h3>
                    </Grid>
                    <Grid item style={{ marginTop: -5 }}>
                      <ActionBar
                        pricing={course.pricing}
                        type="course"
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                      />
                    </Grid>

                    <Grid
                      item
                      className="buy-button-actionbar"
                      style={{ marginLeft: 'auto' }}
                    >
                      {!course.bought && (
                        <BuyButton
                          available_language={course.available_language}
                          educationalResource={course}
                          language={course.language}
                          pricing={course.pricing}
                          status={course.status}
                          id={course.id}
                          openDescription
                        />
                      )}
                    </Grid>
                  </Grid>
                </Container>
              </div>

              <a className="anchor" name="about" id="about" href="#about">
                About
              </a>
              <CourseAbout course={course} user={user} />

              <a className="anchor" name="program" id="program" href="#program">
                Program
              </a>
              <CourseProgram
                structure={course.structure}
                user={user}
                other_settings={course.other_settings}
                id={course.id}
                status={course.status}
              />

              <a className="anchor" name="mentor" id="mentor" href="#mentor">
                Mentor
              </a>

              <Container maxWidth="xl">
                <ImageSlider slider={course.partners} fixed />
              </Container>

              <MoreCourses courses={course.related_contents} limit={2} />
            </div>
          </>
        )}
      </div>

      <EarlyBidModal
        open={openEarlyBidModal}
        handleClose={(e) => {
          e.preventDefault();
          setOpenEarlyBidModal(false);
          Cookies.set('early-access', 'false', {
            domain,
          });
        }}
      />

      <Footer className="home" user={user} />
    </>
  );
};

Course.propTypes = {
  actions: PropTypes.shape({
    getCourse: PropTypes.func,
  }),
  course: courseType,
  degree: degreeType,
  enrollments: educationalResourcesType,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  menu: menuType,
  search: educationalResourcesType,
  staticContext: PropTypes.object,
  user: userType,
};

Course.defaultProps = {
  actions: {
    getCourses: () => {},
  },
  course: {},
  degree: {},
  enrollments: [],
  menu: [],
  search: [],
  staticContext: {},
  user: {},
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getCourse: bindActionCreators(getCourse, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Course);
