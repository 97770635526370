export const DATA = {
  sponsors: [
    {
      image: 'fifa.svg',
      name: 'FIFA',
      link: '#',
    },
    {
      image: 'olympics.svg',
      name: 'Olympic Games',
      link: '#',
    },
    {
      image: 'fiba.svg',
      name: 'FIBA',
      link: '#',
    },
    {
      image: 'fjh.svg',
      name: 'FJH',
      link: '#',
    },
    {
      image: 'rugby.svg',
      name: 'Rugby',
      link: '#',
    },
    {
      image: 'plus.svg',
      name: 'Plus',
      link: '#',
    },
  ],
  slider: [
    {
      fullname: 'April Heinrichs',
      title: 'Our Mentors',
      description:
        'Is one of the most influential women in the history of women’s soccer.<br />World Champion as a player, Coach and Leader.<br />In 1998, she was the first woman to become part of the National American Soccer’s Hall of Fame.',
      image: 'BG-HEINRICH_Desktop.jpg',
      linkCta: 'Read More',
    },
    {
      fullname: 'Ettore Messina',
      title: 'Our Mentors',
      description:
        'Is considered one of the best basketball coaches worldwide.<br />He has won more than 30 titles and has coached in Italy, Spain, Russia, and United States of America (NBA: Los Angeles Lakers and San Antonio Spurs).',
      image: 'BG-MESSINA_Desktop.jpg',
      link: '/course/professional-basketball-coaching',
      linkCta: 'Read More',
    },
    {
      fullname: 'Julio Velasco',
      title: 'Our Mentors',
      description:
        'Is the greatest volleyball coach of all times.<br />He was the only one to obtain titles with National Teams in three different continents. <br />In 2003, Julio Velasco became member of the Volleyball Hall of Fame.',
      image: 'BG-VELASCO_Desktop.jpg',
      linkCta: 'Read More',
    },
    {
      fullname: 'Estanislao Bachrach',
      title: 'Our Mentors',
      description:
        'Is one of the most influential persons in the world of neurosciences.<br />Is fullname of many bestsellers, speaker at universities and companies; and mentor of sports celebrities.',
      image: 'BG-BACHRACH_Desktop.jpg',
      linkCta: 'Read More',
    },
    {
      fullname: 'Carlos Martinez',
      title: 'Our Mentors',
      description:
        'Carlos Martinez is one of the most recognized journalists and sports commentators in Ibero-America.<br />The emblematic character of his work goes beyond the offline world: he is the Spanish commentator of PES',
      image: 'BG-MARTINEZ_Desktop.jpg',
      link: '/course/sports-commentator',
      linkCta: 'Read More',
    },
    {
      fullname: 'Jorge Valdano',
      title: 'Our Mentors',
      description:
        'Has been an Elite player and World Champion in Mexico 86 Cup. Coach and Sports Director of Real Madrid, General Director of the School of University Studies of Real Madrid. In 2017, FIFA has included him in the Hall of Fame.',
      image: 'BG-VALDANO_Desktop.jpg',
      link: '/course/the-end-of-professional-athletes-career',
      linkCta: 'Read More',
    },
    {
      fullname: 'Juan Pablo Varsky',
      title: 'Our Mentors',
      description:
        'Is considered to be one of the most reliable and transparent journalists of LatinAmerica.<br />With a career of more than 30 years, he has worked in the main graphic and audiovisual media of the region.',
      linkCta: 'Read More',
      image: 'BG-VARSKY_Desktop.jpg',
    },
  ],
  about: {
    image: 'valdano.jpg',
    content:
      "The passion for soccer continues to arouse my curiosity about the unstoppable progress of the sport. I've been learning for a lifetime and now, it's clear to me, it's Oxygen time.",
    fullname: 'Jorge Valdano',
    description: 'co-founder & brand embassador',
    link: '/the-history-of-jorge-valdano',
    linkCta: 'Read More',
  },
  about_detail: {
    achievements: [
      '<b>1986:</b> Mexico World Champion with Argentina',
      '<b>1985-1986:</b> UEFA Champion with Real Madrid',
      '<b>1994-1996:</b> Real Madrid coach',
      '<b>1999-2003:</b> Sporting Director of Real Madrid',
      '<b>2009-2011:</b> Managing Director of Real Madrid',
      '<b>Currently:</b> Sports Analyst',
    ],
    content_history:
      '<p>There is no better beginning to a career than pursuing your passion and my passion was football. At first I played for pleasure, but then over time I started dreaming about pursuing it professionally. Football is what got me out of my small town and for me the ball symbolizes the change that led me elsewhere in the country at 16 and eventually abroad at the age of 19. I was always chasing my dream.</p><p>My entire life was football and I got to know the other side of pleasure: the discipline, the demands and the pressure. When football became an obligation, reading became my new pleasure as I began to take advantage of the free time that professional football allowed me. </p>',
    quote1_title:
      'There is no better beginning to a career than pursuing your passion and my passion was football.',
    quote1_description:
      '<p>Around the time I was finishing the necessary qualifications to become a coach, the Spanish newspaper “El País” invited me to write an article for them. Then, I was offered a radio program on "Cadena Ser", which had the somewhat pretentious name of "La cátedra de Valdano” (roughly translated as “Valdano’s seat” or “Valdano’s throne”) and I became the first analyst on the then newborn "Canal Plus".</p><p>Florentino Pérez offered me the position of Real Madrid’s Sporting Director and then later, in 2008, I became the club’s General Manager. This caused me to move further and further away from coaching, while at the same time completing my knowledge of the already booming football industry. Florentino Pérez was the person who best understood the future of football as a "business of heroes" and that the "Galácticos" (the “Galactics”, a group of Real Madrid superstars from 2000 onwards) marked a before and after in the football association and its management.</p><p>Over the years, I have published ten books that approach football as a game, a spectacle and as popular culture. Many of them are books that use sport to talk about leadership, teamwork, and talent management. To put it simply, I turned football into a message.</p>',
    quote2_title:
      'My passion for football still continues to this day and I am forever curious about the unstoppable progress of the sport. I have been learning all my life and now it’s clear that the world is ready for Oxygen.',
    quote2_description:
      '<p>Over time, sport has become increasingly sophisticated with technology taking it to another dimension. The industry multiplies its revenues year after year and requires trained people in all areas of sport. My passion for football continues to exist as does my curiosity for the unstoppable progress of the sport. “I have been learning all my life and now it’s clear that the world is ready for Oxygen”.</p><p>Oxygen is an academic platform which uses the web as a distribution channel, relying on the knowledge of great specialists and successful people who have put that knowledge into practice. Everyone who loves sport and wants to turn it into a career, needs to receive training. If you want to work in sport, you must study sport, in all sports and in all fields: technical, health, management, journalism, etc.</p><p>“Oxygen”, together with our future partner universities, will ensure a high quality training taught by the most relevant people in the world of sports, in order to reach those who are passionate for this wonderful world. We will give lectures, impart degrees, create community, launch a foundation and we will always be ahead of knowledge.</p><p>If you love sports, look for us and you will find yourself.</p>',
  },
  content_slider: [
    {
      image: 'bg-methodology.jpg',
      content:
        'We collaborate with our mentors and top-tier institutions to create high-end e-learning experiences in their areas of expertise.',
    },
    {
      image: 'bg-methodology.jpg',
      content:
        'We design e-learning activities using the latest in education technology, multimedia and interactive tools.',
    },
    {
      image: 'bg-methodology.jpg',
      content:
        'We produce short documentaries featuring athletes and mentors, who tell us their stories and provide personal insights',
    },
    {
      image: 'bg-methodology.jpg',
      content:
        'Our content is presented within a virtual learning environment that offers rich user experience that invites interactivity, creativity and community-building.',
    },
  ],
  content_info: [
    {
      image: 'bg-community.jpg',
      title: 'Community<br />Hub',
      description:
        "Interaction is key to learning and for this reason Oxygen has a physical space in the virtual world. In this space you'll be able to interact with other Oxygen community members, go to social events, listen to talks and stay up to date with the latest technologies in sports development. All the while being part of a unique virtual experience that connects the world of sport. <br />Register now. The gates will open soon.",
      link: 'MODAL',
      link_description: 'Remind me',
    },
    {
      image: 'bg-foundation.jpg',
      title: 'Founda-tion',
      description:
        'The Oxygen Foundation has one clear mission: To provide academic content to young athletes, people from low-income backgrounds and new parents who abandon their careers to have a baby. Through agreements with foundations and institutions, Oxygen will implement global plans with one objective: Everyone - regardless of where they are from - can access the necessary knowledge to be competitive in the world of sport.',
      link: '',
      link_description: '',
    },
  ],
};

export default DATA;
