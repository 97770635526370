import * as courses from './courses/index';
import * as degrees from './degrees/index';
import * as educationalresources from './educationalresources/index';
import * as enrollments from './enrollments/index';
import * as marketingEmail from './marketingEmail/index';
import * as menu from './menu/index';
import * as search from './search/index';

const api = {
  courses,
  degrees,
  educationalresources,
  enrollments,
  marketingEmail,
  menu,
  search,
};

export default api;
