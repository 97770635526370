import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';

import ActionBar from '../../../EducationalResource/ActionBar';
import BuyButton from '../../BuyButton';
import EnterEmailModal from '../../Modals/EnterEmail';

import i18n from '../../../../languages';

import { educationalResourceType } from '../../../../types/educationalResourceType';
import { userType } from '../../../../types/userType';

import './index.scss';

const HideOnScroll = (props) => {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: window.innerHeight,
  });

  return (
    <Slide appear={false} direction="down" in={trigger}>
      {children}
    </Slide>
  );
};

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

const StickyHeader = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const { educationalResource, selectedTab, setSelectedTab, user } = props;

  return (
    !educationalResource.loading && (
      <HideOnScroll {...props}>
        <div className="sticky-header">
          <Container maxWidth="lg">
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={0}
            >
              <Grid item sm={6} className="title">
                <h2>{educationalResource.name}</h2>
                <h3>
                  {educationalResource.partners &&
                    educationalResource.partners[0] &&
                    educationalResource.partners[0].fullname}
                </h3>
              </Grid>
              <Grid item sm={3} className="options">
                <ActionBar
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  pricing={educationalResource.pricing}
                  type={educationalResource.type}
                />
              </Grid>
              <Grid item sm={3} className="action" alignItems="flex-end">
                {educationalResource && !educationalResource.bought ? (
                  <BuyButton
                    educationalResource={educationalResource}
                    available_language={educationalResource.available_language}
                    language={educationalResource.language}
                    pricing={educationalResource.pricing}
                    status={educationalResource.status}
                    id={educationalResource.id}
                    openDescription
                  />
                ) : (
                  (!window.localStorage.getItem('mail_validated') ||
                    window.localStorage.getItem('mail_validated') ===
                      'false') &&
                  !user.logged && (
                    <>
                      <button
                        type="button"
                        onClick={setOpenModal}
                        className="btn-primary md arrow-right get-course-program"
                      >
                        {i18n.t('DOWNLOAD_SCHEDULE')}
                      </button>
                    </>
                  )
                )}
              </Grid>
            </Grid>
          </Container>
          <EnterEmailModal
            open={openModal}
            handleClose={() => setOpenModal(false)}
            idContent={educationalResource.id}
            contentType={educationalResource.type}
          />
        </div>
      </HideOnScroll>
    )
  );
};

StickyHeader.propTypes = {
  educationalResource: educationalResourceType,
  setSelectedTab: PropTypes.func,
  selectedTab: PropTypes.number,
  user: userType,
};

StickyHeader.defaultProps = {
  educationalResource: {},
  setSelectedTab: () => {},
  selectedTab: 0,
  user: {},
};

export default StickyHeader;
