/* eslint-disable no-undef */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import lodash from 'lodash';
import { Link } from 'react-router-dom';

import { educationalResourcesType } from '../../../../types/educationalResourceType';

const SearchResults = ({ results }) => (
  <Grid item sm={10}>
    <ul className="search-menu">
      {results.map((result) => {
        let url = '';
        if (
          result.language &&
          result.language.code &&
          result.language.code !== 'en'
        ) {
          url = `/${result.language.code}/${result.type}/${result.id}`;
        } else {
          url = `/${result.type}/${result.id}`;
        }

        return result.status === 'remind_me' || result.status === 'pending' ? (
          <li key={lodash.uniqueId('search_result_')} className="no-link">
            {result.name}
          </li>
        ) : (
          <li key={lodash.uniqueId('search_result_')}>
            <Link to={url}>{result.name}</Link>
          </li>
        );
      })}
    </ul>
  </Grid>
);

SearchResults.propTypes = {
  results: educationalResourcesType,
};

SearchResults.defaultProps = {
  results: [],
};

export default SearchResults;
