import { boolean, integer, shape, string } from 'prop-types';

export const headerUrlsType = shape({
  learner_profile: string,
  resume_block: string,
  logout: string,
  account_settings: string,
});

export const userType = shape({
  fullname: string,
  logged: boolean,
  username: string,
  profileImage: string,
  version: integer,
  enrollmentStatusHash: string,
  header_urls: headerUrlsType,
});
