/* eslint-disable no-nested-ternary */
import React from 'react';

import lodash from 'lodash';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { coursesType } from '../../../types/coursesType';
import i18n from '../../../languages';

import './index.scss';
import ThumbnailCourse from '../../Common/ThumbnailCourse';

const itemLimit = 2;

const MoreCourses = ({ courses }) =>
  courses.length > 0 && (
    <div className="more-courses">
      <Container maxWidth="xl">
        <Grid container className="title">
          <Grid item sm={1} />
          <Grid item sm={10} xs={12}>
            <h2>{i18n.t('MORE_COURSES')}</h2>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          className="grid-container"
          spacing={2}
        >
          {courses &&
            courses.length > 0 &&
            courses.slice(0, itemLimit).map((item, index) => {
              const gridLayout = courses.length === 1 ? 12 : 6;
              const isOnlyOne = courses.length === 1;

              return (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={gridLayout}
                  key={lodash.uniqueId('gallery_')}
                >
                  <ThumbnailCourse
                    course={item}
                    index={index}
                    forceSmall={!isOnlyOne}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Container>
    </div>
  );

MoreCourses.propTypes = {
  courses: coursesType,
};

MoreCourses.defaultProps = {
  courses: [],
};

export default MoreCourses;
