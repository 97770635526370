import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';

import i18n from '../../../../languages';

import './index.scss';

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    width: '80%',
    maxWidth: 380,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
}));

const WelcomeModal = ({ open, handleClose }) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Grid container className="form-content">
        <Grid item xs={12}>
          <h2>
            {i18n.t('MODAL_WELCOME_USER_TITLE')}
            <IconButton onClick={handleClose} className="close">
              <CloseIcon />
            </IconButton>
          </h2>
        </Grid>
      </Grid>
      <Grid container className="form-content">
        <Grid item xs={12}>
          <p>{i18n.t('MODAL_WELCOME_USER_DESCRIPTION')}</p>
        </Grid>
        <Grid item xs={12} align="right">
          <Button className="btn-primary arrow-right md" onClick={handleClose}>
            {i18n.t('MODAL_WELCOME_USER_BUTTON')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <Modal className="welcome-modal" open={open} onClose={handleClose}>
      {body}
    </Modal>
  );
};

WelcomeModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

WelcomeModal.defaultProps = {
  open: false,
  handleClose: () => {},
};

export default WelcomeModal;
