/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import i18n from '../../../../languages';

import Header from '../../../../components/Common/Header';
import Footer from '../../../../components/Common/Footer';
import MetaTags from '../../../../components/Common/MetaTags';

import '../../index.scss';

const LegalNoticeEN = () => {
  const { pathname } = useLocation();

  const langs = [{ en: '/info/legal' }, { es: '/es/info/legal' }];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <MetaTags
        title={i18n.t('PAGE_PRIVA_TITLE')}
        description={i18n.t('PAGE_PRIVA_DESCRIPTION')}
        other_langs={langs}
      />
      <div className="page-wrap">
        <Header theme="black" />
        <div className="page privacy">
          <Container maxWidth="lg" className="container">
            <Grid item xs={12}>
              <h2>LEGAL NOTICE</h2>
              <p>
                <em>Date of preparation of this Legal Notice 09/11/2021</em>
              </p>
              <p>
                <strong>PLATFORM MEDIA LAB, SA</strong>, hereinafter referred to
                as <strong>PLATFORM MEDIA</strong>, with CHE{' '}
                <strong>426.769.305, </strong>and registered office at Route de
                Crassier 7, 1262 Eysins (Switzerland), as the party responsible
                for the management of the domain of{' '}
                <a href="https://oxygen.education/">oxygen.education</a>, wishes
                to inform you of the following aspects in this Legal Notice.{' '}
              </p>
              <h3> IDENTIFICATION DATA</h3>
              <p>
                Identity:{' '}
                <strong>PLATFORM MEDIA LAB, SA - CHE: 426.769.305 </strong>
              </p>
              <p>
                Postal address:{' '}
                <strong>Route de Crassier 7, 1262 Eysins, Switzerland. </strong>
                Telephone: <strong>+41225951926 </strong>&ndash; E-mail:{' '}
                <a href="mailto:info@oxygen.education">info@oxygen.education</a>
              </p>
              <p>
                Domain name:{' '}
                <a href="https://oxygen.education/">oxygen.education</a>
              </p>
              <h3> LAW ON INFORMATION SOCIETY SERVICES</h3>
              <p>
                <strong>PLATFORM MEDIA </strong>as the party responsible for the
                website <a href="https://oxygen.education/">oxygen.education</a>
                , places this document at the disposal of users, with which it
                aims to comply with the obligations set out in current
                legislation, Any person accessing this website assumes the role
                of user, committing to the observance and strict compliance with
                the provisions herein, as well as any other legal provision that
                may be applicable.
              </p>
              <p>
                <strong>PLATFORM MEDIA</strong>, reserves the right to modify
                any type of information that may appear on this website, without
                any obligation to give prior notice or inform users of said
                obligations, with publication on the platform{' '}
                <strong>PLATFORM MEDIA</strong>
              </p>
              <p>website being understood to be sufficient.</p>
              <h3> INTELLECTUAL AND INDUSTRIAL PROPERTY RIGHT</h3>
              <p>
                The intellectual property rights of the website{' '}
                <a href="https://oxygen.education/">oxygen.education</a>, its
                source code, design, navigation structure, databases and the
                different elements contained therein are owned by{' '}
                <strong>PLATFORM MEDIA, </strong>which has exclusive rights to
                exploit them in any form and, in particular, the rights of
                reproduction, distribution, public communication and
                transformation.
              </p>
              <p>
                services offered on this website shall also be governed by the
                specific conditions set out in each case, which shall be
                understood to be accepted by the mere use of such services.
              </p>
              <p>
                The viewing, printing and downloading, if applicable, of the
                content of the website is authorised only and exclusively if the
                following conditions are met:
              </p>
              <p>
                ▪ That it is compatible with the purpose of the{' '}
                <a href="https://oxygen.education/">oxygen.education</a>{' '}
                Website.
              </p>
              <p>
                ▪ That it is carried out with the sole purpose of obtaining the
                information contained therein for personal and private use. Its
                use for commercial purposes or for distribution, public
                communication or transformation is expressly prohibited.
              </p>
              <p>
                ▪ That none of the contents related to this website are modified
                in any way.
                <br />
              </p>
              <p>
                ▪ That no graphic, symbol or image available on this website is
                used, copied or distributed separately from the text or other
                images that accompany it.
              </p>
              <p>
                <strong>PLATFORM MEDIA </strong>reserves the right to carry out,
                at any time and without prior notice, modifications and updates
                to the information contained in the aforementioned website, to
                the configuration and presentation of the website and to the
                conditions of access.
              </p>
              <p>
                <strong>PLATFORM MEDIA </strong>does not guarantee the
                non-existence of interruptions or errors in access to the
                aforementioned Webpage, in its content, nor that it is up to
                date, although it will make its best efforts to correct or
                update them, as the case may be.
              </p>
              <p>
                The unauthorised use of the information contained in this
                Webpage, as well as any breach of the Intellectual or Industrial
                Property rights of <strong>PLATFORM MEDIA</strong>, will give
                rise to the legally established responsibilities.
              </p>
              <h3> USER ACCESS TO THE WEBSITE</h3>
              <p>
                The Webpage{' '}
                <a href="https://oxygen.education/">oxygen.education</a> may be
                freely visited by users. This Webpage may have different forms
              </p>
              <p>
                particular, to establish attendance registers, registration for
                training courses promoted by <strong>PLATFORM MEDIA.</strong>
              </p>
              <h3> PERSONAL DATA PROTECTION POLICY</h3>
              <p>
                The criteria and rules followed by the data controller,{' '}
                <strong>PLATFORM MEDIA</strong>, regarding the use of the data
                that you freely and voluntarily provide through this website
                (forms), are those set out in the link identified as "privacy
                policy", as well as in the informative clauses located in each
                form or place where the data is collected or requested in order
                to give scope to the information.
              </p>
              <h3> EXEMPTION FROM LIABILITY</h3>
              <p>
                <strong>PLATFORM MEDIA </strong>is exempt from any type of
                responsibility derived from the information published on its web
                page whenever this information has been manipulated or
                introduced by a third party external to the same.
              </p>
              <p>
                <strong>PLATFORM MEDIA </strong>will not be responsible for any
                consequence, damage or harm that may arise from said access or
                use of the information. Nor will it be responsible for any
                possible security errors that may occur or for any possible
                damage that may be caused to the user's computer system
                (hardware and software), files or documents stored therein, as a
                result of the presence of a virus in the user's computer used to
                connect to the services and contents of the website, a
                malfunction of the browser or the use of non-updated versions of
                the same.
                <br />
                <br />
              </p>
              <h3> APPLICABLE LAW AND JURISDICTION</h3>
              <p>
                For the resolution of all disputes or questions related to this
                website or the activities carried out therein, the Courts and
                Tribunals of Switzerland shall be competent for the resolution
                of all disputes arising from or related to its use.
              </p>
            </Grid>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LegalNoticeEN;
