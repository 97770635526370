/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../languages';
import { config } from '../../../config/config';

import ReminderModal from '../Modals/Reminder';

import { userType } from '../../../types/userType';

import './index.scss';

const VerifySiteWide = ({ user, idContent }) => {
  const [openModal, setOpenModal] = useState(false);

  let copy = '';

  const isEmailVerifyPending =
    (user.logged && user.active === false) ||
    (window.localStorage.getItem('mail_validated') &&
      window.localStorage.getItem('mail_validated') === 'false');
  const isEmailValidatedButNoAccount =
    !user.logged &&
    window.localStorage.getItem('mail_validated') &&
    window.localStorage.getItem('mail_validated') === 'true';

  if (isEmailVerifyPending) {
    copy = `<div class="site-wide-container">${i18n.t(
      'VERIFY_EMAIL_BANNER',
    )}</div>`;
  }

  if (isEmailValidatedButNoAccount) {
    copy = `<a class="site-wide-container" href="${
      config.URL_EDX_DOMAIN
    }/register?next=https%3A%2F%2F${config.URL_SITE_DOMAIN}%2F">${i18n.t(
      'CREATE_ACCOUNT_BANNER',
    )}</a>`;
  }

  return (
    copy && (
      <>
        <div
          className="verify-site-wide"
          onClick={() => isEmailVerifyPending && setOpenModal(true)}
          dangerouslySetInnerHTML={{ __html: copy }}
        />
        <ReminderModal
          handleClose={(e) => {
            e.preventDefault();
            setOpenModal(false);
          }}
          idContent={idContent}
          open={openModal}
          user={user}
        />
      </>
    )
  );
};

VerifySiteWide.propTypes = {
  user: userType,
  idContent: PropTypes.string,
};

VerifySiteWide.defaultProps = {
  user: {},
  idContent: '',
};

export default VerifySiteWide;
