import Cookies from 'js-cookie';

export const getUtmParams = () => {
  let params = {};
  window.location.search
    .slice(1)
    .split('&')
    .forEach((elm) => {
      if (elm === '') return;
      let spl = elm.split('=');
      const d = decodeURIComponent;
      if (spl[0].includes('utm_')) {
        params[d(spl[0])] = spl.length >= 2 ? d(spl[1]) : true;
      }
    });

  return params;
};

export const getReferrer = () => {
  return (
    document.referrer.indexOf(
      window.location.protocol + '//' + window.location.host,
    ) !== 0 && window.document.referrer
  );
};

export const getVariablesToSend = () => {
  const variablesToSend =
    Cookies.get('utm_params') && JSON.parse(Cookies.get('utm_params'));
  const referrerToSend =
    Cookies.get('referrer') && JSON.parse(Cookies.get('referrer'));

  return new URLSearchParams({
    ...variablesToSend,
    ...referrerToSend,
  }).toString();
};
