/* eslint-disable no-undef */
import React, { useState } from 'react';

import classnames from 'classnames';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';

import i18n from '../../../languages';

import GetProgramModal from '../../Common/Modals/GetProgram';

import ButtonMoreIcon from '../../../assets/images/button-more.svg';
import ButtonMoreClosedIcon from '../../../assets/images/button-more-closed.svg';

import './index.scss';
import { periodType } from '../../../types/periodsType';

const DegreeProgram = (props) => {
  const { periods, user } = props;
  const [expanded, setExpanded] = React.useState(false);
  const [subExpanded, setSubExpanded] = React.useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    if (!window.localStorage.getItem('mail_validated') && !user.logged) {
      setOpenModal(true);
    } else {
      setExpanded(isExpanded ? panel : false);
    }
  };

  const handleChangeExpanded = (panel) => (event, isExpanded) => {
    setSubExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="course-program">
      <Container maxWidth="lg">
        <Grid container>
          <Grid item sm={12} className="content">
            <Grid container className="title">
              <Grid item sm={8} className="content">
                <h2>{i18n.t('STUDY_PROGRAM')}</h2>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <div>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item sm={12} xs={12} className="content-program">
              {periods.map((period) => (
                <ExpansionPanel
                  key={`panel${period.id}`}
                  expanded={expanded === `panel${period.id}`}
                  onChange={handleChange(`panel${period.id}`)}
                >
                  <ExpansionPanelSummary
                    aria-controls={`panel${period.id}bh-content`}
                    id={`panel${period.id}bh-header`}
                    className="structure"
                  >
                    <Grid
                      container
                      justifyContent="flex-end"
                      className="structure-container"
                    >
                      <Grid item xs={12} sm={4} className="title">
                        <h3>{period.name}</h3>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <p>{period.description || period.short_description}</p>
                      </Grid>

                      <Grid item xs={12} sm={1} className="more text-right">
                        {period.subjects && period.subjects.length > 0 && (
                          <>
                            {expanded === `panel${period.id}` ? (
                              <img
                                src={ButtonMoreClosedIcon}
                                alt="Ver menos"
                                className="btn-close-open"
                              />
                            ) : (
                              <img
                                src={ButtonMoreIcon}
                                alt="Ver más"
                                className="btn-close-open"
                              />
                            )}
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </ExpansionPanelSummary>

                  <AccordionDetails
                    className={classnames(
                      expanded === `panel${period.id}` &&
                        subExpanded === false &&
                        'opened',
                    )}
                  >
                    <Grid item sm={12}>
                      {period.subjects &&
                        period.subjects.map((subject) => (
                          <ExpansionPanel
                            key={`panel_sub${subject.id}`}
                            expanded={subExpanded === `panel_sub${subject.id}`}
                            onChange={handleChangeExpanded(
                              `panel_sub${subject.id}`,
                            )}
                            className="subsection-panel"
                          >
                            <ExpansionPanelSummary
                              aria-controls={`panel_sub${subject.id}bh-content`}
                              id={`panel_sub${subject.id}bh-header`}
                            >
                              <Grid
                                container
                                spacing={3}
                                justifyContent="flex-end"
                                className="subsection-container"
                              >
                                <Grid item xs={12} sm={4} className="title">
                                  <h4>{subject.name}</h4>
                                </Grid>
                                <Grid item xs={12} sm={7}>
                                  <p>{subject.short_description}</p>
                                </Grid>
                                <Hidden only={['xs', 'sm']}>
                                  <Grid item xs={12} sm={1} className="more">
                                    {subject.overview &&
                                      subject.overview !== '' &&
                                      (subExpanded ===
                                      `panel_sub${subject.id}` ? (
                                        <ExpandLessIcon className="expand-icon" />
                                      ) : (
                                        <ExpandMoreIcon className="expand-icon" />
                                      ))}
                                  </Grid>
                                </Hidden>
                              </Grid>
                            </ExpansionPanelSummary>

                            <Hidden only={['xs', 'sm']}>
                              <AccordionDetails
                                className={classnames(
                                  'labeled-content',
                                  subExpanded === `panel_sub${subject.id}` &&
                                    'opened',
                                )}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  className="content-program"
                                >
                                  <Grid
                                    container
                                    justifyContent="center"
                                    className="unit-container"
                                  >
                                    <Grid item sm={11}>
                                      <div
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={{
                                          __html: subject.overview,
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </AccordionDetails>
                            </Hidden>
                          </ExpansionPanel>
                        ))}
                    </Grid>
                  </AccordionDetails>
                </ExpansionPanel>
              ))}
            </Grid>
          </Grid>
        </Container>
        <GetProgramModal
          open={openModal}
          handleClose={() => setOpenModal(false)}
          idContent={props.id}
        />
      </div>
    </div>
  );
};

DegreeProgram.propTypes = {
  periods: periodType,
};

DegreeProgram.defaultProps = {
  periods: [],
};

export default DegreeProgram;
