import psl from 'psl';
import Cookies from 'js-cookie';
import { isLocalhost } from '../serviceWorker';

const languageList = (lng) => {
  let lngLabel = '';

  switch (lng.toLowerCase().substring(0, 2)) {
    case 'es':
      lngLabel = 'es-419';
      break;
    case 'en':
      lngLabel = 'en';
      break;
    default:
      lngLabel = 'en';
      break;
  }
  return lngLabel;
};

const selectLanguage = (lang = undefined) => {
  const domain = isLocalhost
    ? 'localhost'
    : `.${psl.parse(process.env.REACT_APP_URL_SITE_DOMAIN).domain}`;

  if (window.location.href.substr(window.location.href.length - 3) === '/en') {
    Cookies.set('openedx-language-preference', 'en', {
      domain,
    });
    window.location.href = '/';
    return false;
  }

  if (lang && lang.length === 2) {
    if (
      !Cookies.get('openedx-language-preference') ||
      (Cookies.get('openedx-language-preference') &&
        Cookies.get('openedx-language-preference').substring(0, 2) !== lang)
    ) {
      Cookies.set('openedx-language-preference', languageList(lang), {
        domain,
      });

      window.location.reload(false);
    }
  }

  return false;
};

export default selectLanguage;
