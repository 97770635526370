import { arrayOf, shape, string } from 'prop-types';

export const unitType = shape({
  content_type: string,
  description: string,
  title: string,
  name: string,
});

export const subsectionType = shape({
  description: string,
  title: string,
  name: string,
  units: arrayOf(unitType),
});

export const structureType = shape({
  description: string,
  title: string,
  name: string,
  subsections: arrayOf(subsectionType),
});

export const structuresType = arrayOf(structureType);
