import api from '../../api';
import {
  GET_MENU_REQUEST,
  GET_MENU_SUCCESS,
  GET_MENU_ERROR,
} from '../constants';

export const getMenu = () => (dispatch) => {
  dispatch({
    type: GET_MENU_REQUEST,
    meta: api.menu
      .getMenu()
      .then((response) => {
        dispatch({
          type: GET_MENU_SUCCESS,
          payload: {
            data: response.data || response,
          },
        });
      })
      .catch((error) =>
        dispatch({
          type: GET_MENU_ERROR,
          payload: error.response,
        }),
      ),
  });
};

export default getMenu;
