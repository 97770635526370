/* eslint-disable react/no-danger */
/* eslint-disable global-require */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import { isMobile } from 'react-device-detect';

import GetProgramModal from '../../Common/Modals/GetProgram';

import ButtonMoreIcon from '../../../assets/images/button-more.svg';
import ButtonMoreClosedIcon from '../../../assets/images/button-more-closed.svg';

import { userType } from '../../../types/userType';
import { structuresType } from '../../../types/structureType';

import i18n from '../../../languages';

import './index.scss';

const echoImage = (image) => {
  try {
    return require(`../../../assets/images/icons/unit-icons/${image}.svg`)
      .default;
  } catch (e) {
    return false;
  }
};

const CourseProgram = (props) => {
  const { structure, id } = props;
  const [expanded, setExpanded] = React.useState(false);
  const [subExpanded, setSubExpanded] = React.useState(false);

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeExpanded = (panel) => (_, isExpanded) => {
    setSubExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="course-program">
      <Container maxWidth="lg">
        <Grid container>
          <Grid item sm={12} className="content">
            <Grid container className="title">
              <Grid item sm={8} className="content">
                <h2
                  dangerouslySetInnerHTML={{ __html: i18n.t('STUDY_PROGRAM') }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <div>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item sm={12} xs={12} className="content-program">
              {structure.map((courseStructure, index) => {
                const expand = !isMobile && {
                  expanded: expanded === `panel${index}`,
                };
                return (
                  <ExpansionPanel
                    key={`panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                    {...expand}
                  >
                    <ExpansionPanelSummary
                      aria-controls={`panel${index}bh-content`}
                      id={`panel${index}bh-header`}
                      className="structure"
                    >
                      <Grid
                        container
                        justifyContent="flex-end"
                        className="structure-container"
                      >
                        <Grid item xs={12} sm={12} className="date">
                          <h4>
                            {courseStructure.title ||
                              (courseStructure.name.split(':')[0] &&
                                courseStructure.name.split(':')[0].trim())}
                          </h4>
                        </Grid>
                        <Grid item xs={12} sm={4} className="title">
                          <h3>
                            {courseStructure.name.split(':')[1] ||
                              courseStructure.name}
                          </h3>
                        </Grid>
                        <Grid item xs={12} sm={7} className="content-main">
                          <p>
                            {courseStructure.description ||
                              courseStructure.short_description}
                          </p>
                        </Grid>
                        <Grid item xs={12} sm={1} className="more text-right">
                          <>
                            {expanded === `panel${index}` ? (
                              <img
                                src={ButtonMoreClosedIcon}
                                alt="Ver menos"
                                className="btn-close-open"
                              />
                            ) : (
                              <img
                                src={ButtonMoreIcon}
                                alt="Ver más"
                                className="btn-close-open"
                              />
                            )}
                          </>
                        </Grid>
                      </Grid>
                    </ExpansionPanelSummary>

                    <AccordionDetails
                      className={classnames(
                        expanded === `panel${index}` &&
                          subExpanded === false &&
                          'opened',
                      )}
                    >
                      <Grid item sm={12}>
                        {courseStructure.subsections &&
                          courseStructure.subsections.map(
                            (subsection, subsectionIndex) => (
                              <ExpansionPanel
                                key={`panel_sub${subsectionIndex}`}
                                expanded={
                                  subExpanded === `panel_sub${subsectionIndex}`
                                }
                                onChange={handleChangeExpanded(
                                  `panel_sub${subsectionIndex}`,
                                )}
                                className="subsection-panel"
                              >
                                <ExpansionPanelSummary
                                  aria-controls={`panel_sub${subsectionIndex}bh-content`}
                                  id={`panel_sub${subsectionIndex}bh-header`}
                                >
                                  <Grid
                                    container
                                    spacing={3}
                                    justifyContent="flex-end"
                                    className="subsection-container"
                                  >
                                    <Grid item xs={12} sm={9} className="title">
                                      <h4>{subsection.name}</h4>
                                      <p>{subsection.description}</p>
                                      <ul className="lesson-content-list hide">
                                        {[
                                          ...subsection.units
                                            .reduce((mp, o) => {
                                              if (!mp.has(o.content_type)) {
                                                mp.set(o.content_type, {
                                                  ...o,
                                                  count: 0,
                                                });
                                              }
                                              // eslint-disable-next-line no-param-reassign
                                              mp.get(o.content_type).count += 1;
                                              return mp;
                                            }, new Map())
                                            .values(),
                                        ].map(
                                          (unit, unitIndex) =>
                                            unit &&
                                            unit.content_type && (
                                              <li key={`unit_sub${unitIndex}`}>
                                                <img
                                                  src={echoImage(
                                                    unit.content_type
                                                      .replace(/\s/g, '-')
                                                      .toLowerCase(),
                                                  )}
                                                  className="icon"
                                                  alt=""
                                                />
                                                <span>{unit.count}</span>
                                                &nbsp;
                                                <span>
                                                  {unit.content_type_label}
                                                </span>
                                              </li>
                                            ),
                                        )}
                                      </ul>
                                    </Grid>
                                    <Hidden only={['xs', 'sm']}>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={2}
                                        className="more"
                                      >
                                        {subExpanded ===
                                        `panel_sub${subsectionIndex}` ? (
                                          <ExpandLessIcon className="expand-icon" />
                                        ) : (
                                          <ExpandMoreIcon className="expand-icon" />
                                        )}
                                      </Grid>
                                    </Hidden>
                                  </Grid>
                                </ExpansionPanelSummary>

                                <Hidden only={['xs', 'sm']}>
                                  <AccordionDetails
                                    className={classnames(
                                      'labeled-content',
                                      subExpanded ===
                                        `panel_sub${subsectionIndex}` &&
                                        'opened',
                                    )}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      className="content-program"
                                    >
                                      <Grid
                                        container
                                        justifyContent="center"
                                        className="unit-container"
                                      >
                                        <Grid item xs={12} sm={10}>
                                          <h4>{i18n.t('FEATURED_CONTENT')}</h4>
                                        </Grid>
                                      </Grid>

                                      <Grid
                                        container
                                        justifyContent="center"
                                        className="unit-container"
                                      >
                                        <Grid item xs={12} sm={10}>
                                          <Grid
                                            container
                                            justifyContent="flex-start"
                                          >
                                            {subsection.units &&
                                              subsection.units.length > 0 &&
                                              subsection.units.map(
                                                (unit, unitSubIndex) =>
                                                  unit.xblock_featured_content &&
                                                  unit.content_type && (
                                                    <Grid
                                                      item
                                                      sm={6}
                                                      className="unit-content-container"
                                                      key={`unit_sub_sub${unitSubIndex}`}
                                                    >
                                                      <img
                                                        src={echoImage(
                                                          unit.content_type
                                                            .replace(/\s/g, '-')
                                                            .toLowerCase(),
                                                        )}
                                                        className="icon"
                                                        alt=""
                                                      />
                                                      <div>
                                                        <span className="unit-container-title">
                                                          {
                                                            unit.content_type_label
                                                          }{' '}
                                                          - {unit.name}
                                                        </span>
                                                        {unit.description && (
                                                          <span>
                                                            {unit.description}
                                                          </span>
                                                        )}
                                                      </div>
                                                    </Grid>
                                                  ),
                                              )}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </AccordionDetails>
                                </Hidden>
                              </ExpansionPanel>
                            ),
                          )}
                      </Grid>
                    </AccordionDetails>
                  </ExpansionPanel>
                );
              })}
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

CourseProgram.propTypes = {
  structure: structuresType,
  user: userType,
  other_settings: PropTypes.string,
  id: PropTypes.string,
  status: PropTypes.string,
};

CourseProgram.defaultProps = {
  structure: [],
  user: {},
  other_settings: '',
  id: '',
  status: '',
};

export default CourseProgram;
