import {
  GET_SEARCH_REQUEST,
  GET_SEARCH_SUCCESS,
  GET_SEARCH_ERROR,
} from '../constants';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SEARCH_REQUEST:
      return [];
    case GET_SEARCH_SUCCESS:
      return [...action.payload.data];
    case GET_SEARCH_ERROR:
      return [];

    default:
      return state;
  }
};
