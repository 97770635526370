import { combineReducers } from 'redux';

import course from './course';
import degree from './degree';
import educationalResources from './educationalResources';
import enrollments from './enrollments';
import menu from './menu';
import marketingEmail from './marketingEmail';
import user from './user';
import search from './search';

export default combineReducers({
  course,
  degree,
  educationalResources,
  enrollments,
  menu,
  marketingEmail,
  search,
  user,
});
