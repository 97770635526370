import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';

import i18n from '../../../../languages';

import './index.scss';

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '80%',
    maxWidth: 380,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
}));

const LanguageAvailableModal = ({ open, handleClose, pricingUrl }) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [success, setSuccess] = useState(false);

  const submitButton = (event) => {
    event.preventDefault();
    event.stopPropagation();
    window.location.href = pricingUrl;
    return null;
  };

  const cancelButton = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setSuccess(true);
    return null;
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      {success ? (
        <>
          <Grid container className="form-content">
            <Grid item xs={12}>
              <h2>
                {i18n.t('MODAL_LANGUAGE_AVAILABLE_THANKS_TITLE')}
                <IconButton onClick={handleClose} className="close">
                  <CloseIcon />
                </IconButton>
              </h2>
            </Grid>
          </Grid>
          <Grid container className="form-content">
            <Grid item xs={12}>
              <p>{i18n.t('MODAL_LANGUAGE_AVAILABLE_THANKS')}</p>
            </Grid>
            <Grid item xs={12} align="right">
              <Button
                className="btn-primary md button-form"
                onClick={handleClose}
              >
                {i18n.t('YES')}
              </Button>
              <Button
                className="btn-primary md button-form"
                onClick={handleClose}
              >
                {i18n.t('NO')}
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container className="form-content">
            <Grid item xs={12}>
              <h2>
                {i18n.t('MODAL_LANGUAGE_AVAILABLE_TITLE')}
                <IconButton onClick={handleClose} className="close">
                  <CloseIcon />
                </IconButton>
              </h2>
            </Grid>
          </Grid>
          <Grid container className="form-content">
            <Grid item xs={12}>
              <p>{i18n.t('MODAL_LANGUAGE_AVAILABLE_DESCRIPTION')}</p>
            </Grid>
            <Grid item xs={12} align="right">
              <Button
                className="btn-primary md button-form"
                onClick={submitButton}
              >
                {i18n.t('YES')}
              </Button>
              <Button
                className="btn-primary md button-form"
                onClick={cancelButton}
              >
                {i18n.t('NO')}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );

  return (
    <Modal
      className="language-available-modal"
      open={open}
      onClose={handleClose}
    >
      {body}
    </Modal>
  );
};

LanguageAvailableModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  pricingUrl: PropTypes.string,
};

LanguageAvailableModal.defaultProps = {
  open: false,
  handleClose: () => {},
  pricingUrl: '',
};

export default LanguageAvailableModal;
