import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import './index.scss';

const CourseContent = (props) => (
  <div className="course-content">
    <Grid container>
      <Grid item xs={12} className="content">
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: props.overview }}
        />
      </Grid>
    </Grid>
  </div>
);

CourseContent.propTypes = {
  overview: PropTypes.string,
};

CourseContent.defaultProps = {
  overview: '',
};

export default CourseContent;
