/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import { NavHashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Grid from '@material-ui/core/Grid';

import i18n from '../../../../languages';
import { userType } from '../../../../types/userType';
import { educationalResourceType } from '../../../../types/educationalResourceType';
import { getVariablesToSend } from '../../../../utils/getUtmParams';

import { config } from '../../../../config/config';
import UserMenu from '../UserMenu';
import LangMenu from '../LangMenu';

import logo from '../../../../assets/images/logo.svg';
import hamburguer from '../../../../assets/images/hamburguer.svg';
import hamburguerClose from '../../../../assets/images/hamburguer-close.svg';

import './index.scss';

const StaticHeader = ({
  clickMenu,
  menuOpen,
  page,
  user,
  theme,
  educationalResource,
  scrollCallback,
  hasEnrollment,
}) => {
  const [toggleOpen, setToggleOpen] = useState(false);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: hasEnrollment ? 800 : 100,
  });

  useEffect(() => {
    scrollCallback(trigger);
  }, [trigger]);

  const openMenu = (event) => {
    event.preventDefault();
    setToggleOpen(!toggleOpen);
    clickMenu(event);
  };

  const params = getVariablesToSend() && `&${getVariablesToSend()}`;

  return (
    <div className={classnames('static-header', theme, { black: trigger })}>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          alignItems="center"
          className="header-container"
        >
          <Grid item xs={6} sm={1} md={1} className="logo">
            <Link to="/">
              <img src={logo} className="header-logo" alt="Oxygen" />
            </Link>
          </Grid>
          <Grid item xs={6} sm={11} md={2} className="menu">
            <Box display={{ xs: 'none', md: 'block' }}>
              <button
                className={`${
                  !menuOpen ? 'btn-white arrow-down' : 'btn-primary arrow-up'
                } sm`}
                type="button"
                onClick={openMenu}
              >
                {!menuOpen ? i18n.t('EXPLORE') : i18n.t('CLOSE')}
              </button>
            </Box>
            <Box display={{ xs: 'flex', md: 'none' }}>
              {toggleOpen && (
                <LangMenu
                  otherLangs={educationalResource.other_langs}
                  page={page}
                />
              )}
              <a href="/" onClick={openMenu}>
                <img
                  src={toggleOpen ? hamburguerClose : hamburguer}
                  className="menu-mobile"
                  alt="Oxygen"
                />
              </a>
            </Box>
          </Grid>
          <Grid item xs={4} sm={2}>
            <Hidden only={['xs', 'sm']}>
              <NavHashLink to="/#community" className="btn-tertiary sm" smooth>
                {i18n.t('COMMUNITY_HUB')}
              </NavHashLink>
            </Hidden>
          </Grid>

          <Hidden only={['xs', 'sm']}>
            <Grid item xs={6} sm={7} className="right-buttons">
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item xs={4} sm={3}>
                  {!user.logged && (
                    <a
                      href={`https:${config.URL_EDX_DOMAIN}/login?next=https%3A%2F%2F${config.URL_SITE_DOMAIN}%2F`}
                      className="btn-tertiary sm login"
                    >
                      {i18n.t('LOGIN')}
                    </a>
                  )}
                </Grid>
                <Grid item xs={4} sm={3}>
                  {user.logged ? (
                    <UserMenu user={user} />
                  ) : (
                    <a
                      href={`https:${config.URL_EDX_DOMAIN}/register?next=https%3A%2F%2F${config.URL_SITE_DOMAIN}%2F${params}`}
                      className="btn-secondary sm login"
                    >
                      {i18n.t('REGISTER')}
                    </a>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <LangMenu
                    page={page}
                    otherLangs={educationalResource.other_langs}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  );
};

StaticHeader.propTypes = {
  clickMenu: PropTypes.func,
  educationalResource: educationalResourceType,
  menuOpen: PropTypes.bool,
  theme: PropTypes.string,
  user: userType,
  page: PropTypes.string,
  scrollCallback: PropTypes.func,
  hasEnrollment: PropTypes.bool,
};

StaticHeader.defaultProps = {
  clickMenu: () => {},
  educationalResource: {},
  menuOpen: false,
  theme: '',
  user: {
    logged: false,
  },
  page: '',
  scrollCallback: () => {},
  hasEnrollment: false,
};

export default StaticHeader;
