import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import lodash from 'lodash';

import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import api from '../../../../api';
import i18n from '../../../../languages';

import { userType } from '../../../../types/userType';

import './index.scss';

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '80%',
    maxWidth: 380,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
}));

const ReminderModal = ({ handleClose, idContent, open, user }) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [email, setEmail] = useState(
    window.localStorage.getItem('mail') &&
      window.localStorage.getItem('mail').replace(/['"]+/g, ''),
  );
  const [isShowEnterEmail, setIsShowEnterEmail] = useState(false);

  const isRegistered = user && user.logged && user.active === false;

  const sendRegisteredReSendMarketing = () => {
    setLoading(true);

    return api.marketingEmail
      .reSendActivationEmail()
      .then(() => {
        setSuccess(true);
        setLoading(false);
        return false;
      })
      .catch((responseError) => {
        // eslint-disable-next-line no-console
        setLoading(false);
        return false;
      });
  };

  const sendNotLoggedReSendMarketing = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setLoading(true);

    if (
      email ===
      (window.localStorage.getItem('mail') &&
        window.localStorage.getItem('mail').replace(/['"]+/g, ''))
    ) {
      return api.marketingEmail
        .reSendMarketingEmail({
          email,
          id_content: idContent,
          tag: 'get-course-program', // Its a resend email. could be called from banner from home page. In this case make sense?
        })
        .then(() => {
          setSuccess(true);
          setLoading(false);
          return false;
        })
        .catch((responseError) => {
          // eslint-disable-next-line no-console
          console.log(responseError, 'Error');
          setLoading(false);
          return false;
        });
    }
    return api.marketingEmail
      .sendMarketingEmail({
        email,
        id_content: idContent,
        tag: 'get-course-program', // Its a resend email. could be called from banner from home page. In this case make sense?
      })
      .then(() => {
        setSuccess(true);
        setLoading(false);
        return false;
      })
      .catch((responseError) => {
        // eslint-disable-next-line no-console
        console.log(responseError, 'Error');
        setLoading(false);
        return false;
      });
  };

  const handleSendAgain = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!isRegistered) {
      setIsShowEnterEmail(true);
      return null;
    }

    if (isRegistered) {
      setIsShowEnterEmail(false);
      sendRegisteredReSendMarketing();
      return null;
    }
  };

  const changeForm = (event) => {
    event.preventDefault();
    setEmail(event.target.value);
    setError({});
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Grid container className="form-content reminder-modal">
        <Grid item xs={12}>
          <h2>
            {i18n.t('MODAL_COMPLETE_REGISTRATION_TITLE')}
            <IconButton onClick={handleClose} className="close">
              <CloseIcon />
            </IconButton>
          </h2>
        </Grid>
      </Grid>
      {success ? (
        <>
          <Grid container className="form-content reminder-modal">
            <Grid item xs={12}>
              <p>{i18n.t('MODAL_ENTER_EMAIL_THANKS')}</p>
            </Grid>
            <Grid item xs={12} align="right">
              <Button
                className="btn-primary arrow-right md"
                disabled={loading}
                onClick={handleClose}
              >
                {i18n.t('BUTTON_OK')}
              </Button>
            </Grid>
          </Grid>
        </>
      ) : isShowEnterEmail ? (
        <Grid container className="form-content reminder-modal">
          <Grid item sm={12}>
            <p>{i18n.t('EMAIL_VERIFICATION_DESCRIPTION')}</p>
          </Grid>

          <Grid container className="form-content">
            <Grid item xs={12} sm={8}>
              <TextField
                error={!lodash.isEmpty(error)}
                helperText={error && error.msg}
                name="marketingEmail"
                label="E-Mail"
                color="secondary"
                type="email"
                className="form-input"
                defaultValue={
                  window.localStorage.getItem('mail') &&
                  window.localStorage.getItem('mail').replace(/['"]+/g, '')
                }
                onChange={changeForm}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                className="btn-primary arrow-right md"
                disabled={loading}
                onClick={sendNotLoggedReSendMarketing}
              >
                {i18n.t('MODAL_ENTER_EMAIL_SEND')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container className="form-content reminder-modal">
          <Grid item xs={12}>
            <p>
              {i18n
                .t('MODAL_COMPLETE_REGISTRATION_DESCRIPTION')
                .replace('{email}', window.localStorage.getItem('mail'))}
            </p>
          </Grid>
          <Grid item sm={8} xs={12}>
            <p className="bottom">
              {i18n.t('MODAL_COMPLETE_DIDNT_RECEIVE')}{' '}
              <a
                href="/"
                className="send-again"
                disabled={loading}
                onClick={handleSendAgain}
              >
                {i18n.t('SEND_AGAIN')}
              </a>
            </p>
          </Grid>
          <Grid item sm={4} xs={12} align="right">
            <Button
              className="btn-primary arrow-right md"
              disabled={loading}
              onClick={handleClose}
            >
              {i18n.t('BUTTON_OK')}
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );

  return (
    <Modal className="reminder-email-modal" open={open} onClose={handleClose}>
      {body}
    </Modal>
  );
};

ReminderModal.propTypes = {
  idContent: PropTypes.string,
  open: PropTypes.bool,
  user: userType,
  handleClose: PropTypes.func,
};

ReminderModal.defaultProps = {
  idContent: '',
  open: false,
  user: {},
  handleClose: () => {},
};

export default ReminderModal;
