import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import i18n from '../../../../languages';

import Header from '../../../../components/Common/Header';
import Footer from '../../../../components/Common/Footer';
import MetaTags from '../../../../components/Common/MetaTags';

import '../../index.scss';

const PrivacyES = () => {
  const { pathname } = useLocation();

  const langs = [{ en: '/info/privacy' }, { es: '/es/info/privacy' }];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <MetaTags
        title={i18n.t('PAGE_PRIVA_TITLE')}
        description={i18n.t('PAGE_PRIVA_DESCRIPTION')}
        other_langs={langs}
      />
      <div className="page-wrap">
        <Header theme="black" />
        <div className="page privacy">
          <Container maxWidth="lg" className="container">
            <Grid item xs={12}>
              <h2>Política de Privacidad</h2>
              <p>
                <em>
                  Fecha de elaboraci&oacute;n de la presente Pol&iacute;tica de
                  Privacidad 15/11/2021
                </em>
              </p>
              <p>
                De conformidad con el Reglamento General de Protecci&oacute;n de
                Datos (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27
                de abril de 2016 (en adelante, el &ldquo;RGPD&rdquo;), la
                presente Pol&iacute;tica de privacidad tiene como objeto
                informar a todos aquellos usuarios sujetos a la misma del
                posible tratamiento de sus datos personales.
              </p>
              <p>
                Cuando los usuarios visitan la p&aacute;gina web{' '}
                <a href="https://oxygen.education/">oxygen.education</a>, cuando
                solicitan informaci&oacute;n, solicitan o contratan cualesquiera
                de nuestros servicios, <strong>PLATFORM MEDIA </strong>puede
                tratar sus datos personales.
              </p>
              <p>
                Esta Pol&iacute;tica de privacidad est&aacute; destinada a
                ayudarle a entender qu&eacute; datos recopila{' '}
                <strong>PLATFORM MEDIA </strong>y qu&eacute; hace con ellos. En
                esta l&iacute;nea, la presente Pol&iacute;tica de Privacidad
                explica, entre otras cuestiones:
              </p>
              <ul>
                <li>
                  qu&eacute; informaci&oacute;n recopila{' '}
                  <strong>PLATFORM MEDIA </strong>y con qu&eacute; finalidades;
                </li>
                <li>
                  qu&eacute; usos realiza <strong>PLATFORM MEDIA </strong>de
                  dicha informaci&oacute;n;
                </li>
                <li>
                  las opciones que ofrece <strong>PLATFORM MEDIA </strong>en
                  relaci&oacute;n con la informaci&oacute;n recopilada.
                </li>
              </ul>
              <h3>
                <strong>1. IDENTIFICACI&Oacute;N DEL RESPONSABLE</strong>
              </h3>
              <p>
                <strong>PLATFORM MEDIA LAB, SA</strong>, en su condici&oacute;n
                de responsable del tratamiento, se toma muy en serio la
                privacidad de los usuarios y se compromete a realizar los
                m&aacute;ximos esfuerzos a su alcance para respetarla.
              </p>
              <p>
                Los datos de identificaci&oacute;n de{' '}
                <strong>PLATFORM MEDIA </strong>son:
              </p>
              <p>
                Identidad:{' '}
                <strong>PLATFORM MEDIA LAB, SA - CHE: 426.769.305</strong>
                <br />
                Direcci&oacute;n postal:{' '}
                <strong>Route de Crassier 7, 1262 Eysins, Suiza.</strong>
                <br />
                Tel&eacute;fono: <strong>+41225951926 </strong> - Correo
                electr&oacute;nico:{' '}
                <strong>
                  <a href="mailto:info@oxygen.education">
                    info@oxygen.education
                  </a>
                  .
                </strong>
              </p>
              <h3>
                <strong>2. DATOS PERSONALES QUE TRATAMOS</strong>
              </h3>
              <p>
                <strong>PLATFORM MEDIA </strong>recopila datos personales de las
                siguientes maneras:
              </p>
              <ul>
                <li>
                  <strong>
                    Datos personales que el usuario nos proporciona
                  </strong>
                  <strong>: </strong>cuando rellena cualquiera de los
                  formularios a su disposici&oacute;n en la p&aacute;gina web o
                  cualquier formulario f&iacute;sico ya sea de
                  inscripci&oacute;n o de solicitud de informaci&oacute;n, o
                  cuando se registra y/o accede a la plataforma o cuando
                  contrata cualquier curso de formaci&oacute;n o servicio
                  ofrecido por <strong>PLATFORM MEDIA. </strong>
                </li>
                <li>
                  <strong>
                    A trav&eacute;s de las Cookies y tecnolog&iacute;as
                    similares:{' '}
                  </strong>
                  cuando visita el sitio web <strong>PLATFORM MEDIA </strong>
                  utiliza diversas tecnolog&iacute;as para recopilar y almacenar
                  informaci&oacute;n y esto puede incluir el uso de cookies o
                  tecnolog&iacute;as similares para identificar su navegador o
                  dispositivo. <strong>PLATFORM MEDIA </strong>tambi&eacute;n
                  utiliza estas tecnolog&iacute;as para recopilar y almacenar
                  informaci&oacute;n cuando el usuario interacciona con
                  servicios ofrecidos por <strong>PLATFORM MEDIA</strong>, u
                  otras funciones de <em>Google </em>que puedan aparecer en
                  otros sitios.
                </li>
                <li>
                  <strong>
                    Información que recogemos de las redes sociales y otras
                    plataformas de contenido:
                  </strong>{' '}
                  si usted accede a los Servicios a través de una conexión o
                  inicio de sesión de terceros (por ejemplo, a través de una red
                  social como Facebook o Twitter), puede permitirnos tener
                  acceso y almacenar cierta información de su perfil de la red
                  social. Esto puede incluir su nombre, sexo, foto de perfil,
                  sus "me gusta", sus check-ins y su lista de amigos,
                  dependiendo de su configuración en dichos servicios. Si no
                  desea que se comparta esta información, no utilice una
                  conexión de red social para acceder a los Servicios. Para
                  obtener una descripción de cómo los sitios de redes sociales
                  manejan su información, consulte sus políticas de privacidad y
                  condiciones de uso, que pueden permitirle modificar su
                  configuración de privacidad. También puede tener la opción de
                  publicar sus actividades en los Servicios de redes sociales
                  cuando acceda a contenidos a través de los Servicios (por
                  ejemplo, puede publicar en Facebook que se ha inscrito en una
                  clase en el Servicio); reconoce que, si decide utilizar esta
                  función, sus amigos, seguidores y suscriptores en cualquier
                  Servicio de redes sociales que haya habilitado podrán ver
                  dicha actividad.
                </li>
                <li>
                  <strong>
                    Información que recogemos resultante de campañas
                    publicitarias:
                  </strong>{' '}
                  si usted llena un formulario que forma parte de nuestras
                  campañas publicitarias en redes sociales o en otras
                  plataformas, podemos recoger y almacenar información como su
                  nombre, correo electrónico, teléfono y cualquier otra
                  información que proporcione en el formulario. Esta información
                  será utilizada tanto por nuestros asesores como por los
                  asesores de nuestros socios académicos relacionados con
                  ciertos cursos, con el fin de contactarle y responder
                  cualquier pregunta que tenga acerca de nuestros cursos o
                  servicios.
                </li>
              </ul>
              <p>
                Puede obtener m&aacute;s informaci&oacute;n sobre el tratamiento
                que <strong>PLATFORM MEDIA </strong>realiza de los datos
                personales obtenidos a trav&eacute;s de cookies en la
                "Pol&iacute;tica de Cookies".
              </p>
              <p>
                Dichos datos personales se pueden agrupar de forma general en
                las categor&iacute;as siguientes:
              </p>
              <ul>
                <li>
                  <strong>Datos identificativos: </strong>nombre; apellidos;
                  DNI; tel&eacute;fono; direcci&oacute;n de correo
                  electr&oacute;nico; fecha de nacimiento (opcional);
                  g&eacute;nero.
                </li>
                <li>
                  <strong>Datos bancarios: </strong>para la domiciliaci&oacute;n
                  de pagos. Los pagos se procesan por stripe, por lo que se
                  recaban los siguientes datos: nombre; apellidos; e-mail;
                  n&uacute;mero de identidad o pasaporte; pa&iacute;s de
                  emisi&oacute;n del documento de identidad; fecha de
                  vencimiento del documento de identidad; direcci&oacute;n
                  completa.
                </li>
                <li>
                  <strong>
                    Datos para la elaboraci&oacute;n de perfiles:{' '}
                  </strong>
                  intereses, aficiones.
                </li>
              </ul>
              <h3>
                <strong>3. FINALIDADES DE TRATAMIENTO</strong>
              </h3>
              <p>
                Principalmente los datos que se recaban en los diferentes
                formularios de este Sitio Web ser&aacute;n utilizados, con
                car&aacute;cter general, para las siguientes finalidades que a
                continuaci&oacute;n se detallan vinculados con los mismos:
              </p>
              <ul>
                <li>
                  Atender las solicitudes de informaci&oacute;n y/o consultas
                  efectuadas por el usuario, a trav&eacute;s de los formularios
                  de contacto del Sitio Web puestos a su disposici&oacute;n.
                </li>
                <li>
                  Formalizaci&oacute;n, ejecuci&oacute;n y desarrollo de los
                  cursos formativos online que ofrece{' '}
                  <strong>PLATFORM MEDIA </strong>y, de los que previamente se
                  ha inscrito el usuario.
                </li>
                <li>
                  Para acciones comerciales y/o publicitarias sobre cursos o
                  servicios que puedan ser de su inter&eacute;s. Esta
                  aceptaci&oacute;n siempre tendr&aacute; car&aacute;cter
                  revocable (asociado a su ejercicio de oposici&oacute;n o
                  limitaci&oacute;n de finalidades de tratamiento).
                </li>
              </ul>
              <h3>
                <strong>4. CONFIDENCIALIDAD</strong>
              </h3>
              <p>
                El responsable del Sitio Web, <strong>PLATFORM MEDIA, </strong>
                garantiza la confidencialidad y seguridad de los datos recogidos
                cuando &eacute;stos son objeto de tratamiento, en la medida que
                tiene implantadas las pol&iacute;ticas para el tratamiento y
                medidas de seguridad (t&eacute;cnicas y organizativas) a las que
                se refiere el art&iacute;culo 32 del Reglamento (UE) 2016/679.
                Las pol&iacute;ticas para el tratamiento y medidas de seguridad
                implantadas en entorno inform&aacute;tico del responsable tienen
                por objeto evitar la alteraci&oacute;n, p&eacute;rdida,
                tratamiento o uso no autorizado de sus datos de car&aacute;cter
                personal.
              </p>
              <p>
                Se garantiza finalidad de uso de los datos referidos a las
                consultas de los usuarios, tanto por parte del responsable como
                por aquellas entidades que intervengan de acuerdo con la
                finalidad y objeto del tratamiento.
              </p>
              <h3>
                <strong>5. EJERCICIO DE LOS DERECHOS DIGITALES</strong>
              </h3>
              <p>
                El usuario podr&aacute;, en todo momento, ejercitar sus derechos
                reconocidos en el Reglamento General de Protecci&oacute;n de
                Datos (Reglamento (UE) 2016/679), haciendo la
                comunicaci&oacute;n pertinente a{' '}
                <strong>PLATFORM MEDIA </strong>en los t&eacute;rminos que la
                normativa aplicable establece mediante escrito dirigido a{' '}
                <strong>PLATFORM MEDIA LAB, SA, </strong>adjuntando la fotocopia
                del DNI, a la siguiente direcci&oacute;n: Route de Crassier 7,
                1262 Eysins, Suiza. Tambi&eacute;n podr&aacute; comunicarse
                mediante correo electr&oacute;nico a{' '}
                <a href="mailto:dataprotection@oxygen.education">
                  dataprotection@oxygen.education
                </a>
              </p>
              <p>
                Con objeto de facilitar los ejercicios de DERECHO DIGITALES que
                la Ley le confiere en calidad de titular de datos,{' '}
                <strong>PLATFORM MEDIA </strong>pone a su disposici&oacute;n{' '}
                <strong>un formulario </strong>para tal efecto
                (solic&iacute;telo a{' '}
                <a href="mailto:dataprotection@oxygen.education">
                  dataprotection@oxygen.education
                </a>
                )
              </p>
              <p>
                Su legislación local (por ejemplo, en la UE) puede permitirle
                solicitar que:
              </p>
              <ul>
                <li>eliminemos determinados datos que tenemos sobre usted.</li>
                <li>
                  limitemos el tratamiento y la divulgación de algunos de sus
                  datos.
                </li>
                <li>
                  transfiramos su información a un tercer proveedor de
                  servicios.
                </li>
                <li>
                  revoquemos su consentimiento para el tratamiento de sus datos
                </li>
                <li>
                  proporcionemos acceso y/o una copia de determinados datos que
                  tenemos sobre usted.
                </li>
                <li>
                  impidamos el tratamiento de sus datos con fines de marketing
                  directo (incluido cualquier tratamiento de marketing directo
                  basado en la elaboración de perfiles).
                </li>
                <li>actualicemos los datos obsoletos o incorrectos.</li>
              </ul>
              <h3>
                <strong>6. DELIMITACI&Oacute;N DE RESPONSABILIDAD</strong>
              </h3>
              <p>
                <strong>PLATFORM MEDIA </strong>asume la responsabilidad en
                cuanto a tratamiento que de los datos pueda hacerse desde el
                presente dominio{' '}
                <a href="https://oxygen.education/">oxygen.education</a>.
              </p>
              <p>
                Por tanto, <strong>PLATFORM MEDIA </strong>responder&aacute; de
                forma individual e independiente por el tratamiento y uso de los
                datos, como por la gesti&oacute;n de las solicitudes de Derechos
                Digitales que puedan presentarse a cada una de ellas por los
                usuarios que se hayan relacionado directamente con el presente
                Sitio Web (
                <a href="https://oxygen.education/">oxygen.education</a>),{' '}
                <strong>PLATFORM MEDIA </strong>no asume ninguna
                obligaci&oacute;n en relaci&oacute;n con el tratamiento de datos
                que pueda hacerse fuera del dominio y &aacute;mbito
                tecnol&oacute;gico propio.
              </p>
              <h3>
                <strong>7. CALIDAD DE LOS DATOS</strong>
              </h3>
              <p>
                <strong>PLATFORM MEDIA, </strong>como responsable del
                tratamiento, advierte al usuario que, salvo en los casos de
                representaci&oacute;n legalmente constituida y acreditada,
                ning&uacute;n usuario puede utilizar la identidad de otra
                persona y comunicar sus datos personales, por lo que el usuario
                en todo momento deber&aacute; tener en cuenta que, si utiliza el
                correo electr&oacute;nico, solo puede incluir datos personales
                correspondientes a su propia identidad y que sean adecuados,
                pertinentes, actuales, exactos y verdaderos.
              </p>
              <p>
                A tales efectos, el usuario ser&aacute; el &uacute;nico
                responsable frente a cualquier da&ntilde;o, directo y/o
                indirecto que cause a terceros o a{' '}
                <strong>PLATFORM MEDIA </strong>por el uso de datos personales
                de otra persona, o sus propios datos personales cuando sean
                falsos, err&oacute;neos, no actuales, inadecuados o
                impertinentes y no cuente con el consentimiento.
              </p>
              <p>
                As&iacute; mismo, el usuario que comunique los datos personales
                de un tercero responder&aacute; ante &eacute;ste de la
                obligaci&oacute;n de informaci&oacute;n establecida en el nuevo
                marco normativo para cuando los datos de car&aacute;cter
                personal no hayan sido recabados del propio interesado y/o de
                las consecuencias de no haberle informado.
              </p>
              <p>
                En aquellos supuestos en que el usuario decida voluntariamente
                facilitar a <strong>PLATFORM MEDIA </strong>datos personales de
                terceras personas (<em>por ejemplo</em>; su correo
                electr&oacute;nico), el usuario se compromete a informar
                previamente a estas personas de la intenci&oacute;n de comunicar
                sus datos a <strong>PLATFORM MEDIA </strong>y a obtener su
                consentimiento libre, expreso, espec&iacute;fico e
                inequ&iacute;voco para dicho tratamiento de datos, siendo
                responsable el usuario en caso contrario.
              </p>
              <h3>
                <strong>8. MANTENIMIENTO DE LOS DATOS</strong>
              </h3>
              <p>
                El nuevo marco normativo obliga a las empresas que recogemos
                datos a establecer e informar el tiempo que se mantendr&aacute;n
                los datos.
              </p>
              <p>
                Los datos asociados al formulario de contacto se
                mantendr&aacute;n, s&oacute;lo los de contacto (email e
                identificativos), por la relaci&oacute;n existente de la
                solicitud, hasta que medie comunicaci&oacute;n por su parte
                asociada al ejercicio de derechos de cancelaci&oacute;n,
                oposici&oacute;n o limitaci&oacute;n de las comunicaciones.
              </p>
              <p>
                Los datos asociados al pago por la contrataci&oacute;n de un
                curso formativo online u otro servicio ofrecido por{' '}
                <strong>PLATFORM MEDIA, </strong>ser&aacute;n mantenidos para
                dar alcance a la relaci&oacute;n contractual o cualquier aspecto
                derivado de la misma.
              </p>
              <h3>
                <strong>9. SEGURIDAD Y ACCESO</strong>
              </h3>
              <p>
                De conformidad con el Reglamento (UE) 2016/679,{' '}
                <strong>PLATFORM MEDIA </strong>aplica medidas de seguridad
                t&eacute;cnicas, administrativas y organizativas para proteger
                los datos que recogemos frente a la destrucci&oacute;n
                accidental o il&iacute;cita y la p&eacute;rdida,
                alteraci&oacute;n, divulgaci&oacute;n o acceso no autorizados,
                en especial cuando el tratamiento implique la transmisi&oacute;n
                de datos en una red, y frente a cualquier otra forma
                il&iacute;cita de tratamiento.
              </p>
              <p>
                Con car&aacute;cter general, para acceder y navegar por los
                contenidos del Sitio Web no es necesario el previo registro del
                usuario. No obstante, la utilizaci&oacute;n de determinados
                servicios y contenidos del Sitio Web puede estar condicionada al
                previo registro del usuario. En tal caso, los datos introducidos
                por el usuario deber&aacute;n ser exactos y actuales.
              </p>
              <p>
                El usuario registrado ser&aacute; responsable en todo momento de
                los datos facilitados a trav&eacute;s del Sitio Web. A estos
                efectos, la cumplimentaci&oacute;n de los datos se
                reputar&aacute; realizada por dicho usuario, quien
                responder&aacute; en todo caso de los datos introducidos. El
                usuario ser&aacute; responsable de cualquier dato e
                informaci&oacute;n falso o inexacto facilitado a{' '}
                <strong>PLATFORM MEDIA. </strong>
              </p>
              <p>
                Asimismo, el usuario registrado ser&aacute; responsable en todo
                momento de la custodia sus claves de acceso (usuario y
                contrase&ntilde;a), asumiendo en consecuencia de los
                da&ntilde;os y perjuicios que pudieran derivarse del uso
                indebido de sus claves de acceso, as&iacute; como la
                cesi&oacute;n, revelaci&oacute;n o extrav&iacute;o de estas.
              </p>
              <p>
                En el supuesto de que el usuario sospeche que sus claves de
                acceso est&aacute;n siendo utilizadas por terceros, sin su
                consentimiento previo, &eacute;ste deber&aacute; notificar dicha
                sospecha a <strong>PLATFORM MEDIA </strong>a la mayor brevedad a
                trav&eacute;s de las siguientes direcciones de correo
                electr&oacute;nico:{' '}
                <a href="mailto:info@oxygen.education">info@oxygen.education</a>{' '}
                y{' '}
                <a href="mailto:dataprotecion@oxygen.education">
                  dataprotecion@oxygen.education
                </a>
              </p>
              <h3>
                <strong>
                  10. CAMBIOS O MODIFICACIONES DE NUESTRA POL&Iacute;TICA DE
                  PRIVACIDAD
                </strong>
              </h3>
              <p>
                Podremos revisar esta Pol&iacute;tica de privacidad
                oportunamente y publicar la versi&oacute;n m&aacute;s
                actualizada en nuestro Sitio Web. Te informaremos en caso de que
                tus derechos se vean apreciablemente afectados como consecuencia
                de una revisi&oacute;n.
              </p>
            </Grid>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyES;
