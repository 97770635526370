/* eslint-disable no-undef */
import axios from 'axios';
import Cookies from 'js-cookie';
import { config } from '../../config/config';

const commonParams =
  Cookies.get('utm_params') && JSON.parse(Cookies.get('utm_params'));
const referrerParams =
  Cookies.get('referrer') && JSON.parse(Cookies.get('referrer'));

const commonVariables = {
  withCredentials: true,
  headers: { 'Content-Type': 'multipart/form-data' },
};

const formatFormData = (values) => {
  const formData = new FormData();
  Object.entries(values).map(([key, value]) => formData.append(key, value));
  return formData;
};

export const sendMarketingEmail = async (values) => {
  const formData = formatFormData({
    ...values,
    ...commonParams,
    ...referrerParams,
  });
  return axios({
    method: 'post',
    url: `${config.API_DOMAIN}/oxygen/send_marketing_email/`,
    data: formData,
    ...commonVariables,
  });
};

export const reSendMarketingEmail = async (values) => {
  const formData = formatFormData({
    ...values,
    ...commonParams,
    ...referrerParams,
  });
  return axios({
    method: 'post',
    url: `${config.API_DOMAIN}/oxygen/resend_marketing_email/`,
    data: formData,
    ...commonVariables,
  });
};

export const reSendActivationEmail = async () => {
  const formData = formatFormData({ ...commonParams, ...referrerParams });
  return axios({
    method: 'get',
    url: `${config.API_DOMAIN}/oxygen/resend_activation_email`,
    data: formData,
    ...commonVariables,
  });
};

export const confirmMarketingEmail = async (values) => {
  const formData = formatFormData({
    ...values,
    ...commonParams,
    ...referrerParams,
  });
  return axios({
    method: 'post',
    url: `${config.API_DOMAIN}/oxygen/confirm_marketing_email/`,
    data: formData,
    ...commonVariables,
  });
};

export default {
  sendMarketingEmail,
  reSendMarketingEmail,
  confirmMarketingEmail,
};
