/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom';

import Cookies from 'js-cookie';
import withContext from '@s-ui/hoc/lib/withContext';
import * as Sentry from '@sentry/browser';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { Provider } from 'react-redux';
import configureStore from './redux/store';
import * as serviceWorker from './serviceWorker';

import i18n from './languages';
import App from './App';

import { config } from './config/config';

const vh = window.innerHeight * 0.01;

document.documentElement.style.setProperty('--vh', `${vh}px`);
document.documentElement.style.setProperty('--vh-nohead', `${vh - 0.88}px`);
document.documentElement.style.setProperty('--vhn', `-${vh}px`);

const navigatorLang = (
  window.navigator.language || navigator.browserLanguage
).substring(0, 2);
const cookieAndNavigatorLang =
  (Cookies.get('openedx-language-preference') &&
    Cookies.get('openedx-language-preference').substring(0, 2)) ||
  ((navigatorLang === 'es' || navigatorLang === 'en') && navigatorLang) ||
  'en';

const AppWithContext = withContext({ i18n })(App);
i18n.culture = cookieAndNavigatorLang;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#52e0ff',
    },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiContainer: {
      // Name of the rule
      maxWidthXl: {
        // Some CSS
        maxWidth: '1440px !important',
        paddingRight: 0,
        paddingLeft: 0,
      },
    },
    MuiInputLabel: {
      outlined: {
        color: '#343434',
      },
    },
  },
});

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn:
      'https://69c814f63262496a8a97ee75bcc92e8a@o1191481.ingest.sentry.io/6313419',
    environment: config.ENVIRONMENT,
  });
}

ReactDOM.render(
  <Provider store={configureStore()}>
    <MuiThemeProvider theme={theme}>
      <AppWithContext />
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
