import { toast } from 'react-toastify';

const getOtherSettings = (other_settings) => {
  try {
    return other_settings
      ? JSON.parse(
          other_settings.replaceAll("u'", "'").replace(/'/g, '"'),
          (k, v) =>
            v &&
            (v.toString().toLowerCase() === 'true'
              ? true
              : v.toString().toLowerCase() === 'false'
              ? false
              : v),
        )
      : {};
  } catch (e) {
    if (process.env.NODE_ENV === 'development') {
      toast.error(`Error: ${other_settings}`, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return {};
  }
};

export default getOtherSettings;
