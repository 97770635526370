const english = {
  BRAND_NAME: 'Oxygen',
  BRAND_TITLE: 'Advanced Sports Education',
  BRAND_DESCRIPTION:
    'The global standard for online sports education, powered by the wisdom of our mentors, cutting-edge technology and our unique e-learning design.',

  CREATE_ACCOUNT: "Let's go",
  CONTINUE_COURSES: 'Continue your programs',
  CONTINUE: 'Continue',
  CLOSE: 'Close',
  LEARN_MORE: 'Learn more',
  STARTING_DATES: 'AVAILABLE STARTING DATES',
  STARTING_DATES_DISCLAIMER:
    'Webinars and other live content may be subject to change. For more detailed information, please contact our support team.',

  EXPLORE: 'Explore',
  COMMUNITY: 'Community Hub',
  LOGIN: 'Log in',
  REGISTER: 'Sign up',
  MENU: 'Menu',

  CONTINUE_LAST_COURSE: 'Resume your last program',
  MY_PROFILE: 'Profile',
  ACCOUNT_SETUP: 'Settings',
  LOGOUT: 'Logout',

  IN_PARTNERSHIP: 'IN PARTNERSHIP WITH',

  US: 'About Us',
  METHODOLOGY: 'Methodology',
  METHODOLOGY_CROP: 'Metho-dology',
  COMMUNITY_HUB: 'Community Hub',
  HELP_CENTER: 'Help Center',

  FUNDATION: 'Fundation',
  PARTNERS: 'Partners',
  MENTORS: 'Mentors',
  BILLBOARD: 'Billboard',
  BACKYARD: 'The Backyard',
  CLASS: 'Class',
  HELP: 'Help Center',
  CAREER: 'Degree',
  CAREERS: 'Degrees',
  COURSE: 'Program',
  COURSES: 'Programs',
  PROGRAMS: 'Programs',
  TIPS: 'Tips',
  CONTACT: 'Help Center',
  TERMS_AND_CONDITIONS: 'Terms and Conditions',
  PRIVACY_POLICY: 'Privacy Policy',
  COOKIES_POLICY: 'Cookies Policy',
  LEGAL_NOTICE: 'Legal Notice',

  COPYRIGHT: 'All rights reserved',
  FAQS: 'FAQS',
  SUBSCRIPTION: 'Subscription',
  ENTER_YOUR_EMAIL: 'Enter your E-Mail',
  MORE: 'More',
  FEATURED_CONTENT: 'Featured Content',
  SUBMIT: 'Submit',
  COMING_SOON: 'Coming Soon',
  COMING_SOON_LOWER_CASE: 'Coming Soon',

  SHARE_COURSE_PROGRAM: 'Share Program',
  SHARE_COURSE_PROGRAM_TITLE: 'Tell your friends about this program',

  EXPLORE_ALL: 'Explore All',
  ENTER: 'Enter',
  WHAT_YOU_WANT_LEARN: 'What do you want to learn?',

  VIEW_PROGRAM: 'View Program',
  GO_BACK: 'Go Back',
  VIEW_ALL_COURSES_AND_CAREERS: 'View all programs and degrees',

  ABOUT: 'About',
  ABOUT_US: 'About Us',
  HEADQUARTERS: 'HQ OFFICES',
  SCHEDULE: 'Program',
  MENTOR: 'Mentor',
  SUBSCRIBE_COURSE: 'Subscribe Program',
  SUBSCRIBE_CAREER: 'Subscribe Degree',

  ACHIEVEMENTS: 'Major Achievements',
  HISTORY: 'My history',

  TAXES_NOT_INCLUDED: 'VAT not included',
  AVAILABLE_CURRENCIES: 'Available in following currencies: ',

  ONE_TIME_PAYMENT: 'One Time Payment (Total Fee)',
  TIME_100_ONLINE: '100% Online',
  START_NOW_AND_LEARN: 'Desktop version.',
  START: 'Start',
  TO_COMPLETE: 'to complete',
  MONTHS: 'Months',
  YEARS: 'Years',
  SUGGESTED_TIME: 'Suggested 8 hours / week',
  TEACHING_TEAM: 'Teaching team',
  LANGUAGE: 'Language',
  REQUIREMENTS: 'Requirements',
  REQUIREMENTS_DESCRIPTION: 'Internet Conection, Headphones',
  INCLUDES: 'Includes',
  STUDY_PROGRAM: 'Study<br />Program',
  MORE_COURSES: 'More interesting programs',
  MENTOR_OF_THIS_COURSE: 'Mentor of this program',

  DOWNLOAD_SCHEDULE: 'Get Program',
  OPEN_INSCRIPTION: 'OPEN REGISTRATION',
  BUY_INSCRIPTION: "Let's go",

  SPANISH: 'Spanish',
  ENGLISH: 'English',
  PORTUGUESE: 'Portuguese',

  FROM: 'From',
  TO: 'to',
  THANKS_SIGNING_UP: 'Thanks for signing up!',
  COURSE_STARTS: 'Program Starts',
  DAYS: 'Days',
  HOURS: 'Hours',
  MINUTES: 'Minutes',
  YES: 'Yes',
  No: 'No',

  MESSAGE_FOR_VALDANO: 'Message for Jorge Valdano',
  CREATE_ACCOUNT_BANNER: 'CREATE YOUR ACCOUNT TO BECOME PART OF OUR COMMUNITY!',
  VERIFY_EMAIL_BANNER: 'PLEASE VERIFY YOUR EMAIL TO COMPLETE YOUR REGISTRATION',

  MODAL_WELCOME_USER_TITLE: "You're now part of the Oxygen community.",
  MODAL_WELCOME_USER_DESCRIPTION:
    'Welcome! Stay connected to recieve our latest news and updates!',
  MODAL_WELCOME_USER_BUTTON: 'Start exploring',

  MODAL_EARLY_BID_TITLE: 'Get your 10% Discount NOW!',
  MODAL_EARLY_BID_DESCRIPTION:
    'Create your account and receive your “EARLY BIRD“ Discount Coupon. REGISTRATIONS ARE OPEN!',
  MODAL_EARLY_BID_DISCLAIMER:
    'EARLY BIRD Discount valid until the 1st of April 2022!',
  MODAL_EARLY_BID_OK: 'Get Discount',

  MODAL_GET_PROGRAM_TITLE: 'Get the full program.',
  MODAL_GET_PROGRAM_DESCRIPTION:
    'Enter your email below to get access to all our academic offers.',
  MODAL_GET_PROGRAM_DISCLAIMER:
    'By submitting this form, I agree that my information will be used to contact me in the context of my request and the commercial relationship that may result. I confirm that I have also read the ',

  MODAL_ENTER_EMAIL_TITLE: "You're almost ready to recieve reminders!",
  MODAL_ENTER_EMAIL_DESCRIPTION:
    'Enter your full name and email below to be notified.',

  MODAL_ENTER_EMAIL_REMINDME_TITLE: 'Check your Email',
  MODAL_ENTER_EMAIL_REMINDME_THANKS: 'We will send you a reminder email.',

  REMIND_ME: 'Remind me',
  REMIND_ME_WHEN: 'When registration opens',

  MODAL_ENTER_EMAIL_SEND: 'Remind Me',
  MODAL_ENTER_EMAIL_THANKS:
    'Thank you! Please check your inbox to verify your email.',
  BUTTON_OK: 'Ok',
  MODAL_ENTER_EMAIL_SEND_OK: 'Send',

  MODAL_LANGUAGE_AVAILABLE_TITLE: 'This program is only available in Spanish.',
  MODAL_LANGUAGE_AVAILABLE_DESCRIPTION:
    'Would you like to proceed with the purchase? ',
  MODAL_LANGUAGE_AVAILABLE_THANKS:
    'Would you like Oxygen to offer an English version of this program ?',
  MODAL_LANGUAGE_AVAILABLE_THANKS_TITLE: 'Thank you.',

  MODAL_ENTER_EMAIL_AGAIN_NOT_VERIFIED_REMINDME_TITLE: 'CHECK YOUR INBOX',
  MODAL_ENTER_EMAIL_AGAIN_NOT_VERIFIED_REMINDME_DESCRIPTION:
    'Click the link we sent to {email} to complete your account setup.',

  MODAL_ENTER_EMAIL_AND_VERIFIED_REMINDME_TITLE: "We'll keep you posted!",
  MODAL_ENTER_EMAIL_AND_VERIFIED_REMINDME_DESCRIPTION:
    "We'll let you know when this program is available. ",

  MODAL_COMPLETE_REGISTRATION_TITLE: 'CHECK YOUR INBOX',
  MODAL_COMPLETE_REGISTRATION_DESCRIPTION: `Click the link we sent to {email} to complete your account setup.`,
  MODAL_COMPLETE_DIDNT_RECEIVE: "I didn't recieve the email: ",
  SEND_AGAIN: 'Send again',

  EMAIL_VERIFICATION_TITLE: 'Email verification',
  EMAIL_VERIFICATION_DESCRIPTION:
    'Please enter your email address so we can verify it is you!',
  EMAIL_VERIFICATION_THANKS_DESCRIPTION:
    'Thank you! Please check your inbox to verify your email address.',

  MODAL_GET_PROGRAM_VERIFY_TITLE: 'You’re almost there!',
  MODAL_GET_PROGRAM_VERIFY_THANKS:
    'Thank you, please verify your email address to continue.',
  MANDATORY_FIELD: 'Mandatory Field',

  EMAIL_INVALID: 'Invalid Email. Please enter again.',
  FIELDS_INCOMPLETED: 'All * fields are required',

  COFOUNDERS: 'Co-Founders',
  ABOUT_US_CONTENT:
    'We are people who come from different industries, but we all have something in common: We are sports lovers and we believe that the democratization of it can make a better world.',
  LETS_TALK: "Let's Talk!",
  PARTNER_INTERESTED:
    'Are you interested in partnering with Oxygen? We are looking forward to hearing from you!',

  PARTNERSHIP_WITH_OXYGEN: 'Partnership with Oxygen',
  MEDIA: 'Media',
  UNIVERSITY: 'University',
  TECH: 'Tech',
  SPORT: 'Sport',

  PAGE_DEFAULT_TITLE: 'Oxygen - Advanced Sports Education',
  PAGE_DEFAULT_DESCRIPTION:
    'The global standard for online sports education, powered by the wisdom of our mentors, cutting-edge technology and our unique e-learning design',

  PAGE_LIST_TITLE: '',
  PAGE_LIST_DESCRIPTION: 'All Oxygen programs and degrees',

  PAGE_ABOUT_TITLE: 'About us',
  PAGE_ABOUT_DESCRIPTION: "Oxygen's Co-Founders. Contact us. Talk to us.",

  PAGE_ABOUT_DETAIL_TITLE: "The history of Jorge Valdano, Oxygen's Co-Founder",
  PAGE_ABOUT_DETAIL_DESCRIPTION:
    'Our Co-Founder, express his values and feelings about sports education, pro athletes, e-learning, sports industry and much more.',

  PAGE_TERMS_TITLE: 'Terms and conditions',
  PAGE_TERMS_DESCRIPTION: 'General Terms and Conditions',

  PAGE_PRIVA_TITLE: 'Privacy Policy',
  PAGE_PRIVA_DESCRIPTION: 'Data Protection Policy',

  NAME: 'Name',
  LAST_NAME: 'Last Name',

  AVAILABLE_DAYS_RULES:
    'The available dates are calculated according to the UTC time zone',

  BUYABLE_COURSE: 'OPEN PRE-REGISTRATION / ',
};

export default english;
