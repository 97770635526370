/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import EnterEmailModal from '../../Modals/EnterEmail';

import menuType from '../../../../types/menuType';

import './index.scss';

const MenuList = ({
  menu,
  level,
  onClickOption,
  onBackOption,
  handleOpenModal,
}) => {
  const normalize = (str) => str.replace(/\s/g, '-').toLowerCase();

  const renderMenu = (menu) =>
    menu.map((item, index) => {
      let url = '';

      if (item.language && item.language.code && item.language.code !== 'en') {
        url = `/${item.language.code}/${item.type}/${item.id}`;
      } else {
        url = `/${item.type}/${item.id}`;
      }

      if (item.title) {
        if (item.empty) {
          return (
            <li
              onClick={(event) => {
                event.preventDefault();
                handleOpenModal(true);
              }}
              key={normalize(item.title)}
            >
              {item.title} <div className="icon-right" />
            </li>
          );
        }

        return (
          <li onClick={() => onClickOption(index)} key={normalize(item.title)}>
            {item.title} <div className="icon-right" />
          </li>
        );
      }

      return item.status === 'remind_me' || item.status === 'pending' ? (
        <li
          key={normalize(item.name)}
          className="no-link"
          onClick={(event) => {
            event.preventDefault();
            handleOpenModal(true);
          }}
        >
          {item.name}
        </li>
      ) : (
        <li key={normalize(item.name)}>
          <a href={url}>{item.name}</a>
        </li>
      );
    });

  return (
    <ul className="dropdown-menu dropdown-menu-mobile">
      {renderMenu(menu)}
      {level.length !== 0 && (
        <li onClick={() => onBackOption()} key="back" className="back">
          Back
        </li>
      )}
    </ul>
  );
};

const DropdownMenuMobile = ({ menu }) => {
  const [openModal, setOpenModal] = useState(false);
  const [currentMenu, setCurrentMenu] = useState(menu);
  const [level, setLevel] = useState([]);

  useEffect(() => {
    if (level.length === 0) {
      setCurrentMenu(menu);
    }

    if (level.length === 1) {
      setCurrentMenu(menu[level[0]].submenu);
    }

    if (level.length === 2) {
      setCurrentMenu(menu[level[0]].submenu[level[1]].submenu);
    }

    if (level.length === 3) {
      setCurrentMenu(menu[level[0]].submenu[level[1]].submenu[level[2]].items);
    }
  }, [level, menu]);

  const clickOnMenu = (index) => {
    setLevel([...level, index]);
  };

  const clickBackMenu = () => {
    setLevel([]);
  };

  return (
    <>
      <MenuList
        menu={currentMenu}
        level={level}
        onClickOption={clickOnMenu}
        onBackOption={clickBackMenu}
        handleOpenModal={setOpenModal}
      />

      <EnterEmailModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
      />
    </>
  );
};

DropdownMenuMobile.propTypes = {
  menu: menuType,
  overMenu: PropTypes.func,
  submenu: PropTypes.bool,
};

DropdownMenuMobile.defaultProps = {
  menu: [],
  overMenu: () => {},
  submenu: false,
};

export default DropdownMenuMobile;
