import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import Grid from '@material-ui/core/Grid';

import i18n from '../../../../languages';

import './index.scss';

const CourseStartCountdown = ({ start }) => {
  const distance = new Date(start).getTime() - new Date().getTime();

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

  const countdown = { days, hours, minutes };

  const dateFormat = i18n.culture === 'es' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';

  return (
    distance > 0 && (
      <div className="course-start-countdown">
        <Grid container>
          <Grid item xs={8}>
            <small>{i18n.t('THANKS_SIGNING_UP')}</small>
            <h3>{i18n.t('COURSE_STARTS')}:</h3>
          </Grid>
          <Grid item xs={4} className="date-container">
            <small className="date">
              {moment(start).format(dateFormat).toUpperCase()}
            </small>
          </Grid>
        </Grid>
        <Grid container className="counter">
          <Grid item xs={4}>
            <h3>{countdown.days}</h3>
            <small>{i18n.t('DAYS')}</small>
          </Grid>
          <Grid item xs={4}>
            <h3>{countdown.hours}</h3>
            <small>{i18n.t('HOURS')}</small>
          </Grid>
          <Grid item xs={4}>
            <h3>{countdown.minutes}</h3>
            <small>{i18n.t('MINUTES')}</small>
          </Grid>
        </Grid>
      </div>
    )
  );
};

CourseStartCountdown.propTypes = {
  overview: PropTypes.string,
};

CourseStartCountdown.defaultProps = {
  overview: '',
};

export default CourseStartCountdown;
