import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import './index.scss';

const Loader = (props) => (
  <div className={classnames('loader', props.className)}>
    <div className="lds-ripple">
      <div />
      <div />
    </div>
  </div>
);

Loader.propTypes = {
  className: PropTypes.string,
};

Loader.defaultProps = {
  className: '',
};

export default Loader;
