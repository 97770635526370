import api from '../../api';
import {
  GET_SEARCH_REQUEST,
  GET_SEARCH_SUCCESS,
  GET_SEARCH_ERROR,
} from '../constants';

export const getSearch = (value) => (dispatch) => {
  dispatch({
    type: GET_SEARCH_REQUEST,
    meta: api.search
      .getSearch(value)
      .then((response) => {
        dispatch({
          type: GET_SEARCH_SUCCESS,
          payload: {
            data: response.data,
          },
        });
      })
      .catch((error) =>
        dispatch({
          type: GET_SEARCH_ERROR,
          payload: error.response,
        }),
      ),
  });
};

export default getSearch;
