/* eslint-disable no-undef */
import React from 'react';
import lodash from 'lodash';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import i18n from '../../../languages';

import { partnersType } from '../../../types/partnersType';

import './index.scss';

const CourseMentor = ({ partners }) => (
  <div className="course-mentor">
    <Container maxWidth="xl">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        className="grid-container"
        spacing={2}
      >
        {partners.map((partner) => (
          <Grid item sm={12} key={lodash.uniqueId('mentor_')}>
            <div
              style={
                partner.image && { backgroundImage: `url(${partner.image})` }
              }
              className="item item-12"
            >
              <div className="box-content">
                <Grid container className="box-content-container">
                  <Grid item sm={1} />
                  <Grid item xs={12} sm={6} className="mentor-content">
                    <h5>{i18n.t('MENTOR_OF_THIS_COURSE')}</h5>
                    <h2>{partner.fullname}</h2>
                    <h4>
                      {partner.description.split('\n').map((item, key) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <span key={key}>
                          {item}
                          <br />
                        </span>
                      ))}
                    </h4>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </Container>
  </div>
);

CourseMentor.propTypes = {
  partners: partnersType,
};

CourseMentor.defaultProps = {
  partners: [],
};

export default CourseMentor;
