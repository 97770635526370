/* eslint-disable global-require */
/* eslint-disable react/no-danger */
/* eslint-disable no-undef */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import classnames from 'classnames';

import Grid from '@material-ui/core/Grid';

import i18n from '../../../languages';

import './index.scss';

const echoImage = (image) => {
  try {
    return require(`../../../assets/images/content_slider/${image}`).default;
  } catch (e) {
    return false;
  }
};

const ContentSlider = ({ content_slider }) => {
  const componentRef = useRef(null);

  return (
    <>
      <div
        className="content-slider"
        style={{
          backgroundImage: `url(${echoImage(content_slider[0].image)}`,
        }}
        ref={componentRef}
      >
        <a
          className="anchor"
          name="methodology"
          id="methodology"
          href="#methodology"
        >
          methodology
        </a>
        <div className="content-slider-shadow">
          <Grid container justifyContent="center">
            <Grid item xs={11} sm={10}>
              <h2
                dangerouslySetInnerHTML={{
                  __html: i18n
                    .t('METHODOLOGY_CROP')
                    .replace(/(.-)/g, '$1<br />'),
                }}
              />
            </Grid>
          </Grid>
          <div className="content-slider-container">
            <Grid container className="content" spacing={0}>
              <Grid item sm={1} md={1} xs={12} />
              <Grid item sm={10} md={7} xs={12}>
                {content_slider.map((item, index) => (
                  <Grid
                    container
                    key={lodash.uniqueId('slider_')}
                    className={classnames('item')}
                    spacing={0}
                  >
                    <Grid item xs={12} sm={1} md={1}>
                      <h2>0{index + 1}</h2>
                    </Grid>
                    <Grid item xs={12} sm={10} md={9}>
                      <p>{item.content}</p>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

ContentSlider.propTypes = {
  content_slider: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      content: PropTypes.string,
    }),
  ),
};

ContentSlider.defaultProps = {
  content_slider: [],
};

export default ContentSlider;
