import React, { useState } from 'react';

import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';

import i18n from '../../../languages';

import CourseContent from './CourseContent';
import PriceContent from './PriceContent';
import CourseStartCountdown from './CourseStartCountdown';

import ShareModal from '../../Common/Modals/Share';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ButtonMoreIcon from '../../../assets/images/button-more.svg';

import './index.scss';

const CourseAbout = ({ course, user }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <Container maxWidth="lg">
      <Grid container className="course-about-container">
        <Grid item sm={7} md={8}>
          {course.sponsors && course.sponsors.length > 0 && (
            <div className="sponsors-container">
              <Grid container spacing={0}>
                {course.sponsors.map((sponsor) => (
                  <Grid
                    item
                    sm={12}
                    className="sponsors-accordions course-content"
                    key={sponsor.id}
                  >
                    <Accordion>
                      <AccordionSummary
                        expandIcon={
                          <img
                            src={ButtonMoreIcon}
                            alt="Ver más"
                            className="btn-close-open"
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="accordion-item"
                        disabled={
                          !sponsor.description || sponsor.description.length < 5
                        }
                      >
                        PARTNER
                        <img
                          src={sponsor.detail_image}
                          alt={sponsor.fullname}
                        />
                      </AccordionSummary>
                      <AccordionDetails className="content">
                        <p
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: sponsor.description,
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}
              </Grid>
            </div>
          )}
          <CourseContent overview={course.overview} />
        </Grid>
        <Grid item xs={1} />
        <Grid item sm={4} md={3}>
          {user.logged &&
            course.bought &&
            course.runs_dates &&
            course.runs_dates[0] && (
              <CourseStartCountdown start={course.runs_dates[0].start} />
            )}
          <PriceContent {...course} />

          <div
            className="btn-secondary dark share-button"
            onClick={(event) => {
              event.preventDefault();
              setOpenModal(true);
            }}
          >
            {i18n.t('SHARE_COURSE_PROGRAM')}
          </div>
          <ShareModal
            open={openModal}
            handleClose={() => setOpenModal(false)}
            idContent={course.id}
            name={course.name}
            description={course.short_description}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default CourseAbout;
