const NAME_SPACE = 'oxygen';
const NS_USER = 'user';
const NS_COURSE = 'course';
const NS_ENROLLMENT = 'enrollment';
const NS_SEARCH = 'search';
const NS_MENU = 'search';
const NS_EDUCATIONALRESOURCE = 'educationalResource';
const NS_DEGREE = 'degree';
const NS_MARKETING_EMAIL = 'marketingEmail';

export const GET_USER = `${NAME_SPACE}/${NS_USER}/GET_USER`;

export const GET_EDUCATIONALRESOURCES_REQUEST = `${NAME_SPACE}/${NS_EDUCATIONALRESOURCE}/GET_EDUCATIONALRESOURCES_REQUEST`;
export const GET_EDUCATIONALRESOURCES_SUCCESS = `${NAME_SPACE}/${NS_EDUCATIONALRESOURCE}/GET_EDUCATIONALRESOURCES_SUCCESS`;
export const GET_EDUCATIONALRESOURCES_ERROR = `${NAME_SPACE}/${NS_EDUCATIONALRESOURCE}/GET_EDUCATIONALRESOURCES_ERROR`;

export const GET_COURSE_REQUEST = `${NAME_SPACE}/${NS_COURSE}/GET_COURSE_REQUEST`;
export const GET_COURSE_SUCCESS = `${NAME_SPACE}/${NS_COURSE}/GET_COURSE_SUCCESS`;
export const GET_COURSE_ERROR = `${NAME_SPACE}/${NS_COURSE}/GET_COURSE_ERROR`;

export const GET_DEGREE_REQUEST = `${NAME_SPACE}/${NS_DEGREE}/GET_DEGREE_REQUEST`;
export const GET_DEGREE_SUCCESS = `${NAME_SPACE}/${NS_DEGREE}/GET_DEGREE_SUCCESS`;
export const GET_DEGREE_ERROR = `${NAME_SPACE}/${NS_DEGREE}/GET_DEGREE_ERROR`;

export const GET_MENU_REQUEST = `${NAME_SPACE}/${NS_MENU}/GET_MENU_REQUEST`;
export const GET_MENU_SUCCESS = `${NAME_SPACE}/${NS_MENU}/GET_MENU_SUCCESS`;
export const GET_MENU_ERROR = `${NAME_SPACE}/${NS_MENU}/GET_MENU_ERROR`;

export const GET_SEARCH_REQUEST = `${NAME_SPACE}/${NS_SEARCH}/GET_SEARCH_REQUEST`;
export const GET_SEARCH_SUCCESS = `${NAME_SPACE}/${NS_SEARCH}/GET_SEARCH_SUCCESS`;
export const GET_SEARCH_ERROR = `${NAME_SPACE}/${NS_SEARCH}/GET_SEARCH_ERROR`;

export const GET_ENROLLMENTS_REQUEST = `${NAME_SPACE}/${NS_ENROLLMENT}/GET_ENROLLMENTS_REQUEST`;
export const GET_ENROLLMENTS_SUCCESS = `${NAME_SPACE}/${NS_ENROLLMENT}/GET_ENROLLMENTS_SUCCESS`;
export const GET_ENROLLMENTS_ERROR = `${NAME_SPACE}/${NS_ENROLLMENT}/GET_ENROLLMENTS_ERROR`;

export const SEND_MARKETING_EMAIL_REQUEST = `${NAME_SPACE}/${NS_MARKETING_EMAIL}/SEND_MARKETING_EMAIL_REQUEST`;
export const SEND_MARKETING_EMAIL_SUCCESS = `${NAME_SPACE}/${NS_MARKETING_EMAIL}/SEND_MARKETING_EMAIL_UCCESS`;
export const SEND_MARKETING_EMAIL_ERROR = `${NAME_SPACE}/${NS_MARKETING_EMAIL}/SEND_MARKETING_EMAIL_ERROR`;

export default {
  GET_USER,
  GET_EDUCATIONALRESOURCES_REQUEST,
  GET_EDUCATIONALRESOURCES_SUCCESS,
  GET_EDUCATIONALRESOURCES_ERROR,
  GET_COURSE_REQUEST,
  GET_COURSE_SUCCESS,
  GET_COURSE_ERROR,
  GET_DEGREE_REQUEST,
  GET_DEGREE_SUCCESS,
  GET_DEGREE_ERROR,
  GET_MENU_REQUEST,
  GET_MENU_SUCCESS,
  GET_MENU_ERROR,
  GET_SEARCH_REQUEST,
  GET_SEARCH_SUCCESS,
  GET_SEARCH_ERROR,
  GET_ENROLLMENTS_REQUEST,
  GET_ENROLLMENTS_SUCCESS,
  GET_ENROLLMENTS_ERROR,
  SEND_MARKETING_EMAIL_REQUEST,
  SEND_MARKETING_EMAIL_SUCCESS,
  SEND_MARKETING_EMAIL_ERROR,
};
