import { arrayOf, shape, string } from 'prop-types';

import { educationalResourcesType } from './educationalResourceType';

const menuType = arrayOf(
  shape({
    submenu: arrayOf(
      shape({
        submenu: arrayOf(
          shape({
            items: educationalResourcesType,
            title: string,
          }),
        ),
        title: string,
      }),
    ),
    title: string,
  }),
);

export default menuType;
