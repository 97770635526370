import { shape, string } from 'prop-types';

const languageType = {
  language: shape({
    code: string,
    name: string,
  }),
};

export default languageType;
