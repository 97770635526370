/* eslint-disable no-undef */
import Cookies from 'js-cookie';
import lodash from 'lodash';
import { GET_USER } from '../constants';

export const getUser = () => (dispatch) => {
  const cookie = Cookies.get();
  const userInfo =
    cookie && cookie['edx-user-info']
      ? cookie['edx-user-info'] &&
        JSON.parse(
          cookie['edx-user-info'].replace(/\\054/g, ',').replace(/\\/g, ''),
        )
      : {};

  dispatch({
    type: GET_USER,
    payload: {
      data: {
        logged:
          cookie && cookie.edxloggedin && !lodash.isEmpty(cookie)
            ? JSON.parse(cookie.edxloggedin)
            : false,
        ...userInfo,
      },
    },
  });
};

export default { getUser };
