/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-undef */
import React from 'react';
import Cookies from 'js-cookie';
import classnames from 'classnames';
import psl from 'psl';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from 'material-ui-popup-state/HoverMenu';

import {
  usePopupState,
  bindHover,
  bindMenu,
} from 'material-ui-popup-state/hooks';
import { isLocalhost } from '../../../../serviceWorker';
import addLanguageToStaticUrl from '../../../../utils/addLanguageToStaticUrl';

import i18n from '../../../../languages';

import './index.scss';

const LangMenu = ({ otherLangs, page }) => {
  const [option, setOption] = React.useState(null);
  const popupState = usePopupState({ variant: 'popover', popupId: 'langMenu' });

  const handleClose = (lang) => {
    if (typeof lang !== 'string') {
      return;
    }

    const selectedValue = lang.toLowerCase().substring(0, 2);
    const valueCookie = selectedValue === 'es' ? 'es-419' : selectedValue;
    const domain = isLocalhost
      ? 'localhost'
      : `.${psl.parse(process.env.REACT_APP_URL_SITE_DOMAIN).domain}`;
    // const domain = `localhost`;

    Cookies.set('openedx-language-preference', valueCookie, {
      domain,
    });

    setOption(selectedValue);

    const selectedOtherLang = otherLangs.find(
      (otherLang) => otherLang[selectedValue],
    );
    let url = '';

    if (page === 'home' && selectedValue === 'en') {
      window.location.href = '/';
      return null;
    }

    if (page === 'about') {
      lang === 'es' ? (url = '/es/acerca-de-nosotros') : (url = '/about');
      window.location.href = url;
      return null;
    }

    if (page === 'about-detail') {
      lang === 'es'
        ? (url = '/es/la-historia-de-jorge-valdano')
        : (url = '/the-history-of-jorge-valdano');
      window.location.href = url;
      return null;
    }

    if (page && selectedOtherLang) {
      if (selectedValue && selectedValue !== 'en') {
        url = `/${selectedValue}/${page}/${selectedOtherLang[selectedValue]}`;
      } else {
        url = `/${page}/${selectedOtherLang[selectedValue]}`;
      }
      window.location.href = url;
    } else {
      window.location.href = addLanguageToStaticUrl(
        window.location.pathname,
        selectedValue,
      );
    }
  };

  const languageList = (lng) => {
    let lngLabel = '';

    switch (lng.toLowerCase().substring(0, 2)) {
      case 'es':
        lngLabel = 'SPA';
        break;
      case 'en':
        lngLabel = 'ENG';
        break;
      default:
        lngLabel = 'ENG';
        break;
    }

    return lngLabel;
  };

  const selectedLang = languageList(
    option ||
      (Cookies.get('openedx-language-preference') &&
        Cookies.get('openedx-language-preference').substring(0, 2)) ||
      i18n.culture ||
      'eng',
  );

  return (
    <div className="menu-list-component">
      <Button
        variant="contained"
        className={classnames(
          'btn-tertiary sm dropdown lang',
          { 'arrow-down': !popupState.isOpen },
          { 'arrow-up': popupState.isOpen },
          { black: popupState.isOpen },
        )}
        {...bindHover(popupState)}
      >
        {selectedLang}
      </Button>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        disableScrollLock
        {...bindMenu(popupState)}
      >
        {selectedLang !== 'SPA' && (
          <MenuItem onClick={() => handleClose('es')} className="lang-dropdown">
            SPA
          </MenuItem>
        )}
        {selectedLang !== 'ENG' && (
          <MenuItem onClick={() => handleClose('en')} className="lang-dropdown">
            ENG
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

LangMenu.propTypes = {
  otherLangs: PropTypes.arrayOf(PropTypes.string),
  page: PropTypes.string,
};

LangMenu.defaultProps = {
  otherLangs: [],
  page: '',
};

export default LangMenu;
