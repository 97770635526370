/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
import React, { useState } from 'react';
import classnames from 'classnames';
import lodash from 'lodash';

import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';

import CoursesGallery from '../../Home/CoursesGallery';
import EnterEmailModal from '../Modals/EnterEmail';

import menuType from '../../../types/menuType';
import i18n from '../../../languages';
import trackAction from '../../../utils/tracking';

import ComingSoonIcon from '../../../assets/images/bubble-coming-soon.svg';

import './index.scss';

const tabSelecctionESP = ['Cursos', 'Tips', 'Carreras'];
const tabSelecctionENG = ['Courses', 'Tips', 'Degrees'];

const CategoryBox = ({ category }) => {
  const [openModal, setOpenModal] = useState(false);

  const tabSelecction =
    i18n._culture === 'en' ? tabSelecctionENG : tabSelecctionESP;
  const defaultSelectedTab = window.location.hash
    ? tabSelecction.findIndex(
        (tab) => tab && tab.toLowerCase() === window.location.hash.substring(1),
      )
    : 0;
  const [tab, setTab] = useState(defaultSelectedTab);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const categoryMerged = [];
  category.submenu.map(
    (item) =>
      item.submenu.length > 0 &&
      item.submenu.map(
        (submenu) =>
          submenu &&
          tabSelecction[tab] &&
          submenu.title &&
          submenu.title.toLowerCase() === tabSelecction[tab].toLowerCase() &&
          categoryMerged.push(...submenu.items),
      ),
  );

  const renderSelectedTab = () => (
    <CoursesGallery educationalResources={categoryMerged} />
  );

  // Tracking
  categoryMerged.length > 0 && trackAction('view_item_list', categoryMerged);

  return (
    <div className="category-box">
      <Container maxWidth="lg">
        <Grid container className="grid-container">
          {category.title === 'Sports' ? (
            <>
              <Grid item sm={8} className="category-title title">
                <h2>{category.title}</h2>
              </Grid>
              <Grid item sm={4} className="category-title selector">
                <Tabs
                  value={tab}
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  className="tabs-container"
                >
                  {category.submenu[0].submenu.map((subcategory, index) => {
                    const isDisabled =
                      subcategory.items.length === 0 &&
                      category.submenu[1].submenu[index].items.length === 0;

                    if (!isDisabled) {
                      return (
                        <Tab
                          key={lodash.uniqueId('tab_')}
                          label={subcategory.title}
                          disabled={isDisabled}
                          className={classnames({ disabled: isDisabled })}
                          component={Link}
                          to={`/list#${subcategory.title.toLowerCase()}`}
                          title={i18n.t('COMING_SOON')}
                        />
                      );
                    }
                    return (
                      <div
                        className="tab-container-disabled"
                        key={lodash.uniqueId('tab_')}
                      >
                        <Tab
                          label={subcategory.title}
                          disabled={isDisabled}
                          className={classnames({ disabled: isDisabled })}
                          component={Link}
                          to={`/list#${subcategory.title.toLowerCase()}`}
                          title={i18n.t('COMING_SOON')}
                        />
                        <div className="box-coming-soon disabled">
                          {i18n.t('COMING_SOON')}
                        </div>
                      </div>
                    );
                  })}
                </Tabs>
              </Grid>
            </>
          ) : (
            <>
              <Grid item sm={10} xs={7} className="category-title">
                <div className="coming-soon-title">
                  <h2>{category.title}</h2>
                  <div className="box-coming-soon">
                    <img
                      src={ComingSoonIcon}
                      className="coming-soon-icon"
                      alt="Time"
                    />
                    <p>{i18n.t('COMING_SOON')}</p>
                  </div>
                </div>
              </Grid>
              <Grid item sm={2} xs={5} className="category-title button-remind">
                <button
                  type="button"
                  className="btn-secondary partial-dark  arrow-right md"
                  onClick={setOpenModal}
                >
                  {i18n.t('REMIND_ME')}
                </button>
              </Grid>
              <EnterEmailModal
                open={openModal}
                handleClose={() => setOpenModal(false)}
                idContent="community"
              />
            </>
          )}
        </Grid>
      </Container>

      <Container maxWidth="xl">
        <Grid container>
          <Grid item sm={12}>
            {renderSelectedTab()}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

CategoryBox.propTypes = {
  category: menuType,
};

CategoryBox.defaultProps = {
  category: [],
};

export default CategoryBox;
