import {
  GET_MENU_REQUEST,
  GET_MENU_SUCCESS,
  GET_MENU_ERROR,
} from '../constants';

export default (state = [], action) => {
  switch (action.type) {
    case GET_MENU_REQUEST:
      return [];
    case GET_MENU_SUCCESS:
      return action.payload.data || action.payload;
    case GET_MENU_ERROR:
      return [];
    default:
      return state;
  }
};
