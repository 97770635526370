import { arrayOf, shape, string, number } from 'prop-types';

export const subjectsRequired = shape({
  description: string,
  title: string,
  name: string,
});

export const subjectType = shape({
  id: number,
  name: string,
  overview: string,
  short_description: string,
  subject_type: string,
  subjects_required: arrayOf(subjectsRequired),
  year: string,
});

export const subjectsType = arrayOf(subjectType);
