import api from '../../api';
import {
  GET_DEGREE_REQUEST,
  GET_DEGREE_SUCCESS,
  GET_DEGREE_ERROR,
} from '../constants';

export const getDegree = (id) => (dispatch) => {
  dispatch({
    type: GET_DEGREE_REQUEST,
    meta: api.degrees
      .getDegree(id)
      .then((response) =>
        dispatch({
          type: GET_DEGREE_SUCCESS,
          payload: {
            data: response.data || response,
          },
        }),
      )
      .catch((error) =>
        dispatch({
          type: GET_DEGREE_ERROR,
          payload: error.response,
        }),
      ),
  });
};

export default { getDegree };
