import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Home from './pages/Home';
import List from './pages/List';
import Course from './pages/Course';
import Degree from './pages/Degree';
import About from './pages/About';
import AboutDetail from './pages/About/Detail';
import PrivacyEs from './pages/Info/Privacy/es/index';
import TermsEs from './pages/Info/Terms/es/index';
import PrivacyEn from './pages/Info/Privacy/en/index';
import TermsEn from './pages/Info/Terms/en/index';

import LegalNoticeEs from './pages/Info/LegalNotice/es/index';
import CookiesPolicyEs from './pages/Info/CookiesPolicy/es/index';
import LegalNoticeEn from './pages/Info/LegalNotice/en/index';
import CookiesPolicyEn from './pages/Info/CookiesPolicy/en/index';

import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/common/common.scss';

const Routes = () => (
  <>
    <ToastContainer />
    <Switch>
      <Route exact path="/" component={Home} />

      <Route exact path="/course/confirm_email/:token" component={Home} />
      <Route
        exact
        path="/course/:course/confirm_email/:token"
        component={Course}
      />
      <Route
        exact
        path="/degree/:degree/confirm_email/:token"
        component={Degree}
      />

      <Route exact path="/:lang/course/confirm_email/:token" component={Home} />
      <Route
        exact
        path="/:lang/course/:course/confirm_email/:token"
        component={Course}
      />
      <Route
        exact
        path="/:lang/degree/:degree/confirm_email/:token"
        component={Degree}
      />

      <Route exact path="/:lang/course/:course" component={Course} />
      <Route exact path="/:lang/degree/:degree" component={Degree} />

      <Route exact path="/course/:course" component={Course} />
      <Route exact path="/degree/:degree" component={Degree} />

      <Route exact path="/list" component={List} />

      <Route exact path="/about" component={About} />
      <Route exact path="/es/acerca-de-nosotros/" component={About} />
      <Route exact path="/en/about/" component={About} />

      <Route
        exact
        path="/es/la-historia-de-jorge-valdano/"
        component={AboutDetail}
      />
      <Route
        exact
        path="/en/the-history-of-jorge-valdano/"
        component={AboutDetail}
      />
      <Route
        exact
        path="/the-history-of-jorge-valdano/"
        component={AboutDetail}
      />

      <Route exact path="/info/privacy" component={PrivacyEn} />
      <Route exact path="/info/terms" component={TermsEn} />

      <Route exact path="/es/info/privacy" component={PrivacyEs} />
      <Route exact path="/es/info/terms" component={TermsEs} />

      <Route exact path="/info/cookies" component={CookiesPolicyEn} />
      <Route exact path="/info/legal" component={LegalNoticeEn} />

      <Route exact path="/es/info/cookies" component={CookiesPolicyEs} />
      <Route exact path="/es/info/legal" component={LegalNoticeEs} />

      <Route exact path="/:lang/list" component={List} />
      <Route exact path="/:lang" component={Home} />
    </Switch>
  </>
);

const App = () => (
  <div className="App">
    <Router>
      <Routes />
    </Router>
  </div>
);

export default App;
