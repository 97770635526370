import api from '../../api';
import psl from 'psl';
import Cookies from 'js-cookie';

import { isLocalhost } from '../../serviceWorker';

import {
  GET_ENROLLMENTS_REQUEST,
  GET_ENROLLMENTS_SUCCESS,
  GET_ENROLLMENTS_ERROR,
} from '../constants';

const domain = isLocalhost
  ? 'localhost'
  : `.${psl.parse(process.env.REACT_APP_URL_SITE_DOMAIN).domain}`;

export const getEnrollments = () => (dispatch) => {
  dispatch({
    type: GET_ENROLLMENTS_REQUEST,
    meta: api.enrollments
      .getEnrollments()
      .then((response) => {
        dispatch({
          type: GET_ENROLLMENTS_SUCCESS,
          payload: {
            data: response.data || response,
          },
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          Cookies.remove('edxloggedin', { domain });
          Cookies.remove('edx-user-info', { domain });
          Cookies.remove('early-access', { domain });
          window.location.href = '/';
        }

        if (error.response && error.response.status === 404) {
          document.cookie.split(';').forEach((c) => {
            document.cookie = c
              .replace(/^ +/, '')
              .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
          });
        }

        return dispatch({
          type: GET_ENROLLMENTS_ERROR,
          payload: error.response,
        });
      }),
  });
};

export default { getEnrollments };
