/* eslint-disable global-require */
/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import lodash from 'lodash';

import Grid from '@material-ui/core/Grid';

import i18n from '../../../languages';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.scss';

const echoImage = (image) => {
  try {
    return image.substring(0, 4) === 'http'
      ? image
      : require(`../../../assets/images/slider/${image}`).default;
  } catch (e) {
    return false;
  }
};

const ImageSlider = ({ slider, fixed = true }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    slider.length > 0 && (
      <div
        className="image-slider"
        style={
          fixed
            ? {
                backgroundImage: `url(${
                  slider[0] && slider[0].image && echoImage(slider[0].image)
                }`,
              }
            : {}
        }
      >
        <Slider {...settings} className="image-slider-carousel">
          {slider.map((item) => (
            <div key={lodash.uniqueId('slider_images_')}>
              <Grid
                container
                className="content"
                style={{
                  backgroundImage:
                    !fixed && `url(${item.image && echoImage(item.image)}`,
                }}
                justifyContent="center"
              >
                <Grid item xs={10} sm={9} md={10} className="content-container">
                  <div className="background-gradient-bottom" />
                  <div className="content-item">
                    <h3>{item.title || i18n.t('MENTOR_OF_THIS_COURSE')}</h3>
                    <h2>{item.fullname}</h2>
                    <p
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                    {item.link && item.linkCta && (
                      <a
                        href={item.link}
                        className="btn-secondary white arrow-right md read-more"
                      >
                        {item.linkCta}
                      </a>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          ))}
        </Slider>
      </div>
    )
  );
};

ImageSlider.propTypes = {
  slider: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      title: PropTypes.string,
      fullname: PropTypes.string,
      description: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
  fixed: PropTypes.bool,
};

ImageSlider.defaultProps = {
  slider: [],
  fixed: false,
};

export default ImageSlider;
