import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'js-cookie';
import psl from 'psl';

import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import { getVariablesToSend } from '../../../../utils/getUtmParams';

// import api from '../../../../api';
import { config } from '../../../../config/config';
import { isLocalhost } from '../../../../serviceWorker';
import i18n from '../../../../languages';

import './index.scss';

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    width: '80%',
    maxWidth: 380,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
}));

const EarlyBidModal = ({ open, handleClose }) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [success, setSuccess] = useState(false);
  const params = getVariablesToSend() && `&${getVariablesToSend()}`;

  const domain = isLocalhost
    ? 'localhost'
    : `.${psl.parse(process.env.REACT_APP_URL_SITE_DOMAIN).domain}`;

  const getEarlyBidCouponSent = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setSuccess(true);
    Cookies.set('early-access', 'false', {
      domain,
    });

    window.location.href = `https:${config.URL_EDX_DOMAIN}/register?next=https%3A%2F%2F${config.URL_SITE_DOMAIN}%2F${params}`;
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Grid container className="form-content">
        <Grid item xs={12}>
          <h2>
            {i18n.t('MODAL_EARLY_BID_TITLE')}
            <IconButton onClick={handleClose} className="close">
              <CloseIcon />
            </IconButton>
          </h2>
        </Grid>
      </Grid>
      <Grid container className="form-content">
        <Grid item xs={12}>
          <p>{i18n.t('MODAL_EARLY_BID_DESCRIPTION')}</p>
          <small>{i18n.t('MODAL_EARLY_BID_DISCLAIMER')}</small>
        </Grid>
        <Grid item xs={12} align="right">
          <Button
            className="btn-primary arrow-right md"
            disabled={success}
            onClick={getEarlyBidCouponSent}
          >
            {i18n.t('MODAL_EARLY_BID_OK')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <Modal className="early-bid-modal" open={open} onClose={handleClose}>
      {body}
    </Modal>
  );
};

EarlyBidModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

EarlyBidModal.defaultProps = {
  open: false,
  handleClose: () => {},
};

export default EarlyBidModal;
