import classnames from 'classnames';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import i18n from '../../../languages';
import { config } from '../../../config/config';
import { getVariablesToSend } from '../../../utils/getUtmParams';

import VideoMuteIcon from '../../../assets/images/icon-video-muted.svg';
import VideoUnmuteIcon from '../../../assets/images/icon-video-unmuted.svg';
import VideoReplayIcon from '../../../assets/images/icon-video-replay.svg';

import './index.scss';

const Jumbotron = ({ logged, stop, setMuteCallback, setReplayCallback }) => {
  const [mute, setMute] = useState(true);
  let title = i18n.t('BRAND_TITLE');
  const titleWordsQty =
    title && title.trim().replace(/\s+/gi, ' ').split(' ').length;
  if (titleWordsQty <= 3) {
    title = title.replace(/\s/g, '<br />');
  }

  const params = getVariablesToSend() && `&${getVariablesToSend()}`;

  const setMuteClick = (event) => {
    event.preventDefault();
    setMute(!mute);
    setMuteCallback(!mute);
  };

  const setReplayClick = (event) => {
    event.preventDefault();
    setReplayCallback(true);
  };

  return (
    <div className={classnames('jumbotron', { stop: !stop })}>
      <Grid container>
        <Grid item sm={5} xs={12} className="content">
          <h2 dangerouslySetInnerHTML={{ __html: title }} />
          <h3>{i18n.t('BRAND_DESCRIPTION')}</h3>

          {!logged && (
            <a
              className="create-acount-button"
              href={`https:${config.URL_EDX_DOMAIN}/register?next=https%3A%2F%2F${config.URL_SITE_DOMAIN}%2F${params}`}
            >
              <button
                type="button"
                className="btn-white md arrow-right create-account"
              >
                <span>{i18n.t('CREATE_ACCOUNT')}</span>
              </button>
            </a>
          )}
        </Grid>
        <Grid item sm={7} className="content-right">
          <img
            src={mute ? VideoMuteIcon : VideoUnmuteIcon}
            className="video-mute-icon"
            alt="Mute"
            onClick={setMuteClick}
          />
          <img
            src={VideoReplayIcon}
            className="video-replay-icon"
            alt="Replay"
            onClick={setReplayClick}
          />
        </Grid>
      </Grid>
      <Hidden only="xs">
        <div className="scroll-container pullDown delay-1000">
          <i className="scroll" />
        </div>
      </Hidden>
    </div>
  );
};

Jumbotron.propTypes = {
  logged: PropTypes.bool,
};

Jumbotron.defaultProps = {
  logged: false,
};

export default Jumbotron;
