/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import AboutBox from '../../components/Home/AboutBox';
import Header from '../../components/Common/Header';
import Footer from '../../components/Common/Footer';
import MetaTags from '../../components/Common/MetaTags';

import i18n from '../../languages';
import selectLanguage from '../../utils/selectLanguage';

import HomeMockSP from '../../constants/HomeSP';
import HomeMockEN from '../../constants/HomeEN';

import NicoIcon from '../../assets/images/about/Nico-Uchitel.png';
import JuanmaIcon from '../../assets/images/about/juanma-freire-1.png';
import MaxiIcon from '../../assets/images/about/maxi-itzkoff.png';
import JorgeIcon from '../../assets/images/about/08-jorge-valdano-b.png';

import './index.scss';

const About = ({ match }) => {
  const { pathname } = useLocation();
  const { lang } = match.params;

  const HomeMockData = i18n._culture === 'en' ? HomeMockEN : HomeMockSP;
  const { about } = HomeMockData;

  useEffect(() => {
    selectLanguage(lang);
    setTimeout(() => {
      window.scrollTo(0, 1);
    }, 0);
  }, [pathname, lang]);

  const langs = [{ en: '/about' }, { es: '/es/acerca-de-nosotros' }];

  return (
    <>
      <MetaTags
        title={i18n.t('PAGE_ABOUT_TITLE')}
        description={i18n.t('PAGE_ABOUT_DESCRIPTION')}
        other_langs={langs}
      />
      <div className="page-wrap">
        <Header theme="black" page="about" />
        <div className="page about">
          <Container maxWidth="lg">
            <Grid container className="grid-container">
              <Grid item xs={12} sm={12} className="category-title">
                <h2>{i18n.t('ABOUT_US')}</h2>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <AboutBox {...about} linkCta={about.linkCta} link={about.link} />
            </Grid>

            <Grid container className="grid-container">
              <Grid item xs={12} sm={12} className="category-title">
                <h2>{i18n.t('COFOUNDERS')}</h2>
              </Grid>
            </Grid>

            <Grid container className="quote">
              <Grid item xs={1} sm={1} className="left-quote" />
              <Grid item xs={11} sm={11}>
                <p>{i18n.t('ABOUT_US_CONTENT')}</p>
              </Grid>
            </Grid>

            <Grid
              container
              className="co-founders"
              direction="row"
              justifyContent="center"
              alignItems="stretch"
            >
              <Grid item xs={6} sm={4}>
                <a
                  className="cofounder-link"
                  href="https://www.linkedin.com/in/nicolasuchitel/"
                >
                  <img src={NicoIcon} alt="Nicolas Uchitel" />
                  <h3>Nicolas Uchitel</h3>
                  <h4>CHIEF EXECUTIVE OFFICER</h4>
                </a>
                <hr />
              </Grid>
              <Grid item xs={6} sm={4}>
                <a
                  className="cofounder-link"
                  href="https://www.linkedin.com/in/juanmafreire/"
                >
                  <img src={JuanmaIcon} alt="Juan Manuel Freire" />
                  <h3>Juan Manuel Freire</h3>
                  <h4>Chief Content Officer</h4>
                </a>
                <hr />
              </Grid>

              <Grid item xs={6} sm={4}>
                <a
                  className="cofounder-link"
                  href={`mailto:partners@oxygen.education?subject=${i18n.t(
                    'MESSAGE_FOR_VALDANO',
                  )}`}
                >
                  <img src={JorgeIcon} alt="Jorge Valdano" />
                  <h3>Jorge Valdano</h3>
                  <h4>Brand Ambassador</h4>
                </a>
                <hr />
              </Grid>
            </Grid>

            <Grid container className="grid-container">
              <Grid item xs={12} sm={12} className="category-title">
                <h2>{i18n.t('LETS_TALK')}</h2>
              </Grid>
            </Grid>

            <Grid container className="quote">
              <Grid item xs={1} sm={1} className="left-quote" />
              <Grid item xs={11} sm={11}>
                <p>{i18n.t('PARTNER_INTERESTED')}</p>
              </Grid>
            </Grid>

            <Grid container className="final-text">
              {i18n._culture === 'en' ? (
                <Grid item sm={12}>
                  <h3>MENTORS PARTNERSHIP</h3>
                  <p>
                    If you’re an Outstanding Sports Mentor interested in
                    creating content on the Oxygen platform, please contact us{' '}
                    <a
                      href={`mailto:partners@oxygen.education?subject=${i18n.t(
                        'MENTORS',
                      )} ${i18n.t('PARTNERSHIP_WITH_OXYGEN')}`}
                    >
                      here
                    </a>
                    .
                  </p>

                  <h3>UNIVERSITIES PARTNERSHIP</h3>
                  <p>
                    If you’re a University or educational organization
                    interested in creating content on the Oxygen platform,
                    please contact us{' '}
                    <a
                      href={`mailto:partners@oxygen.education?subject=${i18n.t(
                        'UNIVERSITY',
                      )} ${i18n.t('PARTNERSHIP_WITH_OXYGEN')}`}
                    >
                      here
                    </a>
                    .
                  </p>

                  <h3>SPORTS ORGANIZATIONS PARTNERSHIP</h3>
                  <p>
                    If you’re a Sports Organization (including Federations,
                    Confederations, Associations), Professional Sport Club
                    (Public or Private Entity) or non-profit entity interested
                    in creating content on the Oxygen platform, please contact
                    us{' '}
                    <a
                      href={`mailto:partners@oxygen.education?subject=${i18n.t(
                        'SPORTS',
                      )} ${i18n.t('PARTNERSHIP_WITH_OXYGEN')}`}
                    >
                      here
                    </a>
                    .
                  </p>

                  <h3>MEDIA INDUSTRY PARTNERSHIP</h3>
                  <p>
                    If you’re a Media Industry stakeholder interested in
                    creating content on the Oxygen platform, please contact us{' '}
                    <a
                      href={`mailto:partners@oxygen.education?subject=${i18n.t(
                        'MEDIA',
                      )} ${i18n.t('PARTNERSHIP_WITH_OXYGEN')}`}
                    >
                      here
                    </a>
                    .
                  </p>

                  <h3>TECH INDUSTRY PARTNERSHIP</h3>
                  <p>
                    If you’re a Tech Industry stakeholder interested in creating
                    content on the Oxygen platform, please contact us{' '}
                    <a
                      href={`mailto:partners@oxygen.education?subject=${i18n.t(
                        'TECH',
                      )} ${i18n.t('PARTNERSHIP_WITH_OXYGEN')}`}
                    >
                      here
                    </a>
                    .
                  </p>
                </Grid>
              ) : (
                <Grid item sm={12}>
                  <h3>Consultas sobre Partnerships con Mentores:</h3>
                  <p>
                    Si eres un Mentor de Deportes sobresaliente interesado en
                    crear contenido en la plataforma de Oxygen, contactate con
                    nosotros{' '}
                    <a
                      href={`mailto:partners@oxygen.education?subject=${i18n.t(
                        'MENTORS',
                      )} ${i18n.t('PARTNERSHIP_WITH_OXYGEN')}`}
                    >
                      aquí
                    </a>
                    .
                  </p>

                  <h3>Consultas sobre Partnerships con Universidades:</h3>
                  <p>
                    Si representas una Universidad u organización educativa
                    interesada en crear contenido en la plataforma de Oxygen,
                    sírvase contactarse con nosotros{' '}
                    <a
                      href={`mailto:partners@oxygen.education?subject=${i18n.t(
                        'UNIVERSITY',
                      )} ${i18n.t('PARTNERSHIP_WITH_OXYGEN')}`}
                    >
                      aquí
                    </a>
                    .
                  </p>

                  <h3>
                    Consultas sobre Partnerships con Organizaciones de Deportes:
                  </h3>
                  <p>
                    Si representas una Organización Deportiva (incluyendo
                    Federaciones, Confederaciones, Asociaciones), Club de
                    Deportes Profesionales (Entidad Pública o Privada) o una
                    entidad sin fines de lucro interesada en crear contenido en
                    la plataforma de Oxygen, contactate con nosotros{' '}
                    <a
                      href={`mailto:partners@oxygen.education?subject=${i18n.t(
                        'SPORTS',
                      )} ${i18n.t('PARTNERSHIP_WITH_OXYGEN')}`}
                    >
                      aquí
                    </a>
                    .
                  </p>

                  <h3>
                    Consultas sobre Partnerships con la Industria de Medios:
                  </h3>
                  <p>
                    Si formas parte de la Industria de medios y estás
                    interesado/a en crear contenido en la plataforma de Oxygen,
                    sírvase contactarse con nosotros{' '}
                    <a
                      href={`mailto:partners@oxygen.education?subject=${i18n.t(
                        'MEDIA',
                      )} ${i18n.t('PARTNERSHIP_WITH_OXYGEN')}`}
                    >
                      aquí
                    </a>
                    .
                  </p>

                  <h3>
                    Consultas sobre Partnerships con la Industria de Tecnología:
                  </h3>
                  <p>
                    Si formas parte de la Industria de Tecnología y estás
                    interesado/a en crear contenido en la plataforma de Oxygen,
                    contactate con nosotros{' '}
                    <a
                      href={`mailto:partners@oxygen.education?subject=${i18n.t(
                        'TECH',
                      )} ${i18n.t('PARTNERSHIP_WITH_OXYGEN')}`}
                    >
                      aquí
                    </a>
                    .
                  </p>
                </Grid>
              )}
            </Grid>

            <Grid container className="footer-map">
              <Grid container className="grid-container">
                <Grid item xs={12} sm={12} className="category-title">
                  <h2>{i18n.t('HEADQUARTERS')}</h2>
                </Grid>
              </Grid>
              <Grid sm={12}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2752.2251672038437!2d6.21254275177531!3d46.3847589790202!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478c5d286daf7df9%3A0x348ba6205f9ea1e4!2sRoute%20de%20Crassier%207%2C%201262%20Eysins%2C%20Suiza!5e0!3m2!1ses!2sar!4v1611597299400!5m2!1ses!2sar"
                  title="Central Offices"
                  width={600}
                  height={450}
                  frameBorder={0}
                  style={{ border: 0 }}
                  className="google-map"
                  allowFullScreen
                  aria-hidden="false"
                  tabIndex={0}
                />
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
