import { arrayOf, shape, string, number, boolean, oneOfType } from 'prop-types';

import mediaType from './mediaType';
import { partnersType } from './partnersType';
import { structuresType } from './structureType';
import { pricingsType } from './pricingType';
import languageType from './languageType';

export const courseType = shape({
  available_language: languageType,
  error: string,
  loading: boolean,
  blocks_url: string,
  effort: string,
  end: string,
  enrollment_start: string,
  enrollment_end: string,
  id: oneOfType([string, number]),
  type: string,
  other_langs: arrayOf(string),
  oxygen_type: string,
  oxygen_subtype: string,
  media: mediaType,
  name: string,
  number: string,
  org: string,
  overview: string,
  short_description: string,
  start: string,
  start_display: string,
  start_type: string,
  pacing: string,
  mobile_available: boolean,
  hidden: boolean,
  invitation_only: boolean,
  partners: partnersType,
  structure: structuresType,
  pricing: pricingsType,
  language: languageType,
  other_settings: string,
  seo_description: string,
  seo_title: string,
});

export const coursesType = arrayOf(courseType);
