/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import i18n from '../../../../languages';

import Header from '../../../../components/Common/Header';
import Footer from '../../../../components/Common/Footer';
import MetaTags from '../../../../components/Common/MetaTags';

import '../../index.scss';

const CookiesPolicyEN = () => {
  const { pathname } = useLocation();

  const langs = [{ en: '/info/cookies' }, { es: '/es/info/cookies' }];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <MetaTags
        title={i18n.t('PAGE_PRIVA_TITLE')}
        description={i18n.t('PAGE_PRIVA_DESCRIPTION')}
        other_langs={langs}
      />
      <div className="page-wrap">
        <Header theme="black" />
        <div className="page privacy">
          <Container maxWidth="lg" className="container">
            <Grid item xs={12}>
              <h2>COOKIES POLICY</h2>

              <h3>1. INTRODUCTION</h3>
              <p>
                The Website may use its own and/or third party cookies to
                improve the user's experience and offer content tailored to
                their interests. By using the registration procedure on the
                website and/or browsing, the user accepts the installation of
                cookies, unless he/she objects, as detailed in this Cookies
                Policy.
              </p>
              <p>
                The Cookie Policy is subject to periodic updates. The purpose is
                to help you understand how cookies are used, their purpose, as
                well as the different options available to the user for managing
                them.{' '}
              </p>
              <p>
                Users can access this information at any time through the link
                provided on the website. In the same way, they can modify their
                preferences on the acceptance of cookies through the options set
                in their browser.
              </p>
              <p>
                The Cookies Policy of the website comprises the domain of{' '}
                <a href="https://oxygen.education">oxygen.education</a>
              </p>

              <h3>2. DEFINITION OF A COOKIE</h3>
              <p>
                <em>Cookies </em>and other similar technologies such as local
                shared objects, flash <em>cookies </em>or <em>pixels</em>, are
                tools used by web servers to store and retrieve information
                about their visitors, as well as to provide a correct
                functioning of the site.{' '}
              </p>
              <p>
                The use of these devices allows the web server to remember some
                data concerning the user, such as their preferences for viewing
                the pages of that server, name and password, products they are
                most interested in, etc.
              </p>

              <h3>
                3. CLASSIFICATION OF THE COOKIES THAT MAY OCCUR ON THE WEBSITE
              </h3>

              <p>
                ▪{' '}
                <strong>
                  <em>Analytical Cookies: </em>
                </strong>
                these collect information on the use of the website.
                <br />▪{' '}
                <strong>
                  <em>Social Cookies: </em>
                </strong>
                these are those necessary for external social networks.
                <br />▪{' '}
                <strong>
                  <em>Affiliate Cookies: </em>
                </strong>
                these enable tracking of visits from other websites with which
                the website has an affiliation contract.
                <br />▪{' '}
                <strong>
                  <em>Advertising and behavioural cookies: </em>
                </strong>
                these collect information about the user&rsquo;s preferences and
                personal choices.
                <br />▪{' '}
                <strong>
                  <em>Technical and functional cookies: </em>
                </strong>
                these are strictly necessary for the use of the website and for
                the provision of the contracted service.
                <br />
              </p>

              <p>
                The cookies used on this website and their purpose are listed
                below. The list of cookies used on the https://oxygen.education
                domain.
              </p>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>
                        <strong>
                          <em>Cookie</em>
                        </strong>
                      </p>
                    </td>
                    <td>
                      <p>
                        <strong>
                          <em>Description</em>
                        </strong>
                      </p>
                    </td>
                    <td>
                      <p>
                        <strong>
                          <em>Duration</em>
                        </strong>
                      </p>
                    </td>
                    <td>
                      <p>
                        <strong>
                          <em>Category</em>
                        </strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>XDOMAIN-LOGGED-IN</strong>
                      </p>
                    </td>
                    <td>
                      <p>This cookie is set for cross-domain login.</p>
                    </td>
                    <td>
                      <p>Indefinite</p>
                    </td>
                    <td rowSpan="3">
                      <p>Techniques and functional</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>sessionid</strong>
                      </p>
                    </td>
                    <td>
                      <p>
                        This cookie is set to store the current session data.
                      </p>
                    </td>
                    <td>
                      <p>14 days</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>CloudFront-Signature</strong>
                      </p>
                      <p>
                        <strong>CloudFront-Policy</strong>
                      </p>
                      <p>
                        <strong>CloudFront-Key-Pair-Id</strong>
                      </p>
                    </td>
                    <td>
                      <p>This cookie is set to speed up the </p>
                      <p>delivery of static </p>
                      <p>and dynamic web content such as </p>
                      <p>.html, .css, .js and image files to </p>
                      <p>users.</p>
                    </td>
                    <td>
                      <p>During </p>
                      <p>the </p>
                      <p>session</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_ga_*</strong>
                      </p>
                    </td>
                    <td rowSpan="3">
                      <p>These cookies </p>
                      <p>
                        enable the function of monitoring user visits and
                        sessions on the Website.
                      </p>
                    </td>
                    <td>
                      <p>365 days</p>
                    </td>
                    <td rowSpan="3">
                      <p>Analytics</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>__utmzzses</strong>
                      </p>
                    </td>
                    <td>
                      <p>During </p>
                      <p>the </p>
                      <p>session</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>InitialTrafficSource</strong>
                      </p>
                    </td>
                    <td>
                      <p>2 years</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_hjSessionUser_*</strong>
                      </p>
                    </td>
                    <td>
                      <p>1 year</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_hjSession_*</strong>
                      </p>
                    </td>
                    <td>
                      <p>30 mins</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                <br />
                <br />
              </p>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>
                        <strong>_hjAbsoluteSessionInProgress</strong>
                      </p>
                    </td>
                    <td rowSpan="12">
                      <p>These cookies are set to record </p>
                      <p>activities on the </p>
                      <p>
                        Website in order to understand the use of the Website
                        and{' '}
                      </p>
                      <p>improve the user experience.</p>
                    </td>
                    <td>
                      <p>30 mins</p>
                    </td>
                    <td rowSpan="12">
                      <p>Analytics</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_hjIncludedInPageviewSample</strong>
                      </p>
                    </td>
                    <td>
                      <p>30 mins</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_hjIncludedInSessionSample</strong>
                      </p>
                    </td>
                    <td>
                      <p>30 mins</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_hjid</strong>
                      </p>
                    </td>
                    <td>
                      <p>1 year</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_hjptid</strong>
                      </p>
                    </td>
                    <td>
                      <p>1 year</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_BEAMER_DATE_PRODUCT</strong>
                      </p>
                    </td>
                    <td>
                      <p>300 days</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_BEAMER_FILTER_BY_URL_PRODUCT</strong>
                      </p>
                    </td>
                    <td>
                      <p>20 mins</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_BEAMER_LAST_UPDATE_PRODUCT</strong>
                      </p>
                    </td>
                    <td>
                      <p>300 days</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>ajs_user_id</strong>
                      </p>
                    </td>
                    <td>
                      <p>1 year</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>ajs_anonymous_id</strong>
                      </p>
                    </td>
                    <td>
                      <p>1 year</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_gcl_au</strong>
                      </p>
                    </td>
                    <td>
                      <p>3 months</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_gid</strong>
                      </p>
                    </td>
                    <td>
                      <p>1 day</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_fbp</strong>
                      </p>
                    </td>
                    <td>
                      <p>
                        These cookies and tracking pixels are set to measure and
                        analyse the{' '}
                      </p>
                      <p>performance of our Facebook </p>
                      <p>campaigns.</p>
                    </td>
                    <td>
                      <p>3 months</p>
                    </td>
                    <td>
                      <p>Advertising and behavioural</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>AnalyticsSyncHistory</strong>
                      </p>
                    </td>
                    <td rowSpan="4">
                      <p>These cookies are set to analyse and easure the</p>
                    </td>
                    <td>
                      <p>30 hours</p>
                    </td>
                    <td rowSpan="4">
                      <p>Advertising and behavioural</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>li_gc</strong>
                      </p>
                    </td>
                    <td>
                      <p>2 years</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>Li_mc</strong>
                      </p>
                    </td>
                    <td>
                      <p>2 years</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>lidc</strong>
                      </p>
                    </td>
                    <td>
                      <p>24 hours</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                <br />
                <br />
              </p>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>
                        <strong>UserMatchHistory</strong>
                      </p>
                    </td>
                    <td rowSpan="5">
                      <p>performance of our posts on LinkedIn.</p>
                    </td>
                    <td>
                      <p>30 days</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>lang</strong>
                      </p>
                    </td>
                    <td>
                      <p>During </p>
                      <p>the </p>
                      <p>sesi&oacute;n</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>Lms_analytics</strong>
                      </p>
                    </td>
                    <td>
                      <p>30 days</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>bcookie</strong>
                      </p>
                    </td>
                    <td>
                      <p>2 days</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>Lms_ads</strong>
                      </p>
                    </td>
                    <td>
                      <p>30 days</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_uetsid</strong>
                      </p>
                    </td>
                    <td rowSpan="2">
                      <p>These cookies anre set to measure and analyse the </p>
                      <p>performance of our Bing Ads </p>
                      <p>campaigns.</p>
                    </td>
                    <td>
                      <p>1 day</p>
                    </td>
                    <td rowSpan="2">
                      <p>Advertising and behavioural</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>_uetvid</strong>
                      </p>
                    </td>
                    <td>
                      <p>16 days</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <br />
              <h3>4. ACCEPTANCE OF COOKIES</h3>
              <p>
                When accessing this Website, and in accordance with current data
                protection regulations, we inform you of the use of cookies,
                giving you the option of expressly accepting them and accessing
                more information through this Cookies Policy.
              </p>
              <p>
                You should know that, if you continue browsing, you will be
                giving your consent to the use of these cookies. However, at any
                time, you can change your mind and block their use through your
                browser.{' '}
              </p>
              <p>
                For your peace of mind, this website complies with current
                legislation regarding the use of cookies and your personal data.
              </p>

              <h3>
                5. PROCEDURES FOR DISABLING, BLOCKING AND/OR DELETING COOKIES
              </h3>
              <p>
                At any time, you can adapt your browser settings to refuse the
                use of cookies.
              </p>
              <p>
                You can also adjust your browser settings so that your browser
                rejects all cookies, or only third-party cookies. And you can
                also delete any cookies that are already on your computer.{' '}
              </p>
              <p>
                To do this, you should bear in mind that you will have to adapt
                the settings of each browser and device you use separately
                because, as we have already mentioned, cookies are associated
                with the browser, not the person.
              </p>
              <p>
                <strong>
                  GOOGLE CHROME{' '}
                  <a href="https://support.google.com/chrome/answer/95647?hl=es-419 ">
                    https://support.google.com/chrome/answer/95647?hl=es-419
                  </a>
                </strong>
              </p>
              <p>
                <strong>
                  INTERNET EXPLORER{' '}
                  <a href="https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-10">
                    https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-10
                  </a>
                </strong>
              </p>
              <p>
                <strong>
                  MOZILLA FIREFOX{' '}
                  <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&amp;redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we">
                    https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&amp;redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we
                  </a>
                </strong>
              </p>
              <p>
                <strong>
                  APPLE SAFARI{' '}
                  <a href="https://support.apple.com/es-es/HT201265 ">
                    https://support.apple.com/es-es/HT201265
                  </a>
                </strong>
              </p>
              <p>
                <strong>
                  EDGE{' '}
                  <a href="https://support.microsoft.com/es-es/windows/microsoft-edge-datos-de-exploraci%C3%B3n-y-privacidad-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd">
                    https://support.microsoft.com/es-es/windows/microsoft-edge-datos-de-exploraci%C3%B3n-y-privacidad-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd
                  </a>
                </strong>
              </p>
              <p>
                <strong>
                  OPERA{' '}
                  <a href="https://help.opera.com/en/latest/web-preferences/ ">
                    https://help.opera.com/en/latest/web-preferences/
                  </a>
                </strong>
              </p>
              <p>
                If you have any questions about this Cookie Policy, you can
                contact us by sending an email to{' '}
                <a href="mailto:info@oxygen.education">info@oxygen.education</a>
              </p>
            </Grid>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CookiesPolicyEN;
