import React from 'react';
import lodash from 'lodash';
import Button from '@material-ui/core/Button';
import { isMobile } from 'react-device-detect';

import Carousel, { consts } from 'react-elastic-carousel';

import i18n from '../../../languages';

import { educationalResourcesType } from '../../../types/educationalResourceType';

import './index.scss';

const SliderCourses = ({ slider }) => {
  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? (
        <div className="slick-arrow slick-prev" />
      ) : (
        <div className="slick-arrow slick-next" />
      );
    return (
      <Button onClick={onClick} disabled={isEdge}>
        {pointer}
      </Button>
    );
  };

  return (
    <div className="slider-courses">
      <Carousel
        itemsToShow={isMobile ? 1 : 4}
        pagination={false}
        itemPadding={[8, 8]}
        renderArrow={isMobile ? () => <></> : myArrow}
      >
        {slider.map((slide) => {
          const { media, name, partners, resume_course_url } = slide.resource;
          return (
            <div
              key={lodash.uniqueId('slider_')}
              className="slide"
              style={
                media &&
                media.square && { backgroundImage: `url(${media.square})` }
              }
            >
              <div>
                <h2>{name}</h2>
                <h3>{partners[0] && partners[0].fullname}</h3>
                <a
                  href={resume_course_url}
                  className="btn-secondary sm arrow-right continue"
                >
                  {i18n.t('CONTINUE')}
                </a>
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

SliderCourses.propTypes = {
  slider: educationalResourcesType,
};

SliderCourses.defaultProps = {
  slider: [],
};

export default SliderCourses;
