/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';

import menuType from '../../../../types/menuType';

const DropdownMenu = (props) => {
  const { menu } = props;
  const options = [];

  const getMenuItem = (menuItem, index) => {
    if (menuItem.submenu && menuItem.submenu.length > 0) {
      return (
        <li key={index}>
          {menuItem.title}
          <DropdownMenu
            menu={menuItem.submenu}
            submenu
            overMenu={props.overMenu}
          />
        </li>
      );
    }
    if (menuItem.items && menuItem.items.length === 0) {
      menuItem.items.push({
        empty: true,
        title: 'Coming Soon',
      });
    }

    if (menuItem.items && menuItem.items.length > 0) {
      return (
        <li key={index}>
          {menuItem.title}
          <DropdownMenu
            menu={menuItem.items}
            submenu
            overMenu={props.overMenu}
          />
        </li>
      );
    }
    if (menuItem.empty) {
      return <li className="empty">{menuItem.title}</li>;
    }

    if (menuItem.title) {
      return <li key={index}>{menuItem.title}</li>;
    }
    if (menuItem.name) {
      let url = '';

      if (
        menuItem.language &&
        menuItem.language.code &&
        menuItem.language.code !== 'en'
      ) {
        url = `/${menuItem.language.code}/${menuItem.type}/${menuItem.id}`;
      } else {
        url = `/${menuItem.type}/${menuItem.id}`;
      }

      return menuItem.status === 'remind_me' ||
        menuItem.status === 'pending' ? (
        <li
          key={index}
          className="no-link"
          onMouseOver={() => props.overMenu(menuItem)}
        >
          {menuItem.name}
        </li>
      ) : (
        <li key={index}>
          <a
            href={url}
            onMouseOver={() =>
              menuItem.name ? props.overMenu(menuItem) : props.overMenu(null)
            }
            onFocus={() =>
              menuItem.name ? props.overMenu(menuItem) : props.overMenu(null)
            }
          >
            {menuItem.name}
          </a>
        </li>
      );
    }

    return false;
  };

  if (menu.length > 0) {
    menu.forEach((item, index) => {
      options.push(getMenuItem(item, index));
    });
  }

  if (props.submenu && props.submenu === true) {
    return <ul>{options}</ul>;
  }

  return <ul className="dropdown-menu">{options}</ul>;
};

DropdownMenu.propTypes = {
  menu: menuType,
  overMenu: PropTypes.func,
  submenu: PropTypes.bool,
};

DropdownMenu.defaultProps = {
  menu: [],
  overMenu: () => {},
  submenu: false,
};

export default DropdownMenu;
