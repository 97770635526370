import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import i18n from '../../../../languages';

import Header from '../../../../components/Common/Header';
import Footer from '../../../../components/Common/Footer';
import MetaTags from '../../../../components/Common/MetaTags';

import '../../index.scss';

const LegalNoticeES = () => {
  const { pathname } = useLocation();

  const langs = [{ en: '/info/legal' }, { es: '/es/info/legal' }];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <MetaTags
        title={i18n.t('PAGE_PRIVA_TITLE')}
        description={i18n.t('PAGE_PRIVA_DESCRIPTION')}
        other_langs={langs}
      />
      <div className="page-wrap">
        <Header theme="black" />
        <div className="page privacy">
          <Container maxWidth="lg" className="container">
            <Grid item xs={12}>
              <h2>Aviso Legal</h2>
              <p>
                <em>
                  Fecha de elaboraci&oacute;n del presente Aviso Legal
                  09/11/2021
                </em>
              </p>
              <p>
                <strong>PLATFORM MEDIA LAB, SA</strong>, en adelante{' '}
                <strong>PLATFORM MEDIA</strong>, con CHE{' '}
                <strong>426.769.305, </strong>y domicilio social situado en
                Route de Crassier 7, 1262 Eysins (Suiza), en calidad de
                responsable de la gesti&oacute;n del dominio de{' '}
                <a href="https://oxygen.education/">oxygen.education</a>, quiere
                determinarle los siguientes aspectos en el presente Aviso Legal.
              </p>
              <h3>DATOS IDENTIFICATIVOS</h3>
              <p>
                Identidad:{' '}
                <strong>PLATFORM MEDIA LAB, SA - CHE: 426.769.305 </strong>
                <br />
                Direcci&oacute;n postal:{' '}
                <strong>Route de Crassier 7, 1262 Eysins, Suiza.</strong>
                <br />
                Tel&eacute;fono: <strong>+41225951926 </strong>- Correo
                electr&oacute;nico:{' '}
                <a href="mailto:info@oxygen.education">info@oxygen.education</a>{' '}
                Nombre de los dominios:{' '}
                <a href="https://oxygen.education/">oxygen.education</a>
              </p>

              <h3> LEY DE LOS SERVICIOS DE LA SOCIEDAD DE LA INFORMACION</h3>

              <p>
                <strong>PLATFORM MEDIA </strong>como responsable de la
                p&aacute;gina web{' '}
                <a href="https://oxygen.education/">oxygen.education</a>, pone a
                disposici&oacute;n de los usuarios el presente documento, con el
                que pretende dar cumplimiento a las obligaciones previstas en la
                normativa vigente, as&iacute; como informar a todos los usuarios
                de la p&aacute;gina web respecto a cu&aacute;les son las
                condiciones de uso.
              </p>
              <p>
                Toda persona que acceda a esta p&aacute;gina web asume el papel
                de usuario, comprometi&eacute;ndose a la observancia y
                cumplimiento riguroso de las disposiciones aqu&iacute;
                dispuestas, as&iacute; como a cualquier otra disposici&oacute;n
                legal que fuera de aplicaci&oacute;n.
              </p>
              <p>
                <strong>PLATFORM MEDIA </strong>se reserva el derecho de
                modificar cualquier tipo de informaci&oacute;n que pudiera
                aparecer en esta p&aacute;gina web, sin que exista
                obligaci&oacute;n de preavisar o poner en conocimiento de los
                usuarios dichas obligaciones, entendi&eacute;ndose como
                suficiente con la publicaci&oacute;n en la p&aacute;gina web de{' '}
                <strong>PLATFORM MEDIA</strong>.
              </p>

              <h3> DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL</h3>

              <p>
                Los derechos de propiedad intelectual de la p&aacute;gina web{' '}
                <a href="https://oxygen.education/">oxygen.education</a>, su
                c&oacute;digo fuente, dise&ntilde;o, estructura de
                navegaci&oacute;n, bases de datos y los distintos elementos en
                &eacute;l contenidos son titularidad de{' '}
                <strong>PLATFORM MEDIA </strong>a quien corresponde el ejercicio
                exclusivo de los derechos de explotaci&oacute;n de estos en
                cualquier forma y, en especial, los derechos de
                reproducci&oacute;n, distribuci&oacute;n, comunicaci&oacute;n
                p&uacute;blica y transformaci&oacute;n.
                <br />
              </p>
              <p>
                Estas condiciones generales regulan el acceso y
                utilizaci&oacute;n de la p&aacute;gina web{' '}
                <a href="https://oxygen.education/">oxygen.education</a> que{' '}
                <strong>PLATFORM MEDIA </strong>pone gratuitamente a
                disposici&oacute;n de los usuarios de Internet. La
                utilizaci&oacute;n de determinados servicios ofrecidos en esta
                p&aacute;gina web se regir&aacute; adem&aacute;s por las
                condiciones particulares previstas en cada caso, las cuales se
                entender&aacute;n aceptadas por el mero uso de tales servicios.
              </p>
              <p>
                Se autoriza la visualizaci&oacute;n, impresi&oacute;n y
                descarga, en su caso, del contenido de la p&aacute;gina web
                s&oacute;lo y exclusivamente si concurren las siguientes
                condiciones:{' '}
              </p>
              <p>
                ▪ Que sea compatible con los fines de la p&aacute;gina web{' '}
                <a href="https://oxygen.education/">oxygen.education</a>
              </p>
              <p>
                ▪ Que se realice con el exclusivo &aacute;nimo de obtener la
                informaci&oacute;n contenida para uso personal y privado. Se
                proh&iacute;be expresamente su utilizaci&oacute;n con fines
                comerciales o para su distribuci&oacute;n, comunicaci&oacute;n
                p&uacute;blica o transformaci&oacute;n.
              </p>
              <p>
                ▪ Que ninguno de los contenidos relacionados en esta
                p&aacute;gina web sean modificados de manera alguna.
                <br />
              </p>
              <p>
                <strong>PLATFORM MEDIA </strong>se reserva la facultad de
                efectuar, en cualquier momento y sin necesidad de previo aviso,
                modificaciones y actualizaciones de la informaci&oacute;n
                contenida en la referida p&aacute;gina web, de la
                configuraci&oacute;n y presentaci&oacute;n de &eacute;sta y de
                las condiciones de acceso.
              </p>
              <p>
                <strong>PLATFORM MEDIA </strong>no garantiza la inexistencia de
                interrupciones o errores en el acceso a la p&aacute;gina web
                se&ntilde;alada, en su contenido, ni que &eacute;ste se
                encuentre actualizado, aunque desarrollar&aacute; sus mejores
                esfuerzos para, en su caso, subsanarlos o actualizarlos.
              </p>
              <p>
                La utilizaci&oacute;n no autorizada de la informaci&oacute;n
                contenida en esta p&aacute;gina web, as&iacute; como la
                lesi&oacute;n de los derechos de Propiedad Intelectual o
                Industrial de <strong>PLATFORM MEDIA</strong>, dar&aacute; lugar
                a las responsabilidades legalmente establecidas.
              </p>

              <h3> ACCESO DE LOS USUARIOS AL SITIO WEB</h3>

              <p>
                La p&aacute;gina web{' '}
                <a href="https://oxygen.education/">oxygen.education</a> puede
                ser visitada libremente por los
              </p>
              <p>
                usuarios. Dicha p&aacute;gina web podr&aacute; contar con
                diferentes formularios destinados, con car&aacute;cter general,
                a atender solicitudes de informaci&oacute;n, y de forma
                particular, para poder establecer registros de asistencia,
                inscripci&oacute;n a cursos de formaci&oacute;n promovidos por{' '}
                <strong>PLATFORM MEDIA</strong>.
              </p>

              <h3> POLITICA DE PROTECCION DE DATOS PERSONALES</h3>

              <p>
                Los criterios y reglas que sigue el responsable de Tratamiento,{' '}
                <strong>PLATFORM MEDIA</strong>, respecto a la
                utilizaci&oacute;n de los datos, que libre y voluntariamente Vd.
                facilite a trav&eacute;s de la presente p&aacute;gina web
                (formularios), son los que se exponen en el link determinado
                como &ldquo;pol&iacute;tica de privacidad&rdquo;, as&iacute;
                como en las cl&aacute;usulas informativas ubicadas en cada
                formulario o lugar donde se recaben o soliciten los datos con el
                objeto de dar alcance a la informaci&oacute;n.
              </p>

              <h3> EXENCION DE RESPONSABILIDADES.</h3>

              <p>
                <strong>PLATFORM MEDIA </strong>se exime de cualquier tipo de
                responsabilidad derivada de la informaci&oacute;n publicada en
                su p&aacute;gina web siempre que esta informaci&oacute;n haya
                sido manipulada o introducida por un tercero ajeno al mismo.
              </p>
              <p>
                <strong>PLATFORM MEDIA </strong>responder&aacute; de ninguna
                consecuencia, da&ntilde;o o perjuicio que pudieran derivarse de
                dicho acceso o uso de la informaci&oacute;n. Tampoco se
                har&aacute; responsable de los posibles errores de seguridad que
                se puedan producir ni de los posibles da&ntilde;os que puedan
                causarse al sistema informatico del usuario (hardware y
                software), los ficheros o documentos almacenados en el mismo,
                como consecuencia de la presencia de virus en el ordenador del
                usuario utilizado para la conexi&oacute;n a los servicios y
                contenidos de la p&aacute;gina web, de un mal funcionamiento del
                navegador o del uso de versiones no actualizadas del mismo.
              </p>

              <h3> LEGISLACION APLICABLE Y JURISDICCION</h3>

              <p>
                Para la resoluci&oacute;n de todas las controversias o
                cuestiones relacionadas con la presente p&aacute;gina web o de
                las actividades en ella desarrolladas siendo competentes para la
                resoluci&oacute;n de todos los conflictos derivados o
                relacionados con su uso los Juzgados y Tribunales de Suiza.
              </p>
            </Grid>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LegalNoticeES;
